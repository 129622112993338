<template>
  <div class="projectReferenceMountain">
    
    <div class="first-row wrapper" v-if="!data.acf.is_landingpage">
      <div class="expertiseNavSlider" v-if="!data.acf.is_an_academy_project">
        <ul>
          <li v-for="(expertise, key) in expertises" :key="key" :class="{active: activeExpertise == key}">
            <div class="expertiseSlide">
              <a href="" @click.prevent="prevExpertise" class="expertiseSlide__back">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </a>
              <span class="expertise"> 0{{ key+1 }} {{ expertise.acf.type }} <strong v-html="expertise.title.rendered"></strong></span>
              <a href="" @click.prevent="nextExpertise" class="expertiseSlide__forward">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </a>
            </div>
            <nav>
              <ul v-if="projects && projects.length">
                <li v-for="(p, key) in relatedProjects(expertise)" :key="key">
                  <router-link :to="pathTo(p.slug)" :class="{active: data.id === p.id}">{{ p.acf.title }}</router-link>
                </li>
              </ul>
            </nav>
          </li>
        </ul>
      </div>
      <div class="expertiseNavSlider" v-if="data.acf.is_an_academy_project">
        <ul>
          <li v-for="(expertise, key) in expertises" :key="key" :class="{active: activeExpertise == key}">
            <div class="expertiseSlide">
              <a href="" @click.prevent="$router.back()" class="expertiseSlide__back">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </a>
              <span :style="{cursor: 'pointer'}" class="expertise" @click.prevent="$router.back()">{{ $root.translate('back')}}</span>
            </div>
            <nav>
              <ul>
                <li v-for="(p, key) in relatedProjects(null)" :key="key">
                  <router-link :to="pathTo(p.slug)" :class="{active: data.id === p.id}">{{ p.acf.title }}</router-link>
                </li>
              </ul>
            </nav>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrapper" v-if="data.acf.is_landingpage"><br><br><br><br></div>
    <div class="mountainArea">
      <div class="mountain" id="projectReferenceMountain" :class="{showContent: showContent}"></div>
      <div class="processOverlay">
        <div class="processPoint" :class="{ active: activeStep == 0}" @click.prevent="toPosition(0)">
          <span>{{ $root.translate('process_step_1') }}</span>
        </div>
        <div class="processPoint" :class="{ active: activeStep == 1}" @click.prevent="toPosition(1)">
          <span>{{ $root.translate('process_step_2') }}</span>
        </div>
        <div class="processPoint" :class="{ active: activeStep == 2}" @click.prevent="toPosition(2)">
          <span>{{ $root.translate('process_step_3') }}</span>
        </div>
         <div class="processWay"></div>
      </div>
      <div class="wrapper">
        <h5>{{ $root.translate('projectreference')}}</h5>
        <h1 ref="headline" v-html="data.title.rendered"></h1>
        
        <div class="projectProcess"  :class="{ showContent: showContent }">
          <h3 style="opacity: 0" v-html="data.title.rendered"></h3>
          <div class="projectSteps">
            <div class="projectStep" v-for="(processStep, key) in data.acf.project_process" in :key="key" :class="{ active: key === activeStep }">
              <a href="" @click.prevent="toPosition(key)"><small>0{{ key+1 }}</small> {{ processStep.title }}</a>
              <div v-html="processStep.content">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="experts">
        <h5>{{ data.acf.experts.title }}</h5>
        <h4>{{ data.acf.experts.subline }}</h4>
        <div class="expertsList">
          <div class="expert" v-for="(member,key) in experts" :key="key">
            <figure>
               
              <div class="imageMask">
                <div class="socialIcons" v-if="member.acf.team_member.social_channels">
                  <ul>
                    <li v-if="member.acf.team_member.email">
                      <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'linkedin'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M289 273.6h-35.4v-55.4c0-13.2-.2-30.2-18.4-30.2-18.4 0-21.2 14.4-21.2 29.2v56.3h-35.4V159.7h33.9v15.6h.5c6.9-11.8 19.8-18.9 33.5-18.4 35.8 0 42.4 23.6 42.4 54.2l.1 62.5zM138.7 144.2c-11.3 0-20.5-9.2-20.5-20.5s9.2-20.5 20.5-20.5 20.5 9.2 20.5 20.5c.1 11.3-9.1 20.5-20.5 20.5m17.7 129.4H121V159.7h35.4v113.9z" fill="#4b4b4d"/></svg></a -->
                      <a target="_blank" href="" @click.prevent="mailTo(encodeEmail(member.acf.team_member.email))" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z" fill="#4b4b4d"/></svg></a>
                      <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'twitter'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M164.3 287.2c78.3 0 121.2-64.9 121.2-121.2 0-1.8 0-3.7-.1-5.5 8.3-6 15.5-13.5 21.3-22.1-7.6 3.4-15.9 5.6-24.5 6.7 8.8-5.2 15.5-13.6 18.8-23.6-8.2 4.9-17.4 8.4-27.1 10.3-7.8-8.3-18.9-13.5-31.1-13.5-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.6 1.1 9.7-35.4-1.7-66.8-18.8-87.8-44.5-3.7 6.3-5.7 13.6-5.7 21.4 0 14.8 7.6 27.8 18.9 35.5-7-.2-13.5-2.2-19.3-5.3v.6c0 20.6 14.7 37.9 34.1 41.8-3.6 1-7.3 1.5-11.2 1.5-2.7 0-5.4-.2-8-.7 5.4 16.9 21.2 29.2 39.8 29.6-14.6 11.5-33 18.3-52.9 18.3-3.4 0-6.8-.2-10.1-.6 18.7 11.9 41.2 19 65.2 19" fill="#4b4b4d"/></svg></a -->
                      <!-- a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'xing'"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M123.1 135.6c-1.8 0-3.3.6-4 1.8-.8 1.3-.7 2.9.2 4.5l20 34.6v.2l-31.4 55.4c-.8 1.6-.8 3.3 0 4.5s2.1 2 3.9 2h29.5c4.4 0 6.5-3 8.1-5.7 0 0 30.7-54.3 31.9-56.4-.1-.2-20.3-35.4-20.3-35.4-1.5-2.6-3.7-5.5-8.2-5.5h-29.7zM247.2 95.2c-4.4 0-6.3 2.8-7.9 5.6 0 0-63.6 112.8-65.7 116.6.1.2 42 77 42 77 1.5 2.6 3.7 5.6 8.3 5.6h29.5c1.8 0 3.2-.7 3.9-1.9.8-1.3.8-2.9-.1-4.6l-41.6-76.1v-.2L281 101.6c.8-1.6.8-3.3.1-4.6-.8-1.2-2.1-1.9-3.9-1.9l-30 .1z"/></svg></a -->
                    </li>
                  </ul>
                </div>
                <lazy-image :image="member.acf.team_member.image"></lazy-image>
              </div>
              <figcaption>
                <span class="name">{{ member.title.rendered }}</span>
                <span class="position">{{ member.acf.team_member.position || ' '  }}</span>
                <span class="description" v-html="member.description"></span>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
import { mapState } from 'vuex';
import { getCoords } from '../../helpers/NavigationService';
import { pathTranslate } from '../../helpers/NavigationService';
import LazyImage from './LazyImage';
import { emailMixin } from '../../mixins/emailMixin';
import { ref } from 'vue';

let mountain = null;
let scene = null;
export default {
  name: 'projectReferenceMountain',
  setup() {
    const headline = ref(null);
    return {
      headline
    }
  },
  props: ['data', 'projects'],
  mixins: [ emailMixin ],
  components: {
    LazyImage
  },
  data() {
    return {
      activeStep: 0,
      showContent: true,
      top: 0,
      activeExpertise: null,
    }
  },
  watch: {
    $route() {
      this.init();
    }
  },
  computed: { ...mapState({
      members: state => state.team.members,
      expertises: state => state.expertise.all,
      language: state => state.language.language
    }),
    currentExpertise() {
      if (!this.expertises) return null;
      return this.expertises.filter(expertise => {
        return expertise.id === this.data.acf.expertise.ID;
      })[0];
    },
    experts() {
      if (!this.members) return;
      if (!this.data.acf.experts?.expert?.length) return;
      let expertsIds = {};
      this.data.acf.experts.expert.forEach((expert) => {
        expertsIds[+expert.person.ID] = expert;
      });
      
      let experts = this.members.filter((member) => {
        return Object.keys(expertsIds).includes(member.id+'')
      });
      return experts.map(expert => {
        expert.description = expertsIds[expert.id].description;
        return expert;
      });
    }
  },
  mounted() {
    this.$store.dispatch('team/getAll');
    this.$store.dispatch('expertise/getAll', { language: this.language });
    this.init();
  },
  beforeUnmount() {
    if(mountain) {
      mountain.destroy();
    }
  },
  methods: {
    init() {
      if(mountain && !mountain.destroyFlag) {
        mountain.destroy();
      } 
      this.$nextTick(() => this.addScrollMagic());
      setTimeout(() => {
        if (!document.querySelector(".projectReferenceMountain")) {
          return
        }
        this.top = getCoords(document.querySelector(".projectReferenceMountain")).top;
        this.addMountains(); 
        this.activeExpertise = this.expertises.indexOf(this.currentExpertise);
      }, 1200);
    },
    addMountains() {
      if(mountain) {
        mountain.destroy();
      }
      
      if (!document.querySelector('#projectReferenceMountain')) {
        return;
      }
      
      mountain = new window._MOUNTAINS({
        element: '#projectReferenceMountain',
        seed: 10236,
        disableUI: true,
        groundLevel: 15,
        lineColor: 0x989898,
        screenshot: false,
        camera: {
            auto: false,
            motion: true,
            position: {
                x: 37,
                y: 29,
                z: -45
            },
            lookAt: {
                x: 0,
                y: 5,
                z: 0
            }
        }
      });

      mountain.run();
    },
    addScrollMagic() {
      if(!ScrollMagic || !this.$refs.headline) return;
      scene = new ScrollMagic.Scene({
        triggerElement: this.$el,
        offset: (this.$refs.headline?.offsetHeight || 0) + ((window.innerWidth > 768) ? ((window.innerWidth > 1024) ? 600 : 900) : 490),
        duration: 4000,
      })
      .setPin(".projectReferenceMountain")
      .addTo(window.ScrollMagicController); 

      scene.on('progress', (e) => {
        if(e.scrollDirection === 'FORWARD' &&  e.progress < 0.66 && e.progress > 0.33) {
          this.activeStep = 1;
        }
        if(e.scrollDirection === 'REVERSE' &&  e.progress < 0.33) {
          this.activeStep = 0;
        }
        if(e.scrollDirection === 'FORWARD' &&  e.progress >= 0.66) {
          this.activeStep = 2;
        }
        if(e.scrollDirection === 'REVERSE' &&  e.progress >= 0.33 && e.progress < 0.66) {
          this.activeStep = 1;
        }
      })
    },
    relatedProjects(expertise) {
      if(this.data.acf.is_an_academy_project) {
        return this.projects.filter(project => project.acf.is_an_academy_project && !project.acf.is_landingpage)
      }
      return this.projects.filter((project) => project.acf.expertise && project.acf.expertise.ID === expertise.id && !project.acf.is_an_academy_project && !project.acf.is_landingpage)
    },
    toPosition(key) {
      // let top = this.top;
      // top += 4000 * 0.25 * (key+(window.innerWidth < 700 ? 0 : 1));
      // top += this.getCoords(this.$el.parentNode).top;
      // eslint-disable-next-line
      if(window) window.scrollTo(0, scene.scrollOffset() + (scene.duration() * 0.35 * key));
    },
    pathTo(projectName) {
      return pathTranslate('project', this.language) + projectName;
    },
    nextExpertise() {
      if(this.expertises.length -1 < this.activeExpertise + 1) {
        this.activeExpertise = 0;
      }
      else {
        this.activeExpertise += 1;
      }
    },
    prevExpertise() {
      if(0 > this.activeExpertise - 1) {
        this.activeExpertise = this.expertises.length -1;
      }
      else {
        this.activeExpertise -= 1;
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
  .first-row {
    padding-top: 36px;
    
    margin-bottom: 100px;
    min-height: 80px;
    
    @media screen and (max-width: $medium) {
      flex-wrap: wrap;
      align-items: initial;
      margin-bottom: 50px;
    }
    .expertiseSlide__forward,
    .expertiseSlide__back {
      @media screen and (max-width: $medium) {
        width: 16px;
        height: 16px;
      }
      
      svg {
        width: 22px;
        height: 22px;
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translate(0,-50%) rotate(180deg);
        transition: transform 500ms ease;
        &:hover {
          transform: translate(-5px,-55%) rotate(180deg);
        }
        @media screen and (max-width: $medium) {
          left: -20px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .expertiseSlide__forward {
      svg {
        right: auto;
        left: 103%;
        transform: translate(0,-50%);
        &:hover {
          transform: translate(5px,-50%);
        }
      }
    }
    .expertiseSlide {
      position: relative;
      margin-right: 70px;
      margin-left: 30px;
      min-width: calc(33.33% - 60px);
      max-width: calc(33.33% - 60px);
      
      @media screen and (max-width: $medium) {
        margin-bottom: 20px;
        width: 100%;
        min-width: 0;
        max-width: none;
        text-align: center;
        margin-left: 25px;
        margin-right: 25px;
      }
    }
    .expertiseNavSlider {
      width: 100%;
      margin-top: 30px;
      > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        width: 100%;
        > li {
          display: flex;
          align-items: flex-start;
          position: absolute;
          width: 100%;
          top: 0;
          opacity: 0;
          min-height: 48px;
          transition: opacity 500ms ease;
          strong {
            display: block;
          }
          &.active {
            z-index: 10;
            opacity: 1;
          }
          @media screen and (max-width: $medium) {
            flex-wrap: wrap;
            align-items: initial;
            strong {
              display: inline;
            }
          }
        }
      }
    }

    .expertise {
      font-size: 18px;
      display: block;
      @media screen and (max-width: $medium) {
        font-size: 12px;
        line-height: 20px;
      }
    }

    nav {
      @media screen and (max-width: $medium) {
        width: 100%;
      }
      ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0;
        padding: 8px 0;
        list-style: none;
        overflow-x: auto;
        overflow-y: hidden;
        @media screen and (max-width: $medium) {
          flex-wrap: initial;
          
        }
        li {
          display: block;
          margin-right: 40px;
          margin-bottom: 20px;
          a {
            color: $color;
            font-size: 18px;
            text-decoration: none;
            position: relative;
            display: block;
            white-space: nowrap;
            @media screen and (max-width: $medium) {
              font-size: 20px;
            }
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 110%;
              width: 0;
              background: $color;
              height: 2px;
              transition: width 500ms ease, height 200ms ease;
            }
            &.active {
              &:after {
                width: 100%;
                height: 4px;
              }
            }
            &:hover {
              &:after {
                width: 100%
              }
            }
          }
        }
      }
    }
  }

  .mountainArea {
    position: relative;
    height: calc(100vh + 200px);
    margin-bottom: 50px;
    overflow: hidden;
    padding-top: 60px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 300px;
      background: linear-gradient(0deg, white 50%, rgba(255,255,255,0) 100% );
      width: 100%;
      z-index: 10
    }
    .mountain { 
      background: white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @media screen and (max-width: $small) {
        top: 100px;
        //z-index: 10;
      }
      canvas {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 8;
      }
      &.showContent {
        &:before {
          width: 50vw;
          @media screen and (max-width: $small) {
            background: rgba(255,255,255,0.66);
            width: 100vw;
          }
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 0;
        left: 0;
        background: white;
        transition: width 500ms ease;
        z-index: 10;
        @media screen and (max-width: $small) {
          background: rgba(255,255,255,0.66);
        }
      }
    }

    .processOverlay {
      position: absolute;
      top: 0;
      width: 100%;
      bottom: 0;
      //z-index: 12;
      @media screen and (max-width: $small) {
        top: 180px;
        opacity: 1;
        //z-index: 9;
      }
    }

    .processPoint {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: none;
      padding: 0;
      z-index: 12;
       @media screen and (max-width: $small) {
        z-index: 9;
      }
      cursor: pointer;
      transition: transform 500ms ease;
      
      span {
        background: $color;
        padding: 5px 20px;
        color: white;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 500ms ease;
        transition: background-color 500ms ease, color 500ms ease;
      }
      &:nth-child(1) {
        transform: translate(120px, 80px) !important;
        @media screen and (min-width: $small) {
          &:hover {
            transform: translate(120px, 80px) scale(1.1)
          }
        }
      }
      &:nth-child(2) {
        transform: translate(190px, 0px) !important;
        @media screen and (min-width: $small) {
          &:hover {
            transform: translate(190px, 0px) scale(1.1)
          }
        }
      }
      &:nth-child(3) {
        transform: translate(210px, -245px) !important;
        background: none;
        @media screen and (min-width: $small) {
          &:hover {
            transform: translate(210px, -245px) scale(1.1)
          }
        }
        .academy & {
          background: $academy;
          color: white;
        }
      }
      @media screen and (max-width: 1000px) {
        &:nth-child(1) {
          transform: translate(20px, 0px) !important;
        }
        &:nth-child(2) {
          transform: translate(0, -80px) !important;
        }
        &:nth-child(3) {
          transform: translate(40px, -260px) !important;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        background: white;
        width: 11px;
        height: 11px;
        box-shadow: 0 0 15px 5px lighten($primary, 5%);
        border: 1px solid $primary;
        border-radius: 50%;
        transform: scale(1) translate(-50%, -50%);
        transform-origin: 0 0;
        transition: transform 500ms ease, background-color 500ms ease;;
        z-index: 10;
        
        .academy & {
          border: 1px solid $academy;
          box-shadow: 0 0 15px 5px lighten($academy, 10%);
        }
      }
      &.hideLabel {
        span {
          opacity: 0;
        }
        @media screen and (min-width: $small) {
        &:hover {
          &:before {
            background: $primary;
            transform: scale(2) translate(-50%, -50%);
          }
          .academy &:before {
            background: $academy;
          }
        }
      }
      }
     
      &.active {
        span {
          background: $primary;
          color: $color;
          .academy & {
            background: $academy;
            color: white;
          }
        }
        &:before {
          transform: scale(1.5) translate(-50%, -50%);
        }
      }

      &:hover {
        &:before {
          transform: scale(1.5) translate(-50%, -50%);
        }
      }
    }

    .wrapper {
      pointer-events: none;
      h1 {
        font-weight: 300;
        font-size: 38px;
        line-height: 47px;
        transition: opacity 500ms ease;
        &.hide {
          opacity: 0;
        }
        @media screen and (max-width: $medium) { 
          font-size: 30px;
          line-height: 38px;
        }
        @media screen and (max-width: $small) {
          font-size: 25px;
          line-height: 30px;
        }
      }
      .projectProcess {
        width: 50%;
        left: 0;
        //background: white;
        padding: 5vh 50px 50px 0;
        box-sizing: border-box;
        opacity: 0;
        transition: opacity 500ms ease;
        pointer-events: none;
        @media screen and (max-width: $medium) {
          padding-top: 50px;
        }
        @media screen and (max-width: $small) {
          width: 100%;
          padding-top: 50px;
          background: rgba(255,255,255,0.25);
        }
        &.showContent{
          opacity: 1;
          pointer-events: initial;
        }
        h3 {
          font-size: 20px;
          line-height: 32px;
          font-weight: normal;
          margin: 0 0 2em;
          @media screen and (max-width: $small) {
            display: none;
          }
        }
        
        .projectSteps {
          .projectStep {
            > a {
              font-size: 27px;
              font-weight: 300;
              text-decoration: none;
              color: $color;
              @media screen and (max-width: $small) {
                font-size: 20px;
              }
            }
            > div {
              font-size: 15px;
              line-height: 27px;
              padding: 0;
              border-bottom: 0 solid $color;
              margin-bottom: 10px;
              max-height: 0;
              overflow: hidden;
              box-sizing: border-box;
              transition: padding 500ms ease, border-bottom 500ms ease, margin-bottom 500ms ease, max-height 500ms ease, margin 500ms ease;
              @media screen and (max-width: $small) {
                font-size: 15px;
                line-height: 24px;
              }
              ul {
                margin: 0;
                padding: 0 0 0 1.2em;
                li {
                  margin: 0 0 1em;
                }
              }
            }
            &.active {
              > a {
                color: $primary;
                .academy & {
                  color: $academy;
                }
              }
              > div {
                margin: 20px 0 0;
                border-bottom: 1px solid $color;
                margin-bottom: 40px;
                max-height: 500px;
                overflow-y: auto;
                @media screen and (max-width: $medium) {
                  max-height: 350px;
                }
              }
            }
          }
        }
        
      }
    }
  }

  .experts {
    padding-bottom: 130px;
    h4 {
      font-size: 38px; 
      line-height: 47px;
      font-weight: 300;
      margin: 0 0 40px;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) {
        font-size: 25px;
        line-height: 30px;
      }
    }
    .expertsList {
      margin: 0;
      padding: 0;
      display: flex;
      @media screen and (max-width: $small) {
        flex-wrap: wrap;
      }
      .expert {
        flex: 1;
        padding-right: 20px;
        max-width: calc(33.333% - 20px);
        @media screen and (max-width: $medium) { 
          max-width: 50%;
          margin-bottom: 50px;
          max-width: calc(50% - 20px);
        }
        @media screen and (max-width: $small) {
          max-width: 100%;
          width: 100%;
          flex: none;
        }
        figure {
          margin: 0;
          padding: 0;
          .imageMask {
            position: relative;
            overflow: hidden;
            margin-bottom: 10px;
            .socialIcons {
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 5;
              ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                  width: 32px;
                  height: 32px;
                  svg {
                    width: 100%;
                    height: 100%;
                    > * {
                      transition: fill 500ms ease;
                      fill: $color;
                    }
                  }
                  a {
                    &:hover {
                      svg {
                        > * {
                          fill: $primary;
                        }
                      }
                    }
                  }
                }
              }
            }
            > * {
              position: absolute;
            }
            &:after {
              content: '';
              display: block;
              padding-top: 83%;
            }
          }
          img {
            width: 100%;
          }

          .name {
            font-size: 27px;
            line-height: 24px;
            display: block;
          }
          .position {
            font-size: 18px;
            line-height: 24px;
            min-height: 24px;
            display: block;
          }
          .description {
            font-size: 17px;
            line-height: 30px;
            ul {
              margin: 30px 0 0 0;
              padding: 0 0 0 1.2em;
              li {
                margin: 0 0 1.5em;
                width: 90%;
              }
            }
          }
        }
      }
      
    }
  }
</style>