import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    faqs: null
  },
  mutations: {
    setFaqs(state, post) {
      state.faqs = post
    }
  },
  actions: {
    getFaqs({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPosts('faqs', language).then( (post) => {
          commit('setFaqs', post.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    }
  }
}