<template>
    <button :class="{ 'white': theme == 'white', 'hasSvg': hasSvg}" @click.prevent="navigate" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <span class="front">
        <slot></slot>
      </span>
      <span class="mask">
        <span class="back__button" :class="{'hasSvg': hasSvg}">
          <slot></slot>
        </span>
      </span>
    </button>
</template>

<script>
import { toLink } from '../../helpers/NavigationService';
export default {
  name: 'PrimaryButton',
  props: ['label', 'theme', 'hasSvg', 'to'],
  methods: {
    navigate() {
      if(this.to && toLink(this.to).type === 'intern') {
        this.$router.push(toLink(this.to).link);
      }
      else if(this.to && toLink(this.to).type === 'extern') {
        window.open(this.to);
      }
      else {
        this.$emit('click');
      }
    },
    mouseenter() {
      this.$store.commit('mouseHovering', { color: 'whiteFocus', label: this.label });
      setTimeout(() => {
         this.$store.commit('mouseHovering', null)
      }, 2000)
    },
    mouseleave() {
      this.$store.commit('mouseHovering', null)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/index';
  button {
    font-size: 16px;
    color: $color;
    background: $primary;
    height: 66px;
    padding: 0 60px;
    border-radius: 0;
    border: none;
    outline: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.1em;
    position: relative;
    margin: 0;
    .academy & {
      background: $academy;
      color: white;
    }
    &.hasSvg {
      padding: 0 90px 0 60px;
    }
    @media screen and (max-width: $medium) {
      font-size: 11px;
      height: 50px;
    }
    @media screen and (max-width: $xsmall) {
      //display: block;
      //width: 100%;
    }
    cursor: pointer;
    .front {
      display: block;      
      white-space: nowrap;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      overflow: hidden;
      transition: width 500ms ease;
      svg {
        * {
          fill: white
        }
      }
    }
    &:hover {
      .mask {
        width: 100%;
      }
    }
    svg {
      width: 50px;
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
    }
    .back__button {
      background: $color;
      color: white;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      height: 66px;
      line-height: 66px;
      padding: 0 60px;
      white-space: nowrap;
      @media screen and (max-width: $medium) {
        font-size: 11px;
        height: 50px;
        line-height: 50px;
      }
      &.hasSvg {
        padding: 0 100px 0 60px;
      }
    }
    &.white {
      background: white;
    }
  }
</style>