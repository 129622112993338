<template>
  <div>
    <div class="wrapper">
      <h5>{{ data.title }}</h5>
    </div>
    <div class="sliderComponent">
      <div class="sliderMask" ref="slider" >
          <div class="slider" :style="{ transform: `translate(-${(currentPosition * 100)}%)`}">
            <div class="item" v-for="(item, key) in data.item" :key="key">
              <div class="wrapper">
                <div class="left">
                  <lazy-image :image="item.image" replaceSize="medium_large"></lazy-image>
                </div>
                <div class="right">
                  <div v-html="item.text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="process">
            <div class="position" :style="{ 
              width: (perPage / amount * 100) + '%', 
              left: (currentPosition * (perPage/amount) * 100) + '%'
            }"></div>
          </div>
        </div>
        <div class="arrows">
          <div class="next" @click="nextStop">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </div>
          <div class="prev" @click="prevStop">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
let Hammer;
if(typeof window !== 'undefined') {
  Hammer = require('hammerjs');
}
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
import LazyImage from './LazyImage';
import { ref } from 'vue';

export default {
  name: 'Slider',
  props: ['data'],
  setup() {
    const slider = ref(null)
    // ...
    return {
      slider
    }
  },
  components: {
    LazyImage
  },
  data() {
    return {
      currentPosition: 0,
      perPage: 1,
      interval: null
    }
  },
  computed: {
    amount() {
      return this.data.item.length;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(!Hammer && !this.$refs.slider) return;
      
      const hammer = new Hammer(this.$refs.slider);
      hammer.on('panstart', ({ direction }) => {
        clearInterval(this.interval);
        if(direction === 2) {
          this.next();
        }
        else {
          this.prev();
        }
      })

      const scene = new ScrollMagic.Scene({
        triggerElement: this.$el,
        offset: 0
      })
      .addTo(window.ScrollMagicController); 

      scene.on('start', () => {
        this.triggerInterval();
      })
    })
  },
  methods: {
    triggerInterval() {
      if(this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => this.next(), 8000);
    },
    nextStop() {
      if(this.interval) {
        clearInterval(this.interval)
      }
      this.next();
    },
    next() {
      if(this.currentPosition +1 > Math.ceil(this.amount/this.perPage) -1) {
        this.currentPosition = 0; 
      }
      else {
        this.currentPosition += 1;
      }
    },
    prevStop() {
      if(this.interval) {
        clearInterval(this.interval)
      }
      this.prev();
    },
    prev() {
      if(this.currentPosition -1 < 0) {
        this.currentPosition = Math.ceil(this.amount/this.perPage) -1; 
      }
      else {
        this.currentPosition -= 1;
      }
    },
  }
}
</script>

<style lang="scss">
@import '../../styles/config';

.sliderComponent {
  position: relative;
  z-index: 22;
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    margin-bottom: 100px;
  }
  .sliderMask {
    //overflow: hidden;
    width: 99.99%;
    transform: translate(0, 0);
    overflow: hidden;
    margin-bottom: 40px;
    
    .slider {
      display: flex;
      transition: transform 500ms ease;
      max-height: 700px;
      @media screen and (max-width: $medium) {
        max-height: 500px;
      }
      @media screen and (max-width: $medium) {
        max-height: none;
      }
      
      .item {
        min-width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .wrapper {
          display: flex;
          @media screen and (max-width: $medium) {
            display: block;
          }
        }
        
        .left {
          width: 50%;
          flex: 2;
          position: relative;
          @media screen and (max-width: $medium) {
            flex: 1;
          }
          @media screen and (max-width: $medium) {
            width: 100%;
          }
          &:after {
            content: '';
            display: block;
            padding-top: 100%;
            @media screen and (max-width: $medium) {
              padding-top: 100%;
            }
          }
          img {
            position: absolute;
            right: 120px;
            max-width: 60vw;
            //min-height: 100%;

            @media screen and (max-width: $medium) {
              max-width: 100vw;
              right: -20px;
              left: -20px
            }
          }
        }
        .right {
          flex: 1;
          padding-top: 120px;
          color: $primary;
          font-size: 27px;
          line-height: 35px;
          font-weight: 300;
          padding-bottom: 50px;
          @media screen and (max-width: $medium) { 
            font-size: 22px;
            line-height: 29px;
          }
          @media screen and (max-width: $medium) {
            padding-top: 30px;
            margin-bottom: 32px;
            font-size: 22px;
            line-height: 29px;
          }
          > div {
            padding-right: 40px;
          }
          p {
            margin: 0 0 1em;
          }
        }
      }
    }
  }
    
  .process {
    right: 46px;
    bottom: 40px;
    width: calc(33.33% - 40px);
    height: 3px;
    background: #cdcdd3;
    position: absolute;

    @media screen and (max-width: $medium) {
        display: block;
        width: calc(100% - 20px);
        position: relative;
        bottom: auto;
        right: auto;
    }

    .position {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background: $color;
      transition: left 500ms ease;
    }
  }

  .arrows {
    @media screen and (max-width: $large) {
      display: block;
    }
    .next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      margin: 0 2em;
      width: 22px;
      height: 22px;
      cursor: pointer;
      @media screen and (max-width: $medium) {
        top: 86.2vw;
        margin: 0;
        width: 22px;
        height: 22px;
        background: rgba(255,255,255, 1);
        padding: 10px;
      }
    }
    .prev {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%) rotate(180deg);
      margin: 0;
      width: 22px;
      height: 22px;
      background: rgba(255,255,255, 1);
      padding: 10px;
      cursor: pointer;
      @media screen and (max-width: $medium) {
        top: 86.2vw;
      }
    }
  }
}
</style>