import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    post: null,

  },
  mutations: {
    setPDF(state, post) {
      state.pdf = post
    },
    setPDFs(state, post) {
      state.pdfs = post
    },
  },
  actions: {
    getPDFs({ commit }, { language }) {
      return new Promise((resolve, reject)  => {
        (new APIService).getPosts('pdf', language).then( (post) => {
          let posts = [];
          post.data.forEach((p) => {
            const elements = [];
            Object.keys(p.acf).forEach((key) => {
              let data = p.acf[key];
              if(p.acf[key].length == 1 && Object.keys(p.acf[key][0])[0] === key) {
                data = p.acf[key][0][key];
              }

              if(key === 'content_container') {
                return true;
              }
              if(key === 'blog_content') {
                key = 'content_container';
                data = p.acf['blog_content'].length ? p.acf['blog_content'][0]['content_container'] : null
              }
              elements.push({
                component: key,
                data: data
              })
            });
            p.acf = elements;
            posts.push(p)
          })
          
          commit('setPDFs', posts);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    },
    getPDF({ commit }, { slug, language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPDFBySlug(slug, language).then( (post) => {
          let p = post.data[0];
          if (!p) return;
          let elements = [];
          const postFields = Object.keys(post.data[0].acf).filter((key) => key !== 'preview_image');
          postFields.forEach((key) => {
            let data = p.acf[key];
            if(p.acf[key].length == 1 && Object.keys(p.acf[key][0])[0] === key) {
              data = p.acf[key][0][key];
            }
            if(key === 'content_container') {
              return true;
            }
            if(key === 'blog_content') {
              key = 'content_container';
              data = p.acf['blog_content'][0]['content_container']
            }
            elements.push({
              component: key,
              data: data
            })
          });

          p.acf = elements;
          // const hooks = (p) => {
          //     // let elements = [];
          //     // elements.push({
          //     //   component: 'related_publications',
          //     //   data: null
          //     // });
          //     // const items = [
          //     //   'content_container',
          //     //   'download_request',
          //     //   'contact_teaser'
          //     // ];
          //     // items.forEach((key) => {
          //     //   if(p.acf.find(value => key === value.component)) {
          //     //     elements.push(p.acf.find(value => key === value.component))
          //     //   }
          //     // })
          //     // p.acf = elements;
            
          //   return p;
          // }
          commit('setPDF', (p));
          resolve((p));
        }).catch(e => reject(e));
      })
    }
  }
}