import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    members: null
  },
  mutations: {
    setTeamMembers(state, posts) {
      state.members = posts
    }
  },
  actions: {
    getAll({ commit }) {
      (new APIService).getPosts('team').then( (posts) => {
        commit('setTeamMembers', posts.data)
      })
    }
  }
}