<template>
  <div class="mountainStage" id="mountainStage">
    <div id="mountainStageContainer" :class="{hide: zoomIn}"></div>
    <div class="wrapper">
      <div class="mountainStageBadge" v-if="data.badge" :class="{ hide: uiHide }">
        <router-link :to="language === 'en' ? '/en/career/' : '/karriere'">
          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1010.6 1010.6" xml:space="preserve"><circle cx="498.7" cy="496.3" r="443.9" fill="#a0d0cb"/><path class="st1" d="M201.6 317.8h17.2L229 431.2l54.8-105.8h15l13.6 105.8 54.2-113.4h19.2L318 457.2h-17.4L287 352.8l-54.4 104.4h-17.2l-13.8-139.4zM411.8 317.8h91.6l-3.2 16h-74.4l-8.6 44.2h67.6l-3 16h-67.6l-9.2 47.2h74.4l-3.2 16h-91.6l27.2-139.4zM534.4 317.8h14.2l-9.2 46.4h-14.2l9.2-46.4zM629.2 394.6l25.6 62.6h-18.6l-23-60.6h-27.4l-11.6 60.6H557l27.2-139.4H625c31.4 0 44 16.6 39 41.6-3.8 19.8-15.8 30.8-34.8 35.2zm-8.2-60.8h-22.8l-9.2 46.8h22.2c19.8 0 32.6-6.2 36-23.4 3-16-6.6-23.4-26.2-23.4zM704.2 317.8h91.6l-3.2 16h-74.4l-8.6 44.2h67.6l-3 16h-67.6l-9.2 47.2h74.4l-3.2 16H677l27.2-139.4z"/><g><path class="st2" d="M194.7 508.8h25l-11 56.2h61l11-56.2h25l-27.2 139.4h-25l11.8-60.2h-61l-11.8 60.2h-25l27.2-139.4zM340.9 508.8h25l-27.2 139.4h-25l27.2-139.4zM448.9 588l25.6 60.2h-26.6l-22.2-57.8h-19.8l-11.2 57.8h-24.8l27.2-139.4h45c33.2 0 44.8 18.2 39.8 44-3.8 19.6-15.2 30.4-33 35.2zm-12.8-56.2h-18.8l-6.8 35.6h17.6c16.2 0 26.6-3.4 29.4-17.8 2.4-12.8-5.2-17.8-21.4-17.8zM521.5 508.8h25l-27.2 139.4h-25l27.2-139.4zM687.1 508.8l-27.2 139.4h-25.8l-40-95.2-18.6 95.2h-25l27.2-139.4h25.6l40.4 94.8 18.4-94.8h25zM770.5 572.8h56l-14.6 75.4h-19.8l1.8-18.8c-10.2 13.2-26.4 20.8-44.4 20.8-39.2 0-56.2-24.4-56.2-55.2 0-48.8 33.6-88.4 81.8-88.4 33 0 50.2 17.4 54.8 40.6l-24 7.4c-2.8-14.8-13-24.2-33.2-24.2-33.8 0-54.6 29-54.6 63 0 19.6 9.8 33 33.8 33 22 0 40-11.4 45.4-33.4h-30.6l3.8-20.2z"/></g><g></g></svg>
        </router-link>
      </div>
      <div class="mountainStage__content" :class="{ hide: uiHide }">
        <headline>
          <span v-html="data.claim"></span>
        </headline>
      </div>

      <div class="mountainStage__videoTeaser" v-if="data.teaser_type == 'videoTeaser'" :class="{ hide: uiHide }">
        <video-teaser label="View Video" :src="data.video_preview_image" :youtubeId="data.youtube_id"></video-teaser>
      </div>

      <div class="mountainStage__videoTeaser" v-if="teaser" :class="{ hide: uiHide }">
        <div class="item">
          <figure>
            <div>
                <router-link :to="pathTo(teaser.slug, teaser.type)">
                  <lazy-image v-if="teaser.acf && teaser.acf.stage && !teaser.acf.preview_image" :image="teaser.acf.stage[0].stage.header_image" replaceSize="medium_large" ></lazy-image>
                  <lazy-image v-if="teaser.acf && teaser.acf.preview_image" :image="teaser.acf.preview_image" replaceSize="medium_large" ></lazy-image>
                </router-link>
              </div>
            <figcaption>
              <span class="date">{{ formatDate(teaser.date) }} <span class="category" v-for="categoryId in teaser.categories" :key="categoryId" ><router-link :to="language === 'en' ? '/en/news/#' + getCategory(categoryId).slug : '/news/#' + getCategory(categoryId).slug"># {{ getCategory(categoryId).name }}</router-link></span> </span>
            </figcaption>
          </figure>
            <router-link :to="pathTo(teaser.slug, teaser.type)" class="titleLink">
              <h4 v-html="teaser.title.rendered"></h4>
            </router-link>
            <router-link :to="pathTo(teaser.slug, teaser.type)" class="arrowLink">
              {{ data.teaser_type == 'newsTeaser' ? $root.translate("read_article") : $root.translate('to_publication') }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </router-link>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script>
import Headline from './Headline'
import VideoTeaser from './VideoTeaser'
import { mapState } from 'vuex';
import APIService from '../../helpers/APIService';
import { pathTranslate } from '../../helpers/NavigationService';
import LazyImage from './LazyImage';
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
let mountainStage = null;
let scene = null;
export default {
  components: {
    Headline,
    VideoTeaser,
    LazyImage
  },
  props: ['data'],
  name: 'mountainStage',
  data() {
    return {
      zoomIn: false,
      uiHide: false,
      teaser: null,
      categories: []
    }
  },
  computed: mapState({
    language: state => state.language.language,
  }),
  watch: {
    zoomIn(zoom) {
      if(zoom) {
        mountainStage.cameraMoveTo({
          x: 0,
          z: 0,
          y: 0
        });
      }
      else {
        mountainStage.cameraMoveTo({
            x: 60,
            y: 35,
            z: 20
        });
        
      }
    },
    $route() {
      if(this.data.teaser_type == 'newsTeaser') {
        this.newsTeaserInit();
      }
      if(this.data.teaser_type == 'publicationTeaser') {
        this.publicationTeaserInit();
      }
    }
  },
  mounted() {
    setTimeout(() => this.addScrollMagic(), 200);
    setTimeout(() => this.addMountains(), 600);
    if(this.data.teaser_type == 'newsTeaser') {
      this.newsTeaserInit();
    }
    if(this.data.teaser_type == 'publicationTeaser') {
      this.publicationTeaserInit();
    }
  },
  beforeUnmount() {
    if(mountainStage) {
      mountainStage.destroy();
    }
  },
  methods: {
    newsTeaserInit() {
      (new APIService).getCategories().then((categories) => {
        this.categories = categories.data;
        (new APIService).getPostBySlug(this.data.news_teaser[0].post_name, this.language).then(({data}) => {
          this.teaser = data[0];
        });
      });
    },
    publicationTeaserInit() {
      (new APIService).getPublicationBySlug(this.data.publication_teaser[0].post_name, this.language).then(({data}) => {
        this.teaser = data[0];
      });
    },
    addMountains() {
      if (!document.querySelector('#mountainStageContainer')) {
        return;
      }
      mountainStage = new window._MOUNTAINS({
        element: '#mountainStageContainer',
        seed: Math.round(Math.random() * 65500),
        disableUI: true,
        groundLevel: 15,
        screenshot: false,
        lineColor: 0x989898,
        camera: {
            auto: true,
            motion: false,
            position: {
                x: 60,
                y: 55,
                z: 20
            },
            lookAt: {
                x: 0,
                y: 20,
                z: 0
            }
        }
      });
      
      mountainStage.run();

      setTimeout(() => {
        mountainStage.cameraMoveTo({
          x: 60,
          y: 35,
          z: 20
        })
      }, 100)
      
    },
    addScrollMagic() {
      if(!ScrollMagic) return;
      scene = new ScrollMagic.Scene({
        triggerElement: this.$el,
        offset: 0,
        duration: window.innerHeight,
      })
      .addTo(window.ScrollMagicController); 

      scene.on('progress', (e) => {
        if(e.scrollDirection === 'FORWARD' &&  e.progress > 0.9 ) {
          this.uiHide = true;
        }
        if(e.scrollDirection === 'REVERSE' &&  e.progress < 0.5) {
          this.uiHide = false;
        }

        if(e.scrollDirection === 'FORWARD' &&  e.progress > 0.8) {
          this.zoomIn = true;
        }

        if(e.scrollDirection === 'REVERSE' &&  e.progress < 0.95) {
          this.zoomIn = false;
        }
      })
    },
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    getCategory(id) {
      if(!this.categories) return { slug: null, name: null };
      const categories = this.categories.length ? this.categories.filter((cat) => cat.id === id) : [];
      return categories.length ? categories[0] : { slug: null };
    },
    pathTo(slug, type) {
      return pathTranslate(type == 'post' ? 'news' : 'publications', this.language) + slug;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.mountainStage {
  position: relative;
  min-height: calc(100vh - 136px);
  margin-bottom: 130px;
  > .wrapper {
    z-index: 10;
  }
}
.mountainStageBadge {
  position: absolute;
  top: 50px;
  right: 30px;
  opacity: 1;
  transition: transform 500ms ease 800ms, opacity 500ms ease 800ms;
  &:after {
    display: block;
    width: 30px;
    height: 4px;
    background: $color;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%);
    content: '';
    transition: width 500ms ease;
  }
  &:hover {
    &:after {
      width: 50px;
    }
  }
  svg {
    width: 180px;
    height: 180px;
    @media screen and (max-width: $small) {
      width: 110px;
      height: 110px;
    }
    circle {
      opacity: 0.9;
    }
    .st1 {
      fill: white;
    }
    .st2 {
      fill: $color;
    }

    
  }
  &.hide {
    transform: translate(0, -50px);
    opacity: 0;
  }
  @media screen and (max-width: $small) {
    top: 20px;
    right: 15px;
    max-width: 110px;
    max-height: 110px;
    &:after {
      display: block;
      width: 30px;
      height: 4px;
      background: $color;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translate(-50%);
      content: '';
      transition: width 500ms ease;
      @media screen and (max-width: $small) {
        bottom: 20px;
        height: 3px;
      }
    }
    img {
      width: 100%;
    }
  }
}
#mountainStageContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  transition: opacity 500ms ease 600ms;
  &.hide {
    opacity: 0;
    transition: opacity 500ms ease ;
  }
  canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.mountainStage__content {
  padding-top: 160px;
  min-height: calc(100vh - 136px);
  margin-bottom: 100px;
  opacity: 1;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms;
  box-sizing: border-box;
  p {
    margin: 0;
  }
  &.hide {
    transform: translate(0, -50px);
    opacity: 0;
  }
  @media screen and (max-width: $small) {
    display: block;
    min-height: auto;
    margin: 0;
    
  }
  @media screen and (max-width: $small) {
    padding-top: 80px;
  }
}

.mountainStage__videoTeaser {
  position: absolute;
  left: 33.89%;
  bottom: 50px;
  opacity: 1;
  width: 33.89%;
  transition: transform 500ms ease, opacity 500ms ease;
  &.hide {
    transform: translate(0, -50px);
    opacity: 0;
  }
  @media screen and (max-width: $small) {
    position: static;
    width: 100%;
    margin: 50px 0;
  }

  .item {
    width: 100%;
    overflow: hidden;
    padding: 0 10px 0 0;
    box-sizing: border-box;
    @media screen and (max-width: $small) {
      min-width: 85%;
    }
    .date {
      letter-spacing: 0.135em;
      text-transform: uppercase;
      font-size: 12px;
      display: block;
      margin-bottom: 20px;
      position: absolute;
      right: 0;
      transform: rotate(-90deg) translate(100%, 10px);
      transform-origin: right top;
      @media screen and (max-width: $small) {
        font-size: 10px;
      }
      .category {
        color: $primary;
        a {
          text-decoration: none;
        }
      }
    }
    figure {
      margin: 0 100px 0 0;
      padding: 0;
      position: relative;
      div {
        overflow: hidden;
        position: relative;
        background: $primary;
        &:after {
          content: '';
          display: block;
          padding: 80% 0 0;
        }
        img {
          position: absolute;
          top: 0;
          bottom: 0;
          display: block;
          left: 50%;
          height: 100%;
          transform: translate(-50%);
          transition: transform 500ms ease;
        }
        a {
          &:hover {
            img {
              transform: translate(-50%) scale(1.1);
            }
          }
        }
      }
      
    }
    a.titleLink {
      text-decoration: none;
      color: $color;
    }
    h4 {
      font-size: 25px;
      line-height: 33px;
      font-weight: 300;
      width: 90%;
      margin: 20px 0;
      @media screen and (max-width: $small) {
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}

</style>