<template>
  <div class="contentFullWidthImage">
    <div class="gallery" >
      <div :style="{ paddingTop: (data.image[currentIndex].height/data.image[currentIndex].width * 100) + '%'}"></div>
      <div class="item" :class="{ 'active': index === currentIndex, 'prev': index === previousIndex }" v-for="(image, index) in data.image" :key="index">
        <figure>
          <lazy-image :image="image"></lazy-image>
          <figcaption>
            {{ image.caption }}
          </figcaption>
        </figure>
      </div>
    </div>
    <div class="position" v-if="totalAmount > 1">
      {{ currentIndex + 1 }} | {{ totalAmount }}
    </div>
    <div class="arrows" v-if="totalAmount > 1">
      <div class="next" @click="next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </div>
      <div class="prev" @click="prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './../LazyImage';
export default {
  name: 'ContentFullWidthImage',
  props: ['data'],
  components: {
    LazyImage
  },
  data() {
    return {
      currentIndex: 0,
      previousIndex: 0
    }
  },
  computed: {
    totalAmount() {
      return this.data.image ? this.data.image.length : 0;
    }
  },
  methods: {
    next() {
      this.previousIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex +1 >= this.totalAmount) ? 0 : this.currentIndex +1;
    },
    prev() {
      this.previousIndex = this.currentIndex;
      this.currentIndex = (this.currentIndex -1 < 0) ? this.totalAmount -1 : this.currentIndex -1;
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';

.contentFullWidthImage {
  position: relative;
  padding-bottom: 100px;
  @media screen and (max-width: $small) {
    width: 100vw;
    left: 50%;
    transform: translate(-50%);
  }
  .gallery {
    position: relative;
    .item {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      transition: width 500ms ease;
      //overflow: hidden;
      &.active {
        width: 100%;
        z-index: 10;
      }
      &.prev {
        width: 100%;
        z-index: 9;
      }
      figure {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        img {
          display: block;
          height: 100%;
        }

        figcaption {
          max-width: 590px;
          font-size: 15px;
          line-height: 22px;
          margin: 1em 0 1em;
          @media screen and (max-width: $small) {
            margin: 1em 40px 1em 20px;
          }
        }
      }
    }
  }
  .position {
    position: absolute;
    right: 0;
    margin: 1em 0;
    @media screen and (max-width: $small) {
      right: 20px;
    }
  }
  .arrows {
    @media screen and (max-width: $large) {
      display: none;
    }
    .next {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(0, -50%);
      margin: 0 2em;
      width: 22px;
      height: 22px;
    }
    .prev {
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translate(0, -50%) rotate(180deg);
      margin: 0 2em;
      width: 22px;
      height: 22px;
    }
  }
}
</style>