≈
<template>
  <div class="wrapper relatedPublications" v-if="false">
    <h5 v-if="data && data.title">{{ data.title }}</h5>
    <h5 v-if="!data || !data.title">{{ $root.translate('all_publications') }}</h5>
    <div class="slider" ref="slider">
      <ul :style="{ transform: `translate(${((perPage === 1 ? -80 : -100) * currentPage)}%)` }">
        <li v-for="(publication, key) in publications" :key="key" @click="scrollDown()">
          <router-link :to="pathTo(publication.slug)" >
            <div class="box">
              <div class="box__background" :style="{ backgroundImage: `url(${publication.previewImage.url})` }"></div>
              <div class="box__content">
                {{ publication.title }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="position" v-if="amount > perPage">
        <div class="position__indicator" :style="positionIndicator()"></div>
      </div>
    </div>
    <div class="arrows" v-if="amount > perPage">
      <a class="next" href="#" @click.prevent="next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </a>
      <a class="prev" href="#" @click.prevent="prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </a>
    </div>
    <a id="publication-content"></a>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapState } from 'vuex';
import { pathTranslate, getCoords } from '../../helpers/NavigationService';
let Hammer;
if(typeof window !== 'undefined') {
  Hammer = require('hammerjs');
}

export default {
  name: 'RelatedPublications',
  setup() {
    const slider = ref(null)
    // ...
    return {
      slider
    }
  },
  props: {
    data: Object,
    post: Object
  },
  data() {
    return {
      currentPage: 0,
      perPage: 1
    }
  },
  computed: {
    ...mapState({
      statePublications: state => {
        let publications = state.routes.all ? state.routes.all.filter((route) => route.type === 'publication') : null;
        return publications;
      },
      language: state => state.language.language
    }),
    publications() {
      let publications = this.localizedPublications;
      if (this.data && this.data.withoutCurrent) {
        publications = publications.filter(publication => publication.id != this.post.id)
      }
      return publications;
    },
    localizedPublications() {
      let shouldMatchLang = (url) => {
        let lang = this.language;
        const ifEN = url.indexOf('/en') == 0 && lang == 'en';
        const ifDE = url.indexOf('/en') != 0 && lang == 'de';

        return ifEN || ifDE;
      }
      let publications = this.statePublications ? this.statePublications.filter((route) => shouldMatchLang(route.url) ) : null;
      return publications;
    },
    amount() {
      return this.publications.length
    },
    maxPages() {
      return Math.ceil(this.amount/this.perPage)
    }
  },
  watch: {
    publications() {
      this.currentPage = this.pageOfActive();
    }
  },
  mounted() {
    this.$store.dispatch('routes/getRoutes', this.$route.path.indexOf('/en') === 0 ? 'en' : 'de').then( () => {
      this.$nextTick(() => this.currentPage = this.pageOfActive());
      window.addEventListener('resize', () => {
        if (window.innerWidth > 768) {
          this.perPage = 3;
        }
        else {
          this.perPage = 1;
        }
      });
      if (window.innerWidth > 768) {
        this.perPage = 3;
      }
      else {
        this.perPage = 1;
      }
      this.$nextTick(() => {
        if(!Hammer || !this.$refs.slider) return;
        const hammer = new Hammer(this.$refs.slider);
        hammer.on('panstart', ({ direction }) => {
          if(direction === 2) {
            this.next();
          }
          else {
            this.prev();
          }
        })
      })
    });
    
  },
  methods: {
    scrollDown() {
      if (typeof window != 'undefined') {
        const top = getCoords(document.querySelector('#publication-content')).top;
        window.scrollTo(0, top - 100);
      }
    },
    sortPublications() {
      const currentPublication = this.publications.filter((publication) => this.post.id === publication.id );
      const otherPublications = this.publications.filter((publication) => this.post.id != publication.id );
      return [...currentPublication, ...otherPublications];
    },
    positionIndicator() {
      return { 
        width: (100 / this.maxPages) + '%',
        left: (100 / this.maxPages * this.currentPage) + '%'
      }
    },
    pageOfActive() {
      if(this.data && this.data.withoutCurrent) return 0;

      let theIndex = 0;
      this.publications.forEach((publication, index) => {
        if (publication.id === this.post.id) {
          theIndex = index;
        }
      });

      return Math.floor(theIndex * this.maxPages / this.amount);

    },
    next() {
      if (this.currentPage +1 < this.maxPages) {
        this.currentPage += 1;
      } else {
        this.currentPage = 0;
      }
    },
    prev() {
      if (this.currentPage -1 >= 0) {
        this.currentPage -= 1;
      } else {
        this.currentPage = this.maxPages -1;
      }
    },
    pathTo(slug) {
      return pathTranslate('publications', this.language) + slug;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.relatedPublications {
  margin-bottom: 100px;
  h5 {
    margin-bottom: 35px;
  }
  .slider {
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
    width: 99.99%;
    @media screen and (max-width: $small) {
      padding-bottom: 120px;
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      transition: transform 500ms ease;
      
      li {
        margin-right: 20px;
        //flex: 1;
        width: calc(33.333% - 20px);
        min-width: calc(33.333% - 20px);
        @media screen and (max-width: $small) {
          min-width: calc(80% - 20px);
        }
        &:hover {
          .box {
            .box__background {
              opacity: 0.1;
              transform: scale(1.05);
            }
          }
        }
        .router-link-exact-active {
          .box {
            background: $color;
            .box__background {
              opacity: 0.3;
              transform: scale(1.2);
            }
            .box__content {
              color: white;
              svg {
                transform: rotate(90deg);
                > * {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .position {
    position: absolute;
    top: 100%;
    height: 3px;
    width: 180px;
    left: 50%;
    transform: translate(-50%, -50px);
    background: rgba($color, 0.3);
    @media screen and (max-width: $small) {
      width: 100%;
      transform: translate(-50%, -80px);
    }
    .position__indicator {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 10%;
      background: $color;
      transition: left 300ms ease;
    }
  }

  .arrows {
    .prev,
    .next {
      position: absolute;
      left: 100%;
      top: calc(50% - 30px);
      transform: translate(0, -50%);
      width: 24px;
    }
    .prev {
      right: 100%;
      left: auto;
      transform:  translate(0, -50%) rotate(-180deg);
    }
  }
  
  .box {
    background: $primary;
    color: $color;
    padding: 20px;
    position: relative;
    overflow: hidden;
    max-width: 400px;
    transition: background-color 500ms ease;

    &:after {
      content: '';
      display: block;
      padding-top: 110.5%;
    }
    > * {
      position: absolute;
    }
    .box__background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transform: scale(1);
      opacity: 0;
      transition: transform 500ms ease, opacity 500ms ease;
    }
    .box__content {
      bottom: 20px;
      left: 20px;
      right: 20px;
      padding-bottom: 20px;
      font-size: 38px;
      transition: color 500ms ease;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) {
        font-size: 25px;
      }
    }
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16px;
      height: 16px;
      transform: rotate(-45deg);
      transition: transform 500ms ease;
      > * {
        transition: fill 500ms ease;
        fill: $color;
      }
    }
    &.active {

    }
  }
}
</style>