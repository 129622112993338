<template>
  <div class="facts">
    <div class="background"></div>
    <div class="wrapper">
      <h5>{{ data.title }}</h5>
      <div class="content">
        <div class="fact" v-for="(fact, key) in data.facts" :key="key">
          <span class="number">{{ fact.number }}</span>
          <span class="label">{{ fact.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Facts',
  props: ['data']
}
</script>

<style lang="scss">
@import '../../styles/config';
.facts {
  margin: 0 0 210px;
  @media screen and (max-width: $small) {
    margin: 0 0 80px;
  }
  h5 {
    margin: 0 0 100px;
    @media screen and (max-width: $small) {
      margin: 0 0 50px;
    }
  }
  .content {
    display: flex;
    @media screen and (max-width: $xsmall) {
      display: block;
    }
    .fact {
      flex: 1;
      @media screen and (max-width: $small) {
        margin: 0 0 50px;
      }
      .number {
        display: block;
        font-size: 189px;
        color: $primary;
        line-height: 1em;
        @media screen and (max-width: $small) {
          font-size: 90px;
        }
      }
      .label {
        font-size: 17px;
        @media screen and (max-width: $small) {
          font-size: 15px;
        }
      }
    }
  }
  
}
</style>