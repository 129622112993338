import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    all: null,
    expertise: null
  },
  mutations: {
    setExpertises(state, posts) {
      state.all = posts
    },
    setExpertise(state, expertise) {
      state.expertise = expertise
    }
  },
  actions: {
    getAll({ commit }, { language }) {
      (new APIService).getPosts('expertises', language ).then( (posts) => {
        commit('setExpertises', posts.data)
      })
    },
    getExpertise({ commit }, { slug, language}) {
      return new Promise((resolve, reject) => {
        (new APIService).getBySlug(slug, 'expertise', language).then( (post) => {
          let p = post.data[0];
          commit('setExpertise', p);
          resolve(p);
        }).catch(e => reject(e));
      })
    },
  }
}