<template>
  <div class="toTop" @click="toTop" :class="{ show: show }">
    <svg class="circle" width="64" height="64" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle ref="circle" stroke-width="2" cx="32" cy="32" r="31" ></circle>
    </svg>
    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'ToTop',
  setup() {
    const circle = ref(null);
    return {
      circle
    }
  },
  data() {
    return {
      circumference: 0,
      show: false
    }
  },
  watch: {
    '$route'() {
      this.show = false;
    }
  },
  mounted() {
    var circle = this.$refs.circle;
    if (!circle) return;
    var radius = circle.r.baseVal.value;
    this.circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    circle.style.strokeDashoffset = `${this.circumference}`;

    this.setProgress(0);

    if(typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        const progress = (window.scrollY + window.innerHeight)/document.body.offsetHeight * 100;
        this.show = document.body.offsetHeight > 1000 ? progress > 25 : false;
        this.setProgress(progress)
      })
    }
    
  },
  methods: {
    setProgress(percent) {
      percent = document.body.offsetHeight < 1000 ? 0 : percent;
      const offset = this.circumference - percent / 100 * this.circumference;
      if (!this.$refs.circle) return;
      this.$refs.circle.style.strokeDashoffset = offset;
    },
    toTop() {
      if(typeof window != 'undefined') window.scrollTo(0,0);
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/index';
  .toTop {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 6px solid #fff;
    transform: translate(0, 100px);
    opacity: 0;
    transition: transform 500ms ease, opacity 500ms ease;
    z-index: 45;
    background-color: rgba(255,255,255,1);
    cursor: pointer;
    &.show {
      opacity: 1;
      transform: translate(0, 0);
    }
    svg.circle {
      width: 68px;
      height: 68px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      circle {
        fill: transparent;
        stroke: $color;
      }
    }
    svg.arrow {
      width: 22px;
      height: 22px;
      transform: translate(-50%, -50%) rotate(-90deg);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
</style>