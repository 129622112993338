import { createRouter, createWebHistory } from 'vue-router'

import NewsDetail from './pages/news/detail'
import NewsIndex from './pages/news/index'
import Page from './pages/page'
import NotFound from './pages/NotFound'
import Meta from './pages/meta'
import Project from './pages/project'
import PublicationsDetail from './pages/publications/detail'
import PDFDetail from './pages/pdf'
import PublicationsIndex from './pages/publications/index'
import ExpertiseDetail from './pages/expertises/detail'


const RoutesConfiguration = [
  {
    path: '/news',
    component: NewsIndex
  },
  {
    path: '/news/',
    component: NewsIndex
  },
  {
    path: '/en/news',
    component: NewsIndex
  },
  {
    path: '/en/news/',
    component: NewsIndex
  },
  {
    path: '/news/:slug',
    component: NewsDetail
  },
  {
    path: '/en/news/:slug',
    component: NewsDetail
  },
  {
    path: '/themen',
    component: PublicationsIndex
  },
  {
    path: '/en/topics',
    component: PublicationsIndex
  },
  {
    path: '/themen/:slug',
    component: PublicationsDetail
  },
  {
    path: '/en/topics/:slug',
    component: PublicationsDetail
  },
  {
    path: '/pdf/:slug',
    component: PDFDetail
  },
  {
    path: '/en/pdf/:slug',
    component: PDFDetail
  },
  {
    path: '/expertises/:slug',
    component: ExpertiseDetail
  },
  {
    path: '/en/expertises/:slug',
    component: ExpertiseDetail
  },
  {
    path: '/projekt/:slug',
    component: Project
  },
  {
    path: '/en/project/:slug',
    component: Project
  },
  {
    path: '/meta/:slug',
    component: Meta
  },
  {
    path: '/en/meta/:slug',
    component: Meta
  },
  {
    path: '/en/404',
    component: NotFound
  },
  {
    path: '/en/:slug',
    component: Page
  },
  {
    path: '/',
    component: Page
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/:slug',
    component: Page
  },
];

export function NKCreateRouter () {
  return createRouter({
    history: createWebHistory(),
    routes: RoutesConfiguration,
  })
}