<template>
  <div v-if="expertise && expertise.acf" class="expertise">
    <expertise-layer :expertise="expertise" :data="expertise" :inPage="true"></expertise-layer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixin from '../../mixins/titleMixin';

import metaMixin from '../../mixins/metaMixin'
import ExpertiseLayer from '../../components/elements/ExpertiseLayer.vue';

export default {
  name: 'ExpertiseDetail',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.expertise ? 'NUNATAK - ' + this.expertise.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.expertise && this.expertise.acf && this.expertise.acf.find) {
      const desc = this.expertise.acf.find(page => page.component === 'description');
      const keywords = this.expertise.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null,
      }
    }
  },
  computed: { 
    ...mapState({
      expertise: state => state.expertise.expertise,
      page: state => state.page.page,
      language: state => state.language.language
    }),
    routeSlug() {
      return this.$route.params.slug;
    }
  },
  components: {
    ExpertiseLayer
  },
  watch: {
    routeSlug() {
      this.init();
    }
  },
  serverPrefetch() {
    return this.init();
  },
  asyncData({store, route}) {
    return new Promise((resolve) => {
      Promise.all(
        [
          store.dispatch('expertise/getExpertise', {
            slug: route.params.slug,
            language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
          })
        ]
      ).finally(() => resolve());
    }) 
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      return new Promise((resolve, reject) => {
        const lang = (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de';
        this.$store.dispatch('language/setCurrentLanguage', lang)
        this.$store.dispatch('expertise/getExpertise', {
          slug: this.$route.params.slug,
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.expertise.translations[(this.expertise.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('language/setCurrentLanguage', this.expertise.lang)
          resolve();
          this.$root.loading = false;
        }).catch((e) => reject(e))
      });
    },
  }
}
</script>

<style lang="scss">
.publicationContent {
  padding-top: 40px;
  margin-bottom: 150px;
}

</style>