<template>
  <div class="wrapper" v-if="posts && highlighted">
    <h5>News</h5>
    <div class="newsSlider">
      <div class="highlighted">
        <div class="image">
          <router-link :to="pathTo(highlighted.slug)">
            <lazy-image v-if="!showMobileImage || !highlighted.acf[0].data.use_mobile_image" :image="highlighted.acf[0].data.header_image"></lazy-image>
            <lazy-image v-if="showMobileImage && highlighted.acf[0].data.use_mobile_image" :image="highlighted.acf[0].data.mobile_image"></lazy-image>
          </router-link>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <span class="date">{{ formatDate(highlighted.date) }} <span class="category" v-for="categoryId in highlighted.categories" :key="categoryId" ><router-link v-if="getCategory(categoryId).slug" :to="language === 'en' ? '/en/news/#' + getCategory(categoryId).slug : '/news/#' + getCategory(categoryId).slug"># {{ getCategory(categoryId).name }}</router-link></span> </span>
          <router-link :to="pathTo(highlighted.slug)" class="titleLink">
            <h4 v-html="highlighted.title.rendered"></h4>
          </router-link>
          <router-link :to="pathTo(highlighted.slug)" class="arrowLink">
            {{ $root.translate("read_article") }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </router-link>
        </div>
        <div class="sliderMask" ref="slider" >
          <div class="slider" :style="{ left: `-${(currentPosition * slideWidth)}%`}">
            <div class="item" v-for="(item, key) in others" :key="key">
              <figure>
                <div><router-link :to="pathTo(item.slug)">
                  <lazy-image :image="item.acf[0].data.header_image" replaceSize="medium_large"></lazy-image></router-link>
                </div>
                <figcaption>
                  <span class="date">{{ formatDate(item.date) }} <span class="category" v-for="categoryId in item.categories.slice(0,1)" :key="categoryId" ><router-link :to="language === 'en' ? '/en/news/#' + getCategory(categoryId).slug : '/news/#' + getCategory(categoryId).slug"># {{ getCategory(categoryId).name }}</router-link></span> </span>
                </figcaption>
              </figure>
                <router-link :to="pathTo(item.slug)" class="titleLink">
                  <h4 v-html="item.title.rendered"></h4>
                </router-link>
                <router-link :to="pathTo(item.slug)" class="arrowLink">
                  {{ $root.translate("read_article") }}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                </router-link>
            </div>
          </div>
        </div>
        
        <div class="process">
          <div class="position" :style="{ 
            width: (perPage / (Math.ceil(othersAmount/perPage)*perPage) * 100) + '%', 
            left: (currentPosition * (perPage/(Math.ceil(othersAmount/perPage)*perPage)) * 100) + '%'
          }"></div>
        </div>

        <div class="arrows">
          <div class="next" @click="nextStop">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </div>
          <div class="prev" @click="prevStop">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
let Hammer;
if(typeof window !== 'undefined') {
  Hammer = require('hammerjs');
}
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
import APIService from '../../helpers/APIService';
import LazyImage from './LazyImage';
import { pathTranslate } from '../../helpers/NavigationService';

export default {
  name: 'NewsSlider',
  props: ['data'],
  components: {
    LazyImage
  },
  data() {
    return {
      currentPosition: 0,
      perPage: 1,
      categories: [],
      slideWidth: 85,
      randomPosts: [],
      interval: null,
      showMobileImage: false
    }
  },
  computed: { 
    ...mapState({
      posts: state => state.post.posts,
      language: state => state.language.language
    }),
    highlighted() {
      return this.posts[0];
    },
    others() {
      return this.posts.slice(1);
    },
    othersAmount() {
      return this.others.length;
    }
  },
  watch: {
    language() {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    if(this.interval) clearInterval(this.interval);
  },
  methods: {
    init() {
      
      this.$store.dispatch('post/getPosts', { language: this.language }).then(() => {
        (new APIService).getCategories().then((categories) => {
          this.categories = categories.data;
        });

        this.$nextTick(() => {
          if(!Hammer || !this.$refs.slider) return;
          const hammer = new Hammer(this.$refs.slider);
          
          hammer.on('panstart', ({ direction }) => {
            clearInterval(this.interval);
            if(direction === 2) {
              this.next();
            }
            else {
              this.prev();
            }
          })

          const scene = new ScrollMagic.Scene({
            triggerElement: this.$el,
            offset: 0
          })
          .addTo(window.ScrollMagicController); 

          scene.on('start', () => {
            this.triggerInterval();
          })
        });
      });
      if(typeof window !== 'undefined') {
        window.addEventListener('resize', () => this.checkWidth());
      }
      this.checkWidth();
    },
    shuffledPosts() {
      const shuffle = a => {
          for (let i = a.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [a[i], a[j]] = [a[j], a[i]];
          }
          return a;
      }
      return shuffle(this.posts);
    },
    triggerInterval() {
      if(this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => this.next(), 7000);
    }, 
    checkWidth() {
      if(window.innerWidth < 768) {
        this.perPage = 1;
        this.slideWidth = 85;
        this.showMobileImage = true;
      }
      else {
        this.perPage = 2;
        this.slideWidth = 100;
        this.showMobileImage = false;
      }
    },
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    nextStop() {
      if(this.interval) {
        clearInterval(this.interval);
      }
      this.next();
    },
    next() {
      if(this.currentPosition +1 > Math.ceil(this.othersAmount/this.perPage) -1) {
        this.currentPosition = 0; 
      }
      else {
        this.currentPosition += 1;
      }
    },
    prevStop() {
      if(this.interval) {
        clearInterval(this.interval);
      }
      this.prev();
    },
    prev() {
      if(this.currentPosition -1 < 0) {
        this.currentPosition = Math.ceil(this.othersAmount/this.perPage) -1; 
      }
      else {
        this.currentPosition -= 1;
      }
    },
    getCategory(id) {
      const categories = this.categories.length ? this.categories.filter((cat) => cat.id === id) : [];
      return categories.length ? categories[0] : { slug: null };
    },
    pathTo(slug) {
      return pathTranslate('news', this.language) + slug;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.newsSlider {
  display: flex;
  margin-bottom: 200px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none;
  -webkit-user-drag: none;
  img {
    -webkit-user-drag: none;
  }
  @media screen and (max-width: $medium) {
    margin-bottom: 140px;
  }
  @media screen and (max-width: $small) {
    display: block;
  }
  .highlighted {
    flex: 1;
    position: relative;
    max-width: calc(33.333% - 30px);
    width: 0;
    margin-right: 30px;
    @media screen and (max-width: $small) {
      width: 100%;
      max-width: 100%;
    }
    .image {
      display: block;
      overflow: hidden;
      position: absolute;
      right: 0;
      width: 808px;
      @media screen and (max-width: $small) {
        position:relative;
        width: calc(100% + 20px);
        right: auto;
        left: -20px;
      }
      
      &:after {
        content: '';
        padding-top: 80%;
        display: block;
      }
      img {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        min-height: 100%;
        max-height: 150%;

        min-width: 100%;
        transform: translate(-50%, -50%);
        transition: transform 500ms ease;
      }
      a {
        &:hover {
          img {
            transform: translate(-50%, -50%) scale(1.1);
          }
        }
      }
    }
  }
  .right {
    flex: 2;
    width: 66.66%;
    @media screen and (max-width: $small) { 
      width: 100%;
    }
    .title {
      width: 100%;
      margin: 0 0 20px;
      .date {
        letter-spacing: 0.135em;
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        margin-bottom: 15px;
        .category {
          color: $primary;
          
          a {
            text-decoration: none;
            .academy & {
              color: $academy;
            }
          }
        }
        @media screen and (max-width: $small) { 
          font-size: 10px;
          margin-top: 10px;
        }
      }
      a.titleLink {
        text-decoration: none;
        color: $color;
      }
      h4 {
        font-size: 40px;
        line-height: 44px;
        font-weight: 300;
        margin: 0 0 0.5em;
        width: 90%;
        @media screen and (max-width: $small) { 
          font-size: 25px;
          line-height: 30px;
          width: 100%;
        }
      }
    }
    .sliderMask {
      overflow: hidden;
      width: 100%;
      transform: translate(0, 0);
      position: relative;
      margin-bottom: 0px;
      padding-top: 30px;
      @media screen and (max-width: $small) {
        width: 100%;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 25%;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, white 100%);
          opacity: 0.5;
          pointer-events: none;
        }
      }
      
      .slider {
        display: flex;
        transition: transform 500ms ease, left 500ms ease;
        position: relative;
        
        .item {
          min-width: 50%;
          padding: 0 10px 0 0;
          box-sizing: border-box;
          width: 0;
          @media screen and (max-width: $small) {
            min-width: 85%;
          }
          .date {
            letter-spacing: 0.135em;
            text-transform: uppercase;
            font-size: 12px;
            display: block;
            margin-bottom: 20px;
            position: absolute;
            right: 0;
            transform: rotate(-90deg) translate(100%, 10px);
            transform-origin: right top;
            white-space: nowrap;
            @media screen and (max-width: $small) {
              font-size: 10px;
            }
            .category {
              color: $primary;
              a {
                text-decoration: none;
                .academy & {
                  color: $academy;
                }
              }
            }
          }
          figure {
            margin: 0 100px 0 0;
            padding: 0;
            position: relative;
            div {
              overflow: hidden;
              position: relative;
              background: $primary;
              &:after {
                content: '';
                display: block;
                padding: 80% 0 0;
              }
              img {
                position: absolute;
                top: 0;
                bottom: 0;
                display: block;
                left: 50%;
                height: 100%;
                transform: translate(-50%);
                transition: transform 500ms ease;
              }
              a {
                &:hover {
                  img {
                    transform: translate(-50%) scale(1.1);
                  }
                }
              }
            }
            
          }
          a.titleLink {
            text-decoration: none;
            color: $color;
          }
          h4 {
            font-size: 25px;
            line-height: 33px;
            font-weight: 300;
            width: 90%;
            margin: 20px 0;
            @media screen and (max-width: $small) {
              font-size: 17px;
              line-height: 22px;
            }
          }
        }
      }
    }
    
    .process {
      margin: 20px 0 0 0;
      width: calc(50% - 60px);
      height: 3px;
      background: #cdcdd3;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      @media screen and (max-width: $small) {
        width: 100%;
        margin: 20px 0 0 0;
      }
      .position {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: $color;
        transition: left 500ms ease;
      }
    }
  }
  .arrows {
    max-width: 100%;
    position: relative;
    @media screen and (max-width: $small) {
      display: none;
    }
    .prev,
    .next {
      position: absolute;
      right: 0;
      top: 50%;
      cursor: pointer;
      transform: translate(0, -50%);
      svg {
        width: 16px;
        height: 16px;
        transform: translate(0, 0);
        transition: 500ms transform ease;
      }
      &:hover{
        svg {
          transform: translate(5px, 0);
        }
      }
    }
    .prev {
      transform: translate(0,-15px) rotate(180deg) ;
      right: auto;
      left: 0;
      &:hover{
        svg {
          transform: translate(5px, 0);
        }
      }
    }
  }  
}
</style>