<template>
  <div>
    <div class="contentHeadline" v-if="post && ((post.type === 'publication' && isFirst) || (post.type === 'pdf' && isFirst))">
      <h5 class="category" v-if="post.type != 'pdf'">{{ $root.translate('publication') }}</h5>
      <h2 v-html="post.title.rendered"></h2>
      <div class="dates">
        <div class="date">{{ $root.translate('published') }}: {{ formatDate(post.date) }}</div>
        <div class="date primary">{{ $root.translate('updated') }}: {{ formatDate(post.modified) }}</div>
      </div>
    </div>
    <div class="contentText" v-html="data.text">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentText',
  props: ['data', 'post'],
  computed: {
    
    isFirst() {
      let first = false;
      this.post.acf.forEach((acf) => {
        if(acf.component == 'content_container') {
          for(let i = 0; i < acf.data.length; i++) {
            let data = acf.data[i];
            if(data.acf_fc_layout == 'text' && !first) {
              first = data.text == this.data.text;
              break;
            }
          }
        }
      })

      return first;
    }
  },
  methods: {
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';
.contentHeadline {
  .dates {
    font-size: 13px;
    margin: 0 0 3em;
    letter-spacing: 0.135em;
    line-height: 22px;
    @media screen and (max-width: $medium) {
      margin: 0 0 1.5em;
    }
    .date {
      &.primary {
        color: $primary;
      }
    }
  }
  h2 {
    font-size: 38px;
    margin: 0 0 20px;
    font-weight: normal;
    padding: 0 0 20px;
    position: relative;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    &:after {
      content: '';
      display: block;
      width: 56px;
      height: 6px;
      background: $primary;
      position: absolute;
      bottom: 0;
    }
  }
}

.contentText {
  max-width: 66.66%;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 100px;
  h3 {
    padding-top: 30px;
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
  }
  p {
    margin: 0 0 50px;
  }
  ul {
    margin: -25px 0 50px;
  }
  a {
    color: $color;
    text-decoration: underline;
    transition: color 500ms ease;
    &:hover {
      color: $primary;
    }
  }
  @media screen and (max-width: $medium) { 
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 70px;
    max-width: none;
    h3 {
      font-size: 17px;
      line-height: 24px;
    }
    p {
      margin: 0 0 25px;
    }
    ul {
      margin: -10px 0 25px;
    }
  }
}
</style>