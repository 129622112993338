<template>
  <div class="imageComponent">
    <div class="wrapper">
        <div class="imageComponent__intro" style="margin-bottom: 30px;">
          <div class="imageComponent__introHeadline">
            <h5>{{ data[0].title }}</h5>
            <h3>{{ data[0].subline }}</h3>
          </div>
        </div>
        <div class="image" v-if="data[0].image && data[0].image.url">
          <img :src="data[0].image.url" />
        </div>
      </div>
  </div>
</template>
<script>
/* eslint-disable */ 
export default {
  name: 'Image',
  props: ['data']
}
</script>
<style lang="scss">
@import '../../styles/config';
.imageComponent {
  &__intro {
    display: flex;
    margin-bottom: 165px;
    @media screen and (max-width: $small) {
      display: block;
      margin-bottom: 100px;
    }
    &Headline {
      width: 37%;
      flex: 1;
      @media screen and (max-width: $small) {
        width: auto;
        margin-bottom: 1em;
      }
      h5 {
        margin: 0 0 0.5em;
      }
      h3 {
        font-size: 38px;
        line-height: 47px;
        font-weight: 300;
        margin: 0 0 40px;
        @media screen and (max-width: $medium) { 
          font-size: 30px;
          line-height: 38px;
        }
        @media screen and (max-width: $small) { 
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
    
  }
  
  .image {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>