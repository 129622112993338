<template>
  <div class="introText wrapper">
    <social-shares></social-shares>
    <div class="date">{{ formatDate(post.date) }}</div>
    <div class="text" v-html="replaceNbsp(data)"></div>
  </div>
</template>

<script>
import SocialShares from './SocialShares';
export default {
  name: 'IntroText',
  props: ['post', 'data'],
  components: {
    SocialShares
  },
  methods: {
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    replaceNbsp(textNode) {
      return textNode = textNode.replace(/\u00a0/g, " ");
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.introText {
  margin-bottom: 70px !important;
  @media screen and (max-width: $medium) {
    margin-bottom: 40px !important;
  }

  .date {
    font-size: 13px;
    margin: 0 0 3em;
    letter-spacing: 0.135em;
    @media screen and (max-width: $medium) {
      margin: 0 0 1.5em;
    }
  }
  .text {
    font-size: 27px;
    line-height: 36px;
    font-family: 'HKGrotesk';
    font-weight: 300;
    p {
      margin: 0;
    }
    @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 25px;
    }
  }
}
  
</style>