<template>
  <div class="socialShares">
    <ul>
      <li><a :href="shareOn('twitter')" target="_blank"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M164.3 287.2c78.3 0 121.2-64.9 121.2-121.2 0-1.8 0-3.7-.1-5.5 8.3-6 15.5-13.5 21.3-22.1-7.6 3.4-15.9 5.6-24.5 6.7 8.8-5.2 15.5-13.6 18.8-23.6-8.2 4.9-17.4 8.4-27.1 10.3-7.8-8.3-18.9-13.5-31.1-13.5-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.6 1.1 9.7-35.4-1.7-66.8-18.8-87.8-44.5-3.7 6.3-5.7 13.6-5.7 21.4 0 14.8 7.6 27.8 18.9 35.5-7-.2-13.5-2.2-19.3-5.3v.6c0 20.6 14.7 37.9 34.1 41.8-3.6 1-7.3 1.5-11.2 1.5-2.7 0-5.4-.2-8-.7 5.4 16.9 21.2 29.2 39.8 29.6-14.6 11.5-33 18.3-52.9 18.3-3.4 0-6.8-.2-10.1-.6 18.7 11.9 41.2 19 65.2 19"/><path class="st0" d="M200 6.2c26.2 0 51.6 5.1 75.5 15.2 23.1 9.8 43.8 23.7 61.6 41.5 17.8 17.8 31.8 38.5 41.5 61.6 10.1 23.9 15.2 49.3 15.2 75.5s-5.1 51.6-15.2 75.5c-9.8 23.1-23.7 43.8-41.5 61.6-17.8 17.8-38.5 31.8-61.6 41.5-23.9 10.1-49.3 15.2-75.5 15.2s-51.6-5.1-75.5-15.2c-23.1-9.8-43.8-23.7-61.6-41.5-17.8-17.8-31.8-38.5-41.5-61.6C11.3 251.6 6.2 226.2 6.2 200s5.1-51.6 15.2-75.5c9.8-23.1 23.7-43.8 41.5-61.6 17.8-17.8 38.5-31.8 61.6-41.5C148.4 11.3 173.8 6.2 200 6.2m0-6C89.6.2.2 89.6.2 200S89.6 399.8 200 399.8 399.8 310.4 399.8 200 310.4.2 200 .2z"/></svg></a></li>
      <li><a :href="shareOn('facebook')" target="_blank"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M200 6.2c26.2 0 51.6 5.1 75.5 15.2 23.1 9.8 43.8 23.7 61.6 41.5 17.8 17.8 31.8 38.5 41.5 61.6 10.1 23.9 15.2 49.3 15.2 75.5s-5.1 51.6-15.2 75.5c-9.8 23.1-23.7 43.8-41.5 61.6-17.8 17.8-38.5 31.8-61.6 41.5-23.9 10.1-49.3 15.2-75.5 15.2s-51.6-5.1-75.5-15.2c-23.1-9.8-43.8-23.7-61.6-41.5-17.8-17.8-31.8-38.5-41.5-61.6C11.3 251.6 6.2 226.2 6.2 200s5.1-51.6 15.2-75.5c9.8-23.1 23.7-43.8 41.5-61.6 17.8-17.8 38.5-31.8 61.6-41.5C148.4 11.3 173.8 6.2 200 6.2m0-6C89.6.2.2 89.6.2 200S89.6 399.8 200 399.8 399.8 310.4 399.8 200 310.4.2 200 .2z"/><path class="st0" d="M239.9 210.7l5.8-37.5h-36v-24.4c0-10.3 5-20.3 21.2-20.3h16.4v-32S232.4 94 218.2 94c-29.7 0-49 18-49 50.5v28.6h-33v37.5h33v90.7h40.6v-90.7h30.1z"/></svg></a></li>
      <li><a :href="shareOn('linkedin')" target="_blank"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M200 6.2c26.2 0 51.6 5.1 75.5 15.2 23.1 9.8 43.8 23.7 61.6 41.5 17.8 17.8 31.8 38.5 41.5 61.6 10.1 23.9 15.2 49.3 15.2 75.5s-5.1 51.6-15.2 75.5c-9.8 23.1-23.7 43.8-41.5 61.6-17.8 17.8-38.5 31.8-61.6 41.5-23.9 10.1-49.3 15.2-75.5 15.2s-51.6-5.1-75.5-15.2c-23.1-9.8-43.8-23.7-61.6-41.5-17.8-17.8-31.8-38.5-41.5-61.6C11.3 251.6 6.2 226.2 6.2 200s5.1-51.6 15.2-75.5c9.8-23.1 23.7-43.8 41.5-61.6 17.8-17.8 38.5-31.8 61.6-41.5C148.4 11.3 173.8 6.2 200 6.2m0-6C89.6.2.2 89.6.2 200S89.6 399.8 200 399.8 399.8 310.4 399.8 200 310.4.2 200 .2z"/><path id="Path_2520" class="st0" d="M289 273.6h-35.4v-55.4c0-13.2-.2-30.2-18.4-30.2-18.4 0-21.2 14.4-21.2 29.2v56.3h-35.4V159.7h33.9v15.6h.5c6.9-11.8 19.8-18.9 33.5-18.4 35.8 0 42.4 23.6 42.4 54.2l.1 62.5zM138.7 144.2c-11.3 0-20.5-9.2-20.5-20.5s9.2-20.5 20.5-20.5 20.5 9.2 20.5 20.5c.1 11.3-9.1 20.5-20.5 20.5m17.7 129.4H121V159.7h35.4v113.9z"/></svg></a></li>
      <li><a :href="shareOn('xing')" target="_blank"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M200 6.2c26.2 0 51.6 5.1 75.5 15.2 23.1 9.8 43.8 23.7 61.6 41.5 17.8 17.8 31.8 38.5 41.5 61.6 10.1 23.9 15.2 49.3 15.2 75.5s-5.1 51.6-15.2 75.5c-9.8 23.1-23.7 43.8-41.5 61.6-17.8 17.8-38.5 31.8-61.6 41.5-23.9 10.1-49.3 15.2-75.5 15.2s-51.6-5.1-75.5-15.2c-23.1-9.8-43.8-23.7-61.6-41.5-17.8-17.8-31.8-38.5-41.5-61.6C11.3 251.6 6.2 226.2 6.2 200s5.1-51.6 15.2-75.5c9.8-23.1 23.7-43.8 41.5-61.6 17.8-17.8 38.5-31.8 61.6-41.5C148.4 11.3 173.8 6.2 200 6.2m0-6C89.6.2.2 89.6.2 200S89.6 399.8 200 399.8 399.8 310.4 399.8 200 310.4.2 200 .2z"/><path class="st0" d="M123.1 135.6c-1.8 0-3.3.6-4 1.8-.8 1.3-.7 2.9.2 4.5l20 34.6v.2l-31.4 55.4c-.8 1.6-.8 3.3 0 4.5.8 1.2 2.1 2 3.9 2h29.5c4.4 0 6.5-3 8.1-5.7 0 0 30.7-54.3 31.9-56.4-.1-.2-20.3-35.4-20.3-35.4-1.5-2.6-3.7-5.5-8.2-5.5h-29.7zM247.2 95.2c-4.4 0-6.3 2.8-7.9 5.6 0 0-63.6 112.8-65.7 116.6.1.2 42 77 42 77 1.5 2.6 3.7 5.6 8.3 5.6h29.5c1.8 0 3.2-.7 3.9-1.9.8-1.3.8-2.9-.1-4.6l-41.6-76.1v-.2L281 101.6c.8-1.6.8-3.3.1-4.6-.8-1.2-2.1-1.9-3.9-1.9l-30 .1z"/></svg></a></li>
      <li><a :href="shareOn('mail')" target="_blank"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M200 6.2c26.2 0 51.6 5.1 75.5 15.2 23.1 9.8 43.8 23.7 61.6 41.5 17.8 17.8 31.8 38.5 41.5 61.6 10.1 23.9 15.2 49.3 15.2 75.5s-5.1 51.6-15.2 75.5c-9.8 23.1-23.7 43.8-41.5 61.6-17.8 17.8-38.5 31.8-61.6 41.5-23.9 10.1-49.3 15.2-75.5 15.2s-51.6-5.1-75.5-15.2c-23.1-9.8-43.8-23.7-61.6-41.5-17.8-17.8-31.8-38.5-41.5-61.6C11.3 251.6 6.2 226.2 6.2 200s5.1-51.6 15.2-75.5c9.8-23.1 23.7-43.8 41.5-61.6 17.8-17.8 38.5-31.8 61.6-41.5C148.4 11.3 173.8 6.2 200 6.2m0-6C89.6.2.2 89.6.2 200S89.6 399.8 200 399.8 399.8 310.4 399.8 200 310.4.2 200 .2z"/><path class="st0" d="M110.4 133v11.2l89.6 56 89.6-56V133H110.4zm0 31.2v106.4h179.2V164.2l-89.6 56-89.6-56z"/></svg></a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialShares',
  methods: {
    shareOn(channel) {
      switch(channel) {
        case 'twitter':
          return 'http://www.twitter.com/share?url=' + encodeURIComponent(typeof window !== 'undefined' ? window.location.href : null);
        case 'facebook':
          return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(typeof window !== 'undefined' ? window.location.href : null);
        case 'linkedin':
          return 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(typeof window !== 'undefined' ? window.location.href : null);
        case 'xing':
          return 'https://www.xing.com/spi/shares/new?url=' + encodeURIComponent(typeof window !== 'undefined' ? window.location.href : null);
        case 'mail':
          return 'mailto:?subject=NUNATAK%20News&body=' + encodeURIComponent(typeof window !== 'undefined' ? window.location.href : null);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/config';
.socialShares {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: 20px;
  max-width: 400px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin: 0 0 1em;
      a {
        svg {
          fill: $color;
          width: 45px;
          height: 45px;
          transition: fill 500ms ease;
        }
        &:hover {
          svg {
            fill: $primary;
          }
        }
      }
    }
  }
  @media screen and (max-width: $medium) {
    position: static;
    margin: 0 0 30px;
    ul {
      display: flex;
      justify-content: space-between;
    }
  }
}

</style>