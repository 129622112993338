const charMap = {
  'a': 'r',
  'b': 'f',
  'c': 't',
  'd': 'e',
  'e': 'h',
  'f': 'c',
  'g': 'b',
  'h': 's',
  'i': 'a',
  'j': 'i',
  'k': 'g',
  'l': 'u',
  'm': 'x',
  'n': 'y',
  'o': 'n',
  'p': 'm',
  'q': 'k',
  'r': 'd',
  's': 'v',
  't': 'w',
  'u': 'z',
  'v': 'q',
  'w': 'p',
  'x': 'j',
  'y': 'l',
  'z': 'o'
}



export const emailEncode = (email) => {
  const emailChars = email.split('');
  return emailChars.map(char => charMap[char] || char ).join('');
}

export const emailDecode = (encodedEmail) => {
  const emailChars = encodedEmail.split('');
  const reverseMap = {};
  for(var key in charMap) {
    reverseMap[charMap[key]] = key;
  }
  return emailChars.map(char => reverseMap[char] || char ).join('');
}