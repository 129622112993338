<template>
  <div class="wrapper">
    <div class="pdf-controls">
      <button @click="prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"></path></svg>
      </button>
      <a :href="data.file" download><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5" stroke-linejoin="round" class="feather feather-download" viewBox="0 0 24 24"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3"/></svg></a>
      <button @click="print">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" class="feather feather-printer" viewBox="0 0 24 24"><path d="M6 9V2h12v7M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"/><path d="M6 14h12v8H6z"/></svg>
      </button>
      <button @click="zoomIn"><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" class="feather feather-plus" viewBox="0 0 24 24"><path d="M12 5v14M5 12h14"/></svg></button>
      <button @click="zoomOut"><svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" class="feather feather-minus" viewBox="0 0 24 24"><path d="M5 12h14"/></svg></button>
      <button @click="next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"></path></svg>
      </button>
    </div>
    <div class="pdf-page-indicator">
      {{ currentPage }} / {{ maxPages }}
    </div>
    <div class="pdf-pages">
      <div class="pdf-placeholder"></div>
    </div>
  </div>

</template>

<script>
import * as PDFJS from 'pdfjs-dist';
let pdf = null;
let timeout;
export default {
  name: 'PDFViewer',
  props: ['data'],
  data() {
    return {
      loading: true,
      scale: 1,
      currentPage: 1,
      maxPages: 1
    }
  },
  mounted() {
    if (this.data && this.data.file) {
      this.render();
    }
    window.addEventListener('resize', () => {
      timeout && clearTimeout(timeout);
      timeout = setTimeout ( () => this.renderPage(), 100)
    })
  },
  methods: {
    print() {
      const mywindow = window.open(this.data.file);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
    },
    next() {
      this.currentPage = Math.min(this.maxPages, ++this.currentPage);
      this.renderPage()
    },
    prev() {
      this.currentPage = Math.max(1, --this.currentPage);
      this.renderPage();
    },
    zoomIn() {
      this.scale = Math.min(4, this.scale += 0.5)
      this.renderPage();
    },
    zoomOut() {
      this.scale = Math.max(1, this.scale -= 0.5)
      this.renderPage();
    },
    render() {
      (async () => {
        PDFJS.GlobalWorkerOptions.workerSrc = '/pdf.worker.js?v4';
        const loadingTask = PDFJS.getDocument(this.data.file);
        pdf = await loadingTask.promise;
        this.maxPages = pdf.numPages;
        await this.renderPage();
      })();
    },
    async renderPage() {
      const outputScale = window.devicePixelRatio || 1;
      // Load information from the first page.
      document.querySelector('.pdf-pages').innerHTML = '';
      const page = await pdf.getPage(this.currentPage);
      let viewport = page.getViewport({ scale: 1, dontFlip: true });

      let scale = (document.querySelector('.pdf-pages').offsetWidth -2)  / viewport.width * this.scale;
      if (window.innerHeight < 560) {
        scale = (document.querySelector('.pdf-pages').offsetHeight -2)  / viewport.height * this.scale;
      }

      viewport = page.getViewport({ scale });

      // Apply page dimensions to the `<canvas>` element.
      const canvas = document.createElement('canvas');
      document.querySelector('.pdf-pages').appendChild(canvas);
      const context = canvas.getContext('2d');
      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.width = Math.floor(viewport.width) + "px";
      canvas.style.height =  Math.floor(viewport.height) + "px";

      const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

      // Render the page into the `<canvas>` element.
      const renderContext = {
        canvasContext: context,
        transform,
        viewport,
      };
      await page.render(renderContext);
      this.loading = false;
        
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/index'; 
.wrapper {
  position: relative;
}
.pdf-pages {
  background: #f1f1f1;
  max-width: 100%;
  max-height: 1000px;
  overflow: auto;
  margin-bottom: 120px;
  border: 1px solid #f2f2f2;
  @media screen and (max-height: 560px) {
    height: 80vh;
  }
  .pdf-placeholder {
    aspect-ratio: 1.45;
    
  }
}
.pdf-page-indicator {
  background: $primary;
  color: $color;
  padding: 5px 10px;
  position: absolute;
  top: 33px;
  right: 40px;
  z-index: 11;
  @media screen and (max-width: $small) {
    top: 100%;
    right: auto;
    left: 50%;
    transform: translate(-50%);

  }
}
.pdf-controls {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 10px 0;
  button, a {
    width: 24px;
    height: 24px;
    background: none;
    border: 0;
    display: block;
    color: $color;
    padding: 2px;
    &:first-child {
      transform: rotate(-180deg);
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
  canvas {
    position: relative;
    z-index: 10;
    background: white;
    margin: 0 auto;
    display: block;
  }
</style>