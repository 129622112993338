<template>
  <div class="wrapper" v-if="data.title">
    <div class="teamTeaser">
        <div class="left">
          <h5>{{ data.title }}</h5>
          <h4 v-html="data.subline"></h4>
          <div class="intro" v-html="data.content"></div>
          <primary-button @click="toJobs">{{ $root.translate('job_offers') }}</primary-button>
        </div>
        <div class="right">
          <div @click="showVideo = true" @mouseenter="mouseenter()" @mouseleave="mouseleave()">
            <lazy-image :image="data.video_preview_image"></lazy-image>
            <small>{{ data.video_preview_image.caption }}</small>
            <primary-button @click="toJobs">{{ $root.translate('job_offers') }}</primary-button>
          </div>
        </div>
      </div>
      <video-layer v-if="data.youtube_id" :youtubeId="data.youtube_id" :show="showVideo" @close="showVideo = false"></video-layer>
  </div>
</template>

<script>
import PrimaryButton from './PrimaryButton'
import LazyImage from './LazyImage';
import VideoLayer from './VideoLayer';
export default {
  name: 'TeamTeaser',
  props: ['data'],
  data() {
    return {
      showVideo: false,
    }
  },
  components: {
    PrimaryButton,
    LazyImage,
    VideoLayer
  },
  methods: {
    toJobs() {
      const moduleTop = document.querySelector('#jobs') ? document.querySelector('#jobs').offsetTop : 0;
      window.scrollTo(0, moduleTop) 
    },
    mouseenter() {
      if(this.data.youtube_id) {
        this.$store.commit('mouseHovering', { color: 'whiteFocus', label: 'view video' })
      }
    },
    mouseleave() {
      if(this.data.youtube_id) {
        this.$store.commit('mouseHovering', null)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.teamTeaser {
  display: flex;
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    display: block;
    margin-bottom: 130px;
  }
  .right {
    flex: 1;
    min-width: 50%;
    position: relative;
    button{
      display: none;
    }
    @media screen and (max-width: $small) {
      margin-bottom: 40px;
      button {
        margin: 40px 0;
        display: block;
      }
    }
    > div {
      width: 50vw;
      overflow: hidden;
      height: 100%;
      position: absolute;
       @media screen and (max-width: $small) {
         position: relative;
         width: auto;
       }
      img {
        height: 100%;
        display: block;
        @media screen and (max-width: $small) {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  .left {
    flex: 1;
    min-width: 50%;
    padding-right: 80px;
    box-sizing: border-box;

    h5 {
      margin: 0 0 1em;
    }
    
    @media screen and (max-width: $small) {
      padding-right: 0;
      margin-bottom: 40px;
      button {
        display: none;
      }
    }
  }

  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: normal;
    margin: 0 0 50px;
    font-weight: 300;
    position: relative;
    padding-bottom: 20px;
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
      margin: 0 0 25px;
      padding-bottom: 15px;
    }
    &:after {
      content: '';
      height: 6px;
      width: 56px;
      background: $primary;
      position: absolute;
      top: 100%;
      display: block;
      @media screen and (max-width: $medium) {
        height: 3px;
      }
    }
  }
  .intro {
    font-size: 27px;
    line-height: 32px;
    position: relative;
    padding-bottom: 30px;
    margin: 0;
    @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 0;
      margin-bottom: 40px;
    }
  }
}
</style>