<template>
  <div class="">
    <div class="notFoundOverlay">
      <span class="number">404</span>
      <span class="question">{{ $root.translate('lost_question') }}</span>
      <router-link :to="$route.path.indexOf('/en') === 0 ? '/en' : '/'" class="arrowLink">
        {{ $root.translate('lost_link') }}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </router-link>
    </div>
    <div id="notFoundMountainContainer"></div>
  </div>
</template>

<script>
let notFoundMountainContainer = null;
export default {
  name: 'NotFound',
  mounted() {
    this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
    setTimeout(() => { 
      this.addMountains(); 
      this.$root.loading = false;
    }, 1000);
    document.body.classList.add('dark')
  },
  beforeUnmount() {
    document.body.classList.remove('dark');
    if (notFoundMountainContainer) {
      notFoundMountainContainer?.destroy();
    }
  },
  methods: {
    addMountains() {
      if (!document.querySelector('#notFoundMountainContainer')) {
        return;
      }
      notFoundMountainContainer = new window._MOUNTAINS({
        element: '#notFoundMountainContainer',
        seed: Math.round(Math.random() * 65500),
        disableUI: true,
        groundLevel: 15,
        screenshot: false,
        backgroundColor: 0x000000,
        groundColor: 0x000,
        lineColor: 0xababab,
        camera: {
            auto: true,
            motion: false,
            position: {
                x: 60,
                y: 30,
                z: 20
            },
            lookAt: {
                x: -30,
                y: 0,
                z: 0
            }
        }
      });
      notFoundMountainContainer.run();
    }
  }
}
</script>

<style lang="scss">
@import '../styles/index';

.notFoundOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  .number {
    color: black;
    font-size: 30vw;
    line-height: 1em;
    font-weight: bold;
  }
  .question {
    color: white;
    font-size: 37px;
    font-weight: 300;
    margin-bottom: 50px;
    text-align: center;
  }
}



#notFoundMountainContainer {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 0;
  opacity: 1;
  transition: opacity 500ms ease 200ms;
  canvas:nth-child(1) {
    display:none; 
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    bottom: 0;
  }
}

main {
  min-height: calc(100vh - 130px);
}

</style>