<template>
  <div class="headline">
    <h1>
      <slot></slot>
    </h1>
  </div>
</template>

<script>
</script>

<style lang="scss">
@import '../../styles/index';
.headline {
  h1 {
    font-weight: 300;
    color: $color;
    font-size: 60px;
    line-height: 66px;
    margin: 0;
    width: 540px;
    @media screen and (max-width: $small) {
      font-size: 33px;
      line-height: 38px;
    }
    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      &.bold {
        font-weight: bold;
      }
    }
  }
}
</style>