<template>
  <div class="wrapper" id="jobs">
    <div v-for="jobCategory in jobCategories" :key="jobCategory.id">
      <h5 v-if="getJobsByCategory(jobCategory.id)">{{ data.title }} - {{ jobCategory.name }}</h5>
      <div class="jobs" v-if="getJobsByCategory(jobCategory.id)">
        <div class="jobEntry" v-for="job in getJobsByCategory(jobCategory.id)" :key="job.id" :class="{ open: openJob.includes(job.id) }">
          <div class="jobTitle" @click.prevent="toggle(job.id, $event)">
            <small>{{ $root.translate('m-f-d') }}</small>
            <h2><span v-html="job.title.rendered"></span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg></h2> 
          </div>
          <div class="jobEntryContainer">
            <a class="close" href="" @click.prevent="close(job.id)">
              <span class="label">{{ $root.translate('close') }}</span>
              <span class="cross"></span>
            </a>
            <div class="jobType">
              {{ job.acf.type }}
            </div>
            <div class="jobContent">
              <div class="left">
                <div v-html="job.content.rendered"></div>
              </div>
              <div class="right">
                <primary-button @click="open(job.acf.link.url, '_blank')"><span v-html="job.acf.link.title"></span></primary-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapState } from 'vuex';
import PrimaryButton from './PrimaryButton';
import { getCoords } from '../../helpers/NavigationService.js';


export default {
  name: 'Jobs',
  data() {
    return {
      openJob: []
    }
  },
  components: {
    PrimaryButton
  },
  props: ['data'],
  computed: { ...mapState({
      jobs: state => state.job.jobs,
      jobCategories: state => state.job.jobCategories
    })
  },
  mounted() {
    this.$store.dispatch('job/getJobs', { language: this.language }).then(() => {
      this.$store.dispatch('job/getJobCategories', { language: this.language });
    });
    
  },
  methods: {
    getJobsByCategory(jobCategoryId) {
      if( !this.jobs ) return null;
      const jobs =  this.jobs.filter(job => {
        return job.jobcategory.includes(jobCategoryId);
      });

      if(jobs.length) {
        return jobs;
      }

      return null;
    },
    toggle(jobId, $event) {
      if(this.openJob.includes(jobId)) {
        this.openJob = this.openJob.filter(job => job != jobId);
      }
      else {
        this.openJob.push(jobId);
        const coords = getCoords($event.target);
        const delta = 120;
        window.scrollTo(0, coords.top - delta)
      }
    },
    close(jobId) {
      this.openJob = this.openJob.filter(job => job != jobId);
    },
    open(url, target) {
      window.open(url, target);
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.jobs {
  margin-bottom: 210px;
  @media screen and (max-width: $medium) {
    margin-bottom: 80px;
  }
  .jobEntry {
    @media screen and (max-width: $medium) {
      margin-bottom: 20px;
    }
    .jobTitle {
      display: flex;
      cursor: pointer;
      @media screen and (max-width: $small) {
        display: block;
      }
      small {
        line-height: 83px;
        font-size: 17px;
        display: block;
        margin-top: 8px;
        min-width: 100px;
        transition: color 500ms ease;
        @media screen and (max-width: $medium) {
          font-size: 15px;
          line-height: 24px;
        }
      }
      h2 {
        display: inline-block;
        font-size: 55px;
        line-height: 83px;
        font-weight: normal;
        margin: 0;
        transition: color 500ms ease;
        @media screen and (max-width: $medium) {
          font-size: 25px;
          line-height: 30px;
          //max-width: 80%;
        }
        svg {
          width: 16px;
          height: 16px;
          transform: rotate(-45deg);
          display: inline-block;
          margin-left: 60px;
          transition: transform 500ms ease;
          @media screen and (max-width: $medium) {
            margin-left: 10px;
          }
          * {
            fill: $color;
            transition: color 500ms ease;
          }
        }
      }
      &:hover {
        small {
          color: $primary;
        }
        h2 {
          color: $primary;
        }
        svg {
          * {
            fill: $primary;
          }
        }
      }
    }
    .jobEntryContainer {
      position: relative;
      padding: 0 0 0 100px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms ease, padding 500ms ease;
      @media screen and (max-width: $small) {
        padding-left: 0;
      }
      .close {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 20;
          .label {
            display: none;
          }
          @media screen and (max-width: $small) {
            bottom: 80px;
            top: auto;
            .label {
              display: block;
              position: absolute;
              top: 50%;
              right: 100%;
              transform: translate(0, -50%);
              color: $color;
            }
          } 
          .cross {
            width: 24px;
            height: 24px;
            display: block;
            position: relative;
            margin-left: 10px;
            &:before,
            &:after {
              content: '';
              display: block;
              height: 2px;
              width: 100%;
              background: $color;
              transform: translate(-50%, -50%) rotate(45deg);
              position: absolute;
              top: 50%;
              left: 50%;
            }
            &:before {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      .jobType {
        font-size: 17px;
        line-height: 30px;
        padding: 20px 0 20px;
        margin: 0 0 40px;
        position: relative;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
          padding-bottom: 15px;
          margin-bottom: 20px;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          height: 6px;
          width: 56px;
          background: $primary;
          top: 100%;
          @media screen and (max-width: $small) {
            height: 3px;
          }
        }
      }
      .jobContent {
        display: flex;
        font-size: 17px;
        line-height: 30px;
        align-items: flex-end;
        padding-bottom: 50px;
        border-bottom: 1px solid $color;
        @media screen and (max-width: $medium) {
          display: block;
          font-size: 15px;
          line-height: 24px;
          padding-bottom: 80px;  
        }
        .left {
          flex: 3;
          box-sizing: border-box;
          padding-right: 50px;
          @media screen and (max-width: $small) {
            padding-right: 0;
          }
          p:last-child {
            margin-bottom: 0;
            @media screen and (max-width: $medium) {
              margin-bottom: 40px;
            }
          }
        }
        .right {
          flex: 1;
        }
      }
    }
    &.open {
      .jobTitle {
        small {
          color: $primary;
        }
        h2 {
          color: $primary;
        }
        svg {
          transform: rotate(90deg);
          * {
            fill: $primary;
          }
        }
      }
      .jobEntryContainer {
        max-height: 1000px;
        padding-bottom: 60px;
      }
    }
  }

}
</style>