<template>
  <div class="contentQuote">
    <div class="quote" v-html="data.quote"></div>
    <div class="author" v-if="data.author" v-html="data.author"></div>
  </div>
</template>

<script>
export default {
  name: 'ContentQuote',
  props: ['data']
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';

.contentQuote {
  margin-bottom: 100px;
  @media screen and (max-width: $medium) {
    margin-bottom: 60px;
  }
  .quote {
    font-size: 38px;
    line-height: 47px;
    color: $primary;
    width: 72%;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      width: auto;
      font-size: 25px;
      line-height: 30px;
    }
  }
  .author {
    margin: 1em 0;
    &:before {
      content: '- ';
    }
  }
}
</style>