<template>
  <div class="contentContainer wrapper">
    <div class="contentContainer__element" v-for="(element, index) in data" :key="index">
      <component :is="'Content' + componentName(element.acf_fc_layout)" :data="element" :post="post"/>
    </div>
  </div>
</template>

<script>
import ContentText from './contents/ContentText'
import ContentFullWidthImage from './contents/ContentFullWidthImage'
import ContentQuote from './contents/ContentQuote'
import ContentTextImageBox from './contents/ContentTextImageBox'
import ContentYoutubeVideo from './contents/ContentYoutubeVideo'
import ContentButton from './contents/ContentButton'
import ContentTeaser from './contents/ContentTeaser'

export default {
  name: 'ContentContainer',
  props: ['data', 'post'],
  components: {
    ContentText,
    ContentFullWidthImage,
    ContentQuote,
    ContentTextImageBox,
    ContentYoutubeVideo,
    ContentButton,
    ContentTeaser
  },
  methods: {
    componentName(layout) {
      return layout.split('_').map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join('');
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/config';
  a {
    color: $primary
  }
</style>