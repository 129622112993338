import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    jobCategories: null,
    jobs: null
  },
  mutations: {
    setJobCategories(state, post) {
      state.jobCategories = post
    },
    setJobs(state, post) {
      state.jobs = post
    }
  },
  actions: {
    getJobs({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPosts('jobs', language).then( (post) => {
          commit('setJobs', post.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    },
    getJobCategories({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getJobCategories(language).then( categories => {
          commit('setJobCategories', categories.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
      })
    }
  }
}