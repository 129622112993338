<template>
  <div class="advisoryBoard wrapper" v-if="sortedMembers">
    <a id="advisoryboard"></a>
    <h5>{{ data.title }}</h5>
    <div class="subline" v-html="data.subline"></div>
    <div class="content" v-html="data.content"></div>
    <div class="teamMembers">
      <div class="member" v-for="(member, key) in shortenMembers" :key="key">
        <figure>
          <div class="socialIcons" v-if="member.acf.team_member.social_channels">
            <ul>
              <li v-for="(socialIcon, key) in member.acf.team_member.social_channels" :key="key">
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'linkedin'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M289 273.6h-35.4v-55.4c0-13.2-.2-30.2-18.4-30.2-18.4 0-21.2 14.4-21.2 29.2v56.3h-35.4V159.7h33.9v15.6h.5c6.9-11.8 19.8-18.9 33.5-18.4 35.8 0 42.4 23.6 42.4 54.2l.1 62.5zM138.7 144.2c-11.3 0-20.5-9.2-20.5-20.5s9.2-20.5 20.5-20.5 20.5 9.2 20.5 20.5c.1 11.3-9.1 20.5-20.5 20.5m17.7 129.4H121V159.7h35.4v113.9z" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'mail'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'twitter'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M164.3 287.2c78.3 0 121.2-64.9 121.2-121.2 0-1.8 0-3.7-.1-5.5 8.3-6 15.5-13.5 21.3-22.1-7.6 3.4-15.9 5.6-24.5 6.7 8.8-5.2 15.5-13.6 18.8-23.6-8.2 4.9-17.4 8.4-27.1 10.3-7.8-8.3-18.9-13.5-31.1-13.5-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.6 1.1 9.7-35.4-1.7-66.8-18.8-87.8-44.5-3.7 6.3-5.7 13.6-5.7 21.4 0 14.8 7.6 27.8 18.9 35.5-7-.2-13.5-2.2-19.3-5.3v.6c0 20.6 14.7 37.9 34.1 41.8-3.6 1-7.3 1.5-11.2 1.5-2.7 0-5.4-.2-8-.7 5.4 16.9 21.2 29.2 39.8 29.6-14.6 11.5-33 18.3-52.9 18.3-3.4 0-6.8-.2-10.1-.6 18.7 11.9 41.2 19 65.2 19" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'xing'"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M123.1 135.6c-1.8 0-3.3.6-4 1.8-.8 1.3-.7 2.9.2 4.5l20 34.6v.2l-31.4 55.4c-.8 1.6-.8 3.3 0 4.5s2.1 2 3.9 2h29.5c4.4 0 6.5-3 8.1-5.7 0 0 30.7-54.3 31.9-56.4-.1-.2-20.3-35.4-20.3-35.4-1.5-2.6-3.7-5.5-8.2-5.5h-29.7zM247.2 95.2c-4.4 0-6.3 2.8-7.9 5.6 0 0-63.6 112.8-65.7 116.6.1.2 42 77 42 77 1.5 2.6 3.7 5.6 8.3 5.6h29.5c1.8 0 3.2-.7 3.9-1.9.8-1.3.8-2.9-.1-4.6l-41.6-76.1v-.2L281 101.6c.8-1.6.8-3.3.1-4.6-.8-1.2-2.1-1.9-3.9-1.9l-30 .1z"/></svg></a>
              </li>
              <li v-if="member.acf.team_member.email">
                <a target="_blank" href="" @click.prevent="mailTo(encodeEmail(member.acf.team_member.email))" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z" fill="#4b4b4d"/></svg></a>
              </li>
            </ul>
          </div>
          <div class="teamImage">
            <lazy-image :image="member.acf.team_member.image" replaceSize="medium_large"></lazy-image>
          </div>
          <figcaption :class="{active: activeMemberId === member.id}">
            <span class="name">{{ member.title.rendered }}</span>
            <span class="position">{{ member.acf.team_member.position }}</span>
            <a v-if="member.content.rendered.trim()" href="" @click.prevent="() => openBiography(member)">
              {{ $root.translate('biography') }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </a>
            <div class="bio" v-if="member.content.rendered.trim()" v-html="member.content.rendered"></div>
            <a href="#" @click.prevent="closeBiography" class="close">
              <span class="cross"></span>
            </a>
          </figcaption>
        </figure>
      </div>
    </div>
    <primary-button v-if="!showMore && moreMembers.length" @click="showMore = true">{{ $root.translate('show_more') }}</primary-button>
    <div class="teamMembers moreMembers" :class="{ show: showMore }">
      <div class="member" v-for="(member, key) in moreMembers" :key="key">
        <figure>
          <div class="socialIcons" v-if="member.acf.team_member.social_channels">
            <ul>
              <li v-for="(socialIcon, key) in member.acf.team_member.social_channels" :key="key">
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'linkedin'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M289 273.6h-35.4v-55.4c0-13.2-.2-30.2-18.4-30.2-18.4 0-21.2 14.4-21.2 29.2v56.3h-35.4V159.7h33.9v15.6h.5c6.9-11.8 19.8-18.9 33.5-18.4 35.8 0 42.4 23.6 42.4 54.2l.1 62.5zM138.7 144.2c-11.3 0-20.5-9.2-20.5-20.5s9.2-20.5 20.5-20.5 20.5 9.2 20.5 20.5c.1 11.3-9.1 20.5-20.5 20.5m17.7 129.4H121V159.7h35.4v113.9z" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'mail'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'twitter'"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M164.3 287.2c78.3 0 121.2-64.9 121.2-121.2 0-1.8 0-3.7-.1-5.5 8.3-6 15.5-13.5 21.3-22.1-7.6 3.4-15.9 5.6-24.5 6.7 8.8-5.2 15.5-13.6 18.8-23.6-8.2 4.9-17.4 8.4-27.1 10.3-7.8-8.3-18.9-13.5-31.1-13.5-23.5 0-42.6 19.1-42.6 42.6 0 3.3.4 6.6 1.1 9.7-35.4-1.7-66.8-18.8-87.8-44.5-3.7 6.3-5.7 13.6-5.7 21.4 0 14.8 7.6 27.8 18.9 35.5-7-.2-13.5-2.2-19.3-5.3v.6c0 20.6 14.7 37.9 34.1 41.8-3.6 1-7.3 1.5-11.2 1.5-2.7 0-5.4-.2-8-.7 5.4 16.9 21.2 29.2 39.8 29.6-14.6 11.5-33 18.3-52.9 18.3-3.4 0-6.8-.2-10.1-.6 18.7 11.9 41.2 19 65.2 19" fill="#4b4b4d"/></svg></a>
                <a target="_blank" :href="socialIcon.url" v-if="socialIcon.channel === 'xing'"><svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><path class="st0" d="M123.1 135.6c-1.8 0-3.3.6-4 1.8-.8 1.3-.7 2.9.2 4.5l20 34.6v.2l-31.4 55.4c-.8 1.6-.8 3.3 0 4.5s2.1 2 3.9 2h29.5c4.4 0 6.5-3 8.1-5.7 0 0 30.7-54.3 31.9-56.4-.1-.2-20.3-35.4-20.3-35.4-1.5-2.6-3.7-5.5-8.2-5.5h-29.7zM247.2 95.2c-4.4 0-6.3 2.8-7.9 5.6 0 0-63.6 112.8-65.7 116.6.1.2 42 77 42 77 1.5 2.6 3.7 5.6 8.3 5.6h29.5c1.8 0 3.2-.7 3.9-1.9.8-1.3.8-2.9-.1-4.6l-41.6-76.1v-.2L281 101.6c.8-1.6.8-3.3.1-4.6-.8-1.2-2.1-1.9-3.9-1.9l-30 .1z"/></svg></a>
              </li>
              <li v-if="member.acf.team_member.email">
                <a target="_blank" href="" @click.prevent="mailTo(encodeEmail(member.acf.team_member.email))" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><path d="M63.7 183.3l44 17.7 30 87.9 44.9-41.9 64.4 47c7.5 5.5 18.2 2.1 21.1-6.8l58.7-176.8c2.3-6.8-3.8-13.4-10.8-11.8L65.7 157.2c-13 3.1-14.4 21.1-2 26.1zm76 31.2l-6.5 35.6-18.6-51.3 173-77.2-147.9 92.9z" fill="#4b4b4d"/></svg></a>
              </li>
            </ul>
          </div>
          <div class="teamImage">
            <lazy-image :image="member.acf.team_member.image" replaceSize="medium_large"></lazy-image>
          </div>
          <figcaption :class="{active: activeMemberId === member.id}">
            <span class="name">{{ member.title.rendered }}</span>
            <span class="position">{{ member.acf.team_member.position }}</span>
            <a v-if="member.content.rendered.trim() && activeMemberId != member.id" href="" @click.prevent="() => openBiography(member)">
              {{ $root.translate('biography') }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </a>
            <div class="bio" v-if="member.content.rendered.trim()" v-html="member.content.rendered"></div>
            <a href="#" @click.prevent="closeBiography" class="close">
              <span class="cross"></span>
            </a>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PrimaryButton from './PrimaryButton';
import LazyImage from './LazyImage';
import { emailMixin } from '../../mixins/emailMixin';

export default {
  name: 'AdvisoryBoard',
  components: {
    PrimaryButton,
    LazyImage
  },
  mixins: [ emailMixin ],
  props: ['data'],
  data() {
    return {
      activeMemberId: null,
      max: 6,
      showMore: false
    }
  },
  computed: { ...mapState({
      members: state => state.team.members
    }),
    sortedMembers() {
      if(!this.members) return null;
      let filteredMembers = [];
      if(this.data.team_category) {
        filteredMembers = this.members.filter((member) => member.teamcategory && member.teamcategory.includes(this.data.team_category) )
      }
      else {
        filteredMembers = this.members.filter((member) => !member.teamcategory.length)
      }
      
      if(!filteredMembers.length) return null;

      const shuffle = (a) => {
          for (let i = a.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [a[i], a[j]] = [a[j], a[i]];
          }
          return a;
      }
      const toTop = filteredMembers.filter((member) => member.acf.team_member.to_top);
      const others = shuffle(filteredMembers.filter((member) => !member.acf.team_member.to_top));

      return [...toTop, ...others];
    },
    shortenMembers() {
      return this.sortedMembers.slice(0, this.max);
    },
    moreMembers() {
      return this.sortedMembers.slice(this.max);
    }
  },
  mounted() {
    this.$store.dispatch('team/getAll');
  },
  methods: {
    openBiography(member) {
      this.activeMemberId = member.id;
      this.$nextTick(() => {
        const bioHeight = this.$el.querySelector('figcaption.active .bio').offsetHeight;
        this.$el.style.paddingBottom = bioHeight + 'px';
      });
      if(typeof window != 'undefined') {
        if(window.closeBiography) document.removeEventListener('click', window.closeBiography);
        window.closeBiography = this.closeBiography.bind(this);
        setTimeout(() => document.addEventListener('click', window.closeBiography), 100);
      }
    },
    closeBiography() {
      this.activeMemberId = null;
      this.$el.style.paddingBottom = 0;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
#advisoryboard {
  position:absolute;
  top: -100px;
}
.advisoryBoard {
  position: relative;
  margin-bottom: 210px !important;
  z-index: 30 !important;
  button {
    margin: 0 0 50px;
  }
  @media screen and (max-width: $small) {
    margin-bottom: 80px !important;
  }
  h5 {
    margin-bottom: 0.5em;
  }
  .subline {
    font-size: 38px;
    line-height: 47px;
    margin: 0 0 50px; 
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) {
      font-size: 25px;
      line-height: 30px;
      margin: 0 0 1em;
    }
  }
  .content {
    font-size: 17px;
    line-height: 30px;
    margin: 0 0 50px; 
    @media screen and (max-width: $small) {
      font-size: 15px;
      line-height: 24px;
    }
  }
}



.teamMembers {
  display: flex;
  flex-wrap: wrap;
  &.moreMembers {
    display: none;
    &.show {
      display: flex;
    }
  }
  .member {
    width: 33.33%;
    @media screen and (max-width: $xsmall) {
      width: 100%;
    }
    figure {
      margin: 0 20px 40px 0;
      padding: 0;
      .teamImage {
        position: relative;
        background: $primary;
        overflow: hidden;
        &:after {
          content: '';
          padding-top: 75%;
          display: block;
        }
      }
      img {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      figcaption {
        margin: 20px 0;
        position: relative;
        width: 100%;
        
        .bio {
          display: none;
          font-size: 17px;
          line-height: 30px;
        }
        .close {
          position: absolute;
          display: none;
          top: 20px;
          right: 20px;
          height: 24px;
          color: $color;
          text-decoration: none;
          justify-content:center;
          align-items: center;
          z-index: 20;
          .cross {
            width: 24px;
            height: 24px;
            display: block;
            position: relative;
            margin-left: 10px;
            &:before,
            &:after {
              content: '';
              display: block;
              height: 2px;
              width: 100%;
              background: $color;
              transform: translate(-50%, -50%) rotate(45deg);
              position: absolute;
              top: 50%;
              left: 50%;
            }
            &:before {
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
        .name {
          font-size: 27px;
          display: block;
        }
        .position {
          font-size: 17px;
        }
        a {
          position: absolute;
          bottom: 0;
          right: 0;
          text-decoration: none;
          font-size: 15px;
          svg {
            width: 16px;
            height: 16px;
            transform: rotate(-45deg);
            > * {
              fill: $primary;
            }
          }
        }

        &.active {
          background: $primary;
          padding: 20px;
          margin: 0px -20px 0 -20px;
          .bio {
            display: block;
          }
          .close {
            display: block;
          }
        }
      }
    }
  }
}
</style>