<template>
  <div class="stage" v-if="loaded && data && data.header_image" :class="{stageVideo: data.add_youtube_video}" @click="showVideo = true" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <div class="label" v-if="data.add_youtube_video">
        View Video
      </div>
    <div class="stage__background" :style="{ backgroundImage: backgroundImage }" >
      <div class="wrapper" v-if="!data.show_nunatak_explanation">
        <div class="stage__badge" @click.prevent="scrollToJobs" v-if="data.badge" :class="{ hide: uiHide }">
          <img :src="data.badge.url" />
        </div>
        <h4 v-if="post.type === 'post'">Nunatak News</h4>
        <img v-if="data.logo" :src="data.logo.url" :alt="data.logo.alt">
        <h1 v-html="data.overwrite_title ? data.title : post.title.rendered"></h1>
      </div>
      <div class="wrapper" v-if="data.show_nunatak_explanation">
        <span class="claim">{{ $root.translate('nunatak') }}</span>
        <p>{{data.nunatak_explanation}}</p>
      </div>
    </div>
    <div class="back wrapper" v-if="post.type === 'post'">
      <router-link :to="language == 'en' ? '/en/news/' : '/news/'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
        <span>{{ $root.translate('news_back') }}</span>
      </router-link>
    </div>
    
    <video-layer v-if="data.add_youtube_video" :youtubeId="data.youtube_id" :show="showVideo" @close="showVideo = false"></video-layer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getCoords } from '../../helpers/NavigationService';
import VideoLayer from './VideoLayer';

export default {
  name: 'Stage',
  props: ['data', 'post'],
  components: {
    VideoLayer
  },
  data() {
    return {
      screenWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
      showVideo: false,
      loaded: false
    }
  },
  mounted() {
    this.loaded = true;
    window.addEventListener('resize', () => this.screenWidth = typeof window !== 'undefined' ? window.innerWidth : 0);
  },
  computed: {
    ...mapState({
      language: state => state.language.language
    }),
    backgroundImage() {
      if(!this.data) {
        return null;
      }
      if (this.screenWidth > 768) {
        return 'url(' + this.data.header_image.url + ')';
      }
      else if (this.data.use_mobile_image) {
        return 'url(' + this.data.mobile_image.url + ')';
      }
      return 'url(' + this.data.header_image.url + ')';
    }
  },
  methods: {
    scrollToJobs() {
      if(document.querySelector('#jobs')) {
        const coords = getCoords(document.querySelector('#jobs'));
          const delta = 120;
          window.scrollTo(0, coords.top - delta);
      }
    },
    mouseenter() {
      if(this.data.add_youtube_video) {
        this.$store.commit('mouseHovering', { color: this.theme ? this.theme : 'whiteFocus', label: 'view video' })
      }
    },
    mouseleave() {
      if(this.data.add_youtube_video) {
        this.$store.commit('mouseHovering', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/config.scss';
.stage {
  margin: 0 auto 100px;
  position: relative;
  &.stageVideo {
    cursor: pointer;
  }
  @media screen and (max-width: $large) {
    margin: 0 auto 70px;
  }

  .stage__badge {
    position: absolute; 
    width: 150px;
    top: -200px;
    left: 100px;
    cursor: pointer;
    @media screen and (max-width: $large) {
      top: -100px;
      left: auto;
      right: 20px;
    }

    @media screen and (max-width: $small) {
      top: 100px;
      left: auto;
      right: 20px;
      width: 100px;
    }
    img {
      display: block;
      width: 100%;
      height: auto !important;
    }
  }
  .label {
      display: none;
      
      @media screen and (max-width: $medium) {
        display: block;
        position: absolute;
        top: 20%;
        left: 50%;
        color: white;
        transform: translate(-50vw, -50%);
        margin-left: 90px;
        font-size: 12px;
        letter-spacing: 0.135em;
        text-transform: uppercase;
        z-index: 12;
        &:before {
          content: '';
          width: 77px;
          height: 77px;
          border-radius: 50%;
          background: white;
          display: block;
          position: absolute;
          top: 50%;
          right: 120%;
          transform: translate(50%,-50%);
          opacity: 0.3;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 120%;
          transform: translate(50%,-50%);
          opacity: 1;
          height: 0;
          width: 0;

          border-left: 15px solid white;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }
      }

      @media screen and (max-width: $small) {
        margin-left: 70px;
        top: 15%;
      }
    }
  .back {
    margin-top: 28px;
    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
      left: -2vw;
    }
    a {
      color: $color;
      text-decoration: none;
      transition: color 500ms ease;
      svg {
        transition: transform 500ms ease;
          * {
            transition: fill 500ms ease;
          }
        }
      &:hover {
        color: $primary;
        svg {
          transform: translate(-5px) rotate(180deg);
          * {
            fill: $primary;
          }
        }
      }
    }
    @media screen and (max-width: $large) {
      svg {
        left: 20px;
      }
      padding: 0 0 0 60px;
    }
  }
  .stage__background {
    //width: calc(50vw + 590px);
    margin: 0 auto;
    max-width: 1920px;
    max-height: 822px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
    background-position: 80px center;
    background-size: auto 100%;
    z-index: 11;
    transition: background-position 500ms ease;
    @media screen and (max-width: $large) {
      background-position: top center;
      background-size: cover;
    }
    &:after {
      content: '';
      display: block;
      padding-top: 42.8%;
      @media screen and (max-width: $small) {
        padding-top: 108.6%;
      }
      background: rgba(0, 0, 0, 0.25);
      margin-left: 80px;
      @media screen and (max-width: $large) {
        margin-left: 0;
      }
    }
    > * {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: calc(100% - 40px);
      margin-top: 16.2%; 
      @media screen and (max-width: $medium) {
        padding-right: 40%;
        box-sizing: border-box;
      }
      @media screen and (max-width: $small) {
        margin-top: 50%;
        padding-right: 20%;
        padding-left: 0;
        transform: translate(-50%, -50%);
        width: calc(100% - 40px);
      }
    }
    h4 {
      position: absolute;
      bottom: 100%;
      color: white;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 0.135em;
      font-size: 18px;
    }
    img {
      height: 60px;
      display: block;
      margin-bottom: 50px;
      @media screen and (max-width: $small) {
        margin-top: 75px;
      }
    }
    h1 {
      color: white;
      margin: 0;
      max-width: 787px;
      font-size: 38px;
      line-height: 47px;
      font-weight: normal;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
    }
    span.claim {
      font-size: 38px;
      color: white;
      font-weight: 300;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $medium) { 
        font-size: 25px;
      }
    }
    p {
      max-width: 440px;
      font-size: 17px;
      line-height: 25px;
      color: white;
    }
    @media screen and (max-width: $small) {
      h4 {
        font-size: 11px;
      }
      h1 {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
}
</style>