<template>
  <div class="careerGallery wrapper" v-show="data.gallery">
    <h5>{{ data.title }}</h5>
    <h4 v-if="data.subline">{{ data.subline }}</h4>
    <primary-button v-if="data.link" :to="data.link.url">{{ data.link.title }}</primary-button>
    <br>
    <primary-button v-if="data.instagram_button" :to="data.instagram_button.url" :hasSvg="true">
      {{ data.instagram_button.title }}
      <svg version="1.1" id="White" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 400 400" xml:space="preserve"><g><path class="st1" d="M200 117.9c26.8 0 29.9.1 40.5.6 9.8.4 15.1 2.1 18.6 3.5 4.7 1.8 8 4 11.5 7.5s5.7 6.8 7.5 11.5c1.4 3.5 3 8.8 3.5 18.6.5 10.6.6 13.7.6 40.5s-.1 29.9-.6 40.5c-.4 9.8-2.1 15.1-3.5 18.6-1.8 4.7-4 8-7.5 11.5s-6.8 5.7-11.5 7.5c-3.5 1.4-8.8 3-18.6 3.5-10.6.5-13.7.6-40.5.6s-29.9-.1-40.5-.6c-9.8-.4-15.1-2.1-18.6-3.5-4.7-1.8-8-4-11.5-7.5s-5.7-6.8-7.5-11.5c-1.4-3.5-3-8.8-3.5-18.6-.5-10.6-.6-13.7-.6-40.5s.1-29.9.6-40.5c.4-9.8 2.1-15.1 3.5-18.6 1.8-4.7 4-8 7.5-11.5s6.8-5.7 11.5-7.5c3.5-1.4 8.8-3 18.6-3.5 10.6-.5 13.7-.6 40.5-.6m0-18.1c-27.2 0-30.6.1-41.3.6-10.7.5-17.9 2.2-24.3 4.7-6.6 2.6-12.2 6-17.7 11.6-5.6 5.6-9 11.2-11.6 17.7-2.5 6.4-4.2 13.7-4.7 24.3-.5 10.7-.6 14.1-.6 41.3 0 27.2.1 30.6.6 41.3.5 10.7 2.2 17.9 4.7 24.3 2.6 6.6 6 12.2 11.6 17.7 5.6 5.6 11.2 9 17.7 11.6 6.4 2.5 13.7 4.2 24.3 4.7 10.7.5 14.1.6 41.3.6s30.6-.1 41.3-.6c10.7-.5 17.9-2.2 24.3-4.7 6.6-2.6 12.2-6 17.7-11.6s9-11.2 11.6-17.7c2.5-6.4 4.2-13.7 4.7-24.3.5-10.7.6-14.1.6-41.3s-.1-30.6-.6-41.3c-.5-10.7-2.2-17.9-4.7-24.3-2.6-6.6-6-12.2-11.6-17.7-5.6-5.6-11.2-9-17.7-11.6-6.4-2.5-13.7-4.2-24.3-4.7-10.7-.5-14.1-.6-41.3-.6z"/><path class="st1" d="M200 148.5c-28.4 0-51.5 23-51.5 51.5s23 51.5 51.5 51.5 51.5-23 51.5-51.5-23.1-51.5-51.5-51.5zm0 84.9c-18.4 0-33.4-15-33.4-33.4s15-33.4 33.4-33.4 33.4 15 33.4 33.4-15 33.4-33.4 33.4z"/><circle class="st1" cx="253.5" cy="146.5" r="12"/></g></svg>
    </primary-button>
    <div class="badge" v-if="data.show_career_badge">
      <router-link :to="language === 'en' ? '/en/career/' : 'karriere'">
        <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1010.6 1010.6" xml:space="preserve"><circle cx="498.7" cy="496.3" r="443.9" fill="#a0d0cb"/><path class="st1" d="M201.6 317.8h17.2L229 431.2l54.8-105.8h15l13.6 105.8 54.2-113.4h19.2L318 457.2h-17.4L287 352.8l-54.4 104.4h-17.2l-13.8-139.4zM411.8 317.8h91.6l-3.2 16h-74.4l-8.6 44.2h67.6l-3 16h-67.6l-9.2 47.2h74.4l-3.2 16h-91.6l27.2-139.4zM534.4 317.8h14.2l-9.2 46.4h-14.2l9.2-46.4zM629.2 394.6l25.6 62.6h-18.6l-23-60.6h-27.4l-11.6 60.6H557l27.2-139.4H625c31.4 0 44 16.6 39 41.6-3.8 19.8-15.8 30.8-34.8 35.2zm-8.2-60.8h-22.8l-9.2 46.8h22.2c19.8 0 32.6-6.2 36-23.4 3-16-6.6-23.4-26.2-23.4zM704.2 317.8h91.6l-3.2 16h-74.4l-8.6 44.2h67.6l-3 16h-67.6l-9.2 47.2h74.4l-3.2 16H677l27.2-139.4z"/><g><path class="st2" d="M194.7 508.8h25l-11 56.2h61l11-56.2h25l-27.2 139.4h-25l11.8-60.2h-61l-11.8 60.2h-25l27.2-139.4zM340.9 508.8h25l-27.2 139.4h-25l27.2-139.4zM448.9 588l25.6 60.2h-26.6l-22.2-57.8h-19.8l-11.2 57.8h-24.8l27.2-139.4h45c33.2 0 44.8 18.2 39.8 44-3.8 19.6-15.2 30.4-33 35.2zm-12.8-56.2h-18.8l-6.8 35.6h17.6c16.2 0 26.6-3.4 29.4-17.8 2.4-12.8-5.2-17.8-21.4-17.8zM521.5 508.8h25l-27.2 139.4h-25l27.2-139.4zM687.1 508.8l-27.2 139.4h-25.8l-40-95.2-18.6 95.2h-25l27.2-139.4h25.6l40.4 94.8 18.4-94.8h25zM770.5 572.8h56l-14.6 75.4h-19.8l1.8-18.8c-10.2 13.2-26.4 20.8-44.4 20.8-39.2 0-56.2-24.4-56.2-55.2 0-48.8 33.6-88.4 81.8-88.4 33 0 50.2 17.4 54.8 40.6l-24 7.4c-2.8-14.8-13-24.2-33.2-24.2-33.8 0-54.6 29-54.6 63 0 19.6 9.8 33 33.8 33 22 0 40-11.4 45.4-33.4h-30.6l3.8-20.2z"/></g><g></g></svg>
      </router-link>
    </div>
    <div class="careerGalleryItems">
      <div class="item hide" :class="{video: item.caption}" v-for="(item, i) in data.gallery" :key="i">
        <lazy-image v-if="!item.caption" :class="{show: true}" :image="item" @largeLoad="() => loadImage(i)"></lazy-image>
        <video-teaser v-if="item.caption" label="View Video" :src="item" :youtubeId="item.caption" ></video-teaser>
      </div>
    </div>
  </div>
</template>

<script>
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
import PrimaryButton from './PrimaryButton';
import VideoTeaser from './VideoTeaser';
import { mapState } from 'vuex';
import LazyImage from './LazyImage';
let careerInterval;
export default {
  name: 'CareerGallery',
  props: ['data'],
  data() {
    return {
      counter: 0,
      slots: [],
      firstImage: false,
    }
  },
  computed: mapState({
    language: state => state.language.language
  }),
  components: {
    PrimaryButton,
    VideoTeaser,
    LazyImage,
  },
  mounted() {
    const scene = new ScrollMagic.Scene({
      triggerElement: this.$el,
      offset: 0
    })
    .addTo(window.ScrollMagicController); 
    this.firstImage = false;
    scene.on('start', () => {
      this.init();
    })
  },
  beforeUnmount() {
    if(careerInterval) clearInterval(careerInterval);
  },
  methods: {
    init() {
      if(careerInterval) clearInterval(careerInterval);
      const loadHandler = () => {
        const hideAnItem = () => {
          const key = Math.floor(Math.random() * this.slots.length);
          let hideItem = null;
          if(this.slots[key]) {
            hideItem = this.slots[key][0]
            
            hideItem.style.opacity = 0;
            this.slots = this.slots.filter((slot, k) => k !== key); 
          }
          return hideItem;
        }
        const hideItem1 = hideAnItem();
        const hideItem2 = hideAnItem();

        const items = [].slice.call(this.$el.querySelectorAll('.item'), 0);
        const itemsHide = [].slice.call(this.$el.querySelectorAll('.item.hide'), 0);
        itemsHide.forEach((itemHide) => this.loadImage(items.indexOf(itemHide)))
        if(hideItem1) hideItem1.classList.add('hide');
        if(hideItem2) hideItem2.classList.add('hide');
      };
      careerInterval = setInterval(() => {
        loadHandler();
      }, 4000);
      loadHandler();
    },

    loadImage(index) {
        const items = [].slice.call(this.$el.querySelectorAll('.item'), 0);
        const item = items[index];
        const width = this.$el.querySelector('.careerGalleryItems').offsetWidth;
        const height = this.$el.querySelector('.careerGalleryItems').offsetHeight;
        const imageWidth = item.querySelector('img').offsetWidth,
              imageHeight = item.querySelector('img').offsetHeight;

        if(!imageWidth && !imageHeight) return;
        
        const deltaHeight = Math.max(0, height - imageHeight),
              deltaWidth = Math.max(0, width - imageWidth);

        let tries = 0;

        const position = () => {
          const top = Math.round(Math.random() * deltaHeight),
                left = Math.round(Math.random() * deltaWidth);

          // eslint-disable-next-line
          const freeSlot = this.slots.filter(([it, t, l, w, h]) => {
            const firstCheck = top + imageHeight > t && left + imageWidth > l;
            const secondCheck = top < t + h && left < l + w;
            return firstCheck && secondCheck;
          } )

          if(tries >= 15) {
            return [null, null];
          }

          if(freeSlot.length) {
            tries += 1;
            return position();
          }

          return [top, left];
        }

        const [top, left] = position();
        if(top === null) {
          item.style.opacity = 0;
          item.classList.add('hide');
          this.slots = this.slots.filter((slot) => slot[0] !== item); 
          return;
        }

        item.style.top = top + 'px';
        item.style.left = left + 'px';
        item.style.opacity = 1;
        item.classList.remove('hide');

        const toleranceWidth = 0.2 * imageWidth,
              toleranceHeight = 0.2 * imageHeight;

        this.slots.push([item, top + toleranceHeight, left + toleranceWidth, imageWidth - toleranceWidth, imageHeight - toleranceHeight])
    },
    setX() {
      return (Math.random() * 150 - 50) + '%';
    },
    setY(index) {
      return ((Math.sin(index+1)+1) * 25) + '%';
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.careerGallery {
  margin-bottom: 130px !important;
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 0.5em;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) {
      font-size: 25px;
    }
  }
  @media screen and (max-width: $small) {
    button {
      display: block;
      width: 100%;
      margin: 30px 0;
      span {
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
  .badge {
      position: absolute;
      right: 20px;
      top: 0px;
      @media screen and (max-width: $small) {
        top: auto;
        bottom: 0;
        z-index: 10;
      }
      &:after {
        display: block;
        width: 30px;
        height: 4px;
        background: $color;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%);
        content: '';
        transition: width 500ms ease;
        @media screen and (max-width: $small) {
          bottom: 30px;
          height: 3px;
        }
      }
      &:hover {
        &:after {
          width: 50px;
        }
      }
      svg {
        width: 180px;
        height: 180px;
        @media screen and (max-width: $small) {
          width: 110px;
          height: 110px;
        }
        circle {
          opacity: 0.9;
        }
        .st1 {
          fill: white;
        }
        .st2 {
          fill: $color;
        }
      }
    }
  .careerGalleryItems {
    position: relative;
    min-height: 700px;
    margin-bottom: 200px;
    width: 100vw;
    left: 50%;
    transform: translate(-50%);
    perspective: 1000px;


    @media screen and (max-width: $small) {
      min-height: 60vh;
      max-height: 60vh;
      margin-bottom: 0;
    }
    
    .item {
      position: absolute;
      max-width: 350px;
      height: 33%;
      opacity: 0;
      transition: opacity 500ms ease, transform 500ms ease;
      //overflow: hidden;
      &.video {
        .videoTeaser {
          position: relative;
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($color, 0.7);
            z-index: 0;
            content: '';
            display: block;
          }
        }
        
      }
      &:hover {
        transform: scale(1.05);
      }
      &.hide {
        pointer-events: none;
        transform: scale(0.8);
      }
      
      img {
        opacity: 1;
        width: 100%;
      }
      @media screen and (max-width: $small) {
        max-width: 40%;
        img {
          max-width: 100%;
        }
      }
    }
  }
  
}
</style>