
<template>
  <div class="cookie" v-if="!hasCookie || forceShow">
    <div class="cookie-overlay" :class="{'cookie-overlay--visible': $route.path.indexOf('impressum') !== -1 ||  $route.path.indexOf('imprint') !== -1 ||  $route.path.indexOf('privacy') !== -1 ||  $route.path.indexOf('datenschutz') !== -1}">
      <div>
        <h3>{{ $root.translate('cookie_title') }}</h3>
        <p>{{ $root.translate('cookie_intro') }}</p>
        <div class="cookie-details" :class="{active: showDetails}">
            <table v-for="(cookie, key) in cookieConfig" :key="key" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr>
                        <th>{{ $root.translate('cookie_accept') }}</th>
                        <td>
                            <div v-if="cookie.category" class="cookie_switch">
                                <input :id="'cookie-switch-' + key" type="checkbox" value="1" @change="check($event, cookie.id)">
                                <label :for="'cookie-switch-' + key"></label>
                            </div>
                            <div v-if="!cookie.category">
                              {{ $root.translate('cookie_essential') }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate('cookie_name') }}</th>
                        <td>{{ cookie.name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate('cookie_provider') }}</th>
                        <td>{{ cookie.provider }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate('cookie_purpose') }}</th>
                        <td>{{ cookie.purpose }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate('cookie_privacy_policy') }}</th>
                        <td>
                          <a v-if="!cookie.privacy_policy" :href="$root.language == 'en' ? '/en/meta/privacy-policy' : '/meta/datenschutz'" target="_blank">{{ $root.translate('cookie_privacy_policy') }}</a>
                          <a v-if="cookie.privacy_policy" :href="cookie.privacy_policy" target="_blank">{{ $root.translate('cookie_privacy_policy') }}</a>
                        </td>
                    </tr>
                    
                    <tr v-if="cookie.hosts">
                        <th>{{ $root.translate('cookie_host') }}</th>
                        <td>{{ cookie.hosts.join() }}</td>
                    </tr>
 
                    <tr>
                        <th>{{ $root.translate('cookie_cookie_name') }}</th>
                        <td>{{ cookie.cookie_name }}</td>
                    </tr>
                    <tr>
                        <th>{{ $root.translate('cookie_cookie_expiry') }}</th>
                        <td>{{ cookie.cookie_expiry }}</td>
                    </tr>
                </tbody>
            </table>
            <a class="arrowLink" @click="accept">{{ $root.translate('cookie_save') }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg></a>
        </div>
        <div class="cookie-button-group">
            <a class="arrowLink" @click="acceptAll">{{ $root.translate('cookie_accept_all') }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg></a>
            <a class="arrowLink grey" @click="toggleDetails">{{ $root.translate('cookie_show_details') }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg></a>
            
        </div>
        <nav class="meta">
          <ul>
            <li v-for="(item, key) in menus.meta" :key="key"><router-link :to="(($route.path.indexOf('/en') === 0) ? '/en' : '') + '/meta/' + item.url.split('/')[item.url.split('/').length -2]">{{ item.title }}</router-link></li>
          </ul>
        </nav>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
/* eslint-disable no-console */
export default {
  name: 'Cookie',
  props: [
    'forceShow'
  ],
  components: {
  },
  computed: mapState({
    menus: state => state.menus.menu,
    language: state => state.language.language
  }),
  data() {
    return {
      hasCookie: true,
      acceptedCookies: [],
      showDetails: false,
      cookieConfig: [
        {
          "id": "cookie_acceptance",
          "category": 0,
          "name": "Cookie Acceptance",
          "provider": "Nunatak",
          "cookie_name": "cookie_acceptance",
          "purpose": "Cookie is used for storing your answer on this banner. So that we won't ask you again.",
          "privacy_policy": false,
          "cookie_expiry": "6 Months"
        },
        {
          "id": "google_analytics",
          "category": 1,
          "name": "Google Analytics",
          "provider": "Google LLC",
          "cookie_name": "_ga, _gat, _gid",
          "purpose": "Cookie by Google used for website analytics. Generates statistical data on how the visitor uses the website.",
          "privacy_policy": "https://policies.google.com/privacy?hl=en",
          "cookie_expiry": "2 Years",
        },
        {
          "id": "youtube",
          "category": 2,
          "name": "YouTube",
          "provider": "YouTube",
          "cookie_name": "NID",
          "purpose": "Used to unblock YouTube content",
          "hosts": ["youtube.com", "google.com"],
          "privacy_policy": "https://policies.google.com/privacy?hl=en",
          "cookie_expiry": "6 Month"
        }
      ]
    }
  },
  mounted() {
    this.hasCookie = false;
    if(this.getCookie('cookie_acceptance')) {
      this.hasCookie = true;
    }
    window.addEventListener('cookie.open', () => {
      this.hasCookie = false;
    })

    this.acceptedCookies = this.getCookie('cookie_acceptance').split(',');
    if(this.acceptedCookies.indexOf('cookie_acceptance') == -1) {
      this.acceptedCookies.push('cookie_acceptance');
    }
    this.$root.acceptedCookies = this.getCookie('cookie_acceptance').split(',');

    this.loadScripts();
  },
  methods: {
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return localStorage.getItem(cname) || '';
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    check($event, id) {
      if(!this.acceptedCookies.includes(id)) {
        this.acceptedCookies.push(id);
      }
      else {
        this.acceptedCookies = this.acceptedCookies.filter(cookie => cookie.id != id) 
      }
    },
    acceptAll() {
      this.acceptedCookies = [];
      this.cookieConfig.forEach((cookie) => {
        this.acceptedCookies.push(cookie.id)
      });
      this.accept();
    },
    loadScripts() {
      this.acceptedCookies.forEach((cookieId) => {
        switch(cookieId) {
          case 'youtube':
            // eslint-disable-next-line no-case-declarations
            const scriptYT = document.createElement('script');
            scriptYT.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(scriptYT);
            break;
          case 'google_analytics': 
            if(typeof window !== 'undefined' && window.location.hostname.indexOf('nunatak.com') == -1) return;
            // eslint-disable-next-line no-case-declarations
            const scriptGA = document.createElement('script');
            scriptGA.src = 'https://www.googletagmanager.com/gtag/js?id=UA-50642053-3';
            document.body.appendChild(scriptGA);
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){ window.dataLayer.push(arguments); }
            window.gtag('js', new Date());
            window.gtag('config', 'UA-50642053-3');
            break;
        }
      });
    },
    accept() {
      this.acceptedCookies = this.acceptedCookies.filter(cookie => !!cookie);
      const now = new Date;
      const expires = new Date(now.setMonth(now.getMonth() + 6));
      document.cookie = 'cookie_acceptance=' + this.acceptedCookies.join() + "; expires=" + expires.toUTCString();
      localStorage.setItem('cookie_acceptance', this.acceptedCookies.join() + "; expires=" + expires.toUTCString());

      this.loadScripts();
      
      setTimeout(() => location.reload(), 200);
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/config';
  .cookie-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 500;
      transition: opacity 500ms ease, transform 500ms ease;
      &--visible {
        background: transparent;
        pointer-events: none;
        > div {
          pointer-events: initial;
        }
      }
      &--hide {
          opacity: 0;
          transform: translate(0, -100%);
      }
      a {
          color: darken($primary, 10%);
          cursor: pointer;
          font-size: 100%;
          text-decoration: underline;
          border-bottom: 0 !important;
      }
      > div {
          background: white;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          box-sizing: border-box;
          padding: 30px 30px;
          max-height: 80vh;
          overflow-y: scroll;

          p {
            font-size: 17px;
            line-height: 30px;
            display: inline;
            @media screen and (max-width: $small) { 
              font-size: 15px;
              line-height: 24px;
            }
          }
          

          h3 {
            font-size: 24px; 
            line-height: 30px;
            font-weight: 300;
            margin: 0 0 0.5em;
            @media screen and (max-width: $small) {
              font-size: 25px;
              line-height: 30px;
            }
          }

          .toggle-cookie-details {
              color: darken($primary, 10%);
              cursor: pointer;
              display: inline-block;
              font-size: 17px;
              line-height: 30px;
              @media screen and (max-width: $small) { 
                font-size: 15px;
                line-height: 24px;
              }
          }
          #toggle-cookie-details-checkbox {
              position: absolute;
              left: -9999em;
          }

          .cookie-details {
            display: none;
            &.active {
              display: block;
            }
          }

          .cookie_switch {
              label {
                  display: inline-block;
                  position: relative;
                  height: 22px;
                  width: 44px;
                  border-radius: 11px;
                  background: lightgrey;
                  padding: 0 !important;
                  margin: 0;
                  transition: background-color 300ms ease;
                  cursor: pointer;
                  &:after {
                      content: '';
                      position: absolute;
                      top: 50%;
                      transform: translate(0,-50%);
                      left: 2px;
                      width: 18px;
                      height: 18px;
                      background: white;
                      display: block;
                      border-radius: 50%;
                      transition: left 300ms ease;
                  }
              }
              input {
                  position: absolute;
                  left: -9999em;
                  visibility: hidden;
                  &:checked + label {
                      background: $primary;
                      &:after {
                          left: 24px;
                      }
                  }
              }
              
          }

          table {
              margin: 2em 0 2em 0;
              width: 100%;
              td, th {
                  text-align: left;
                  border-bottom: 1px solid lightgrey;
                  font-size: 12px;
                  padding: 5px 10px;
              }
              th {
                  width: 90px;
              }

          }

          .cookie-button-group {
              display: flex;
              align-items: flex-start;

          }

          .meta {
            position: fixed;
            right: 20px;
            bottom: 30px;
            
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                display: inline-block;
                margin: 0 10px;
                a {
                  text-decoration: none;
                }
              }
            }
            @media screen and (max-width: $small) { 
              position: static;
              ul {
                margin: 20px -10px;
              }
            }
          }

          .arrowLink {
            margin: 20px 20px 0 0;
            text-decoration: none;
            &.grey {
              color: #ccc;
              svg {
                * {
                  fill: #ccc;
                }
              }
            }
          }
      }
  }
</style>