<template>
  <div class="ourValues" v-if="loaded" id="ourValues">
    <div class="mountain" id="ourValuesMountain"></div>
    <div class="ourValuesContainer wrapper">
      <h5>{{ data.title }}</h5>
      <div class="prefix">
        {{ data.prephrase }}
      </div>
      <div class="values">
        <div class="value" ref="values" v-for="(value, key) in data.value" :key="key" :style="{transform: `translate(-${slideWidth}px)`}">
          <h3>{{ value.title }}</h3>
          <div class="wrapper">
            <div class="content" :class="{ active: key == currentIndex}">
              {{ value.content }}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
let mountain = null;
let scene = null;
export default {
  name: 'OurValues',
  props: ['data'],
  data() {
    return {
      currentIndex: 0,
      slideWidth: 0,
      loaded: false
    }
  },
  computed: {
    amount() {
      return this.data.value ? this.data.value.length : 0;
    }
  },
  watch: {
    $route() {
      this.loaded = false;
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.currentIndex = 0;
      this.slideWidth = 0;
      this.$nextTick(() => {
        setTimeout(() => this.loaded = true, 500);
        setTimeout(() => this.addScrollMagic(), 1000);
        setTimeout(() => this.addMountains(), 1500);
      });
    },
    addMountains() {
      if (!document.querySelector('#ourValuesMountain')) {
        return;
      }
      mountain = new window._MOUNTAINS({
        element: '#ourValuesMountain',
        seed: Math.round(Math.random() * 65500),
        disableUI: true,
        groundLevel: 15,
        screenshot: false,
        lineColor: 0xcccccc,
        camera: {
            auto: false,
            motion: false,
            position: {
                x: 60,
                y: 30,
                z: 0
            },
            lookAt: {
                x: 0,
                y: 20,
                z: 0
            }
        }
      });

      mountain.enableCameraAuto(false);

      mountain.run();
      
    },
    addScrollMagic() {
      if(!ScrollMagic) return;
      scene = new ScrollMagic.Scene({
        triggerElement: this.$el,
        offset: window.innerHeight/2 - this.$el.offsetHeight/4,
        duration: 600 * this.amount
      })
      .setPin("#ourValues")
      .addTo(window.ScrollMagicController); 

      let triggerMarker = 0;
      scene.on('progress', (e) => {
        if(e.scrollDirection === 'FORWARD' &&  e.progress > (triggerMarker + 1/(this.amount))) {
          this.next();
          triggerMarker += 1/this.amount;
        }

        if(e.scrollDirection === 'REVERSE' &&  e.progress < triggerMarker) {
          this.prev();
          triggerMarker -= 1/this.amount;
        }
      })

      scene.on('leave', () => {
        //mountain.pause();
      })
      scene.on('enter', () => {
        //mountain.play();
      })
    },
    next() {
      this.slideWidth += this.$refs.values[this.currentIndex].offsetWidth; 
      this.currentIndex += 1;
      if(mountain) {
        mountain.cameraMoveTo({
          x: 60,
          y: 30,
          z: this.currentIndex * -10
        })
      }
    },
    prev() {
      this.currentIndex -= 1;
      this.slideWidth -= this.$refs.values[this.currentIndex].offsetWidth; 
      if(mountain) {
        mountain.cameraMoveTo({
          x: 60,
          y: 30,
          z: this.currentIndex * 10
        })
      }
    }
  },
  beforeUnmount() {
    if(mountain) {
      mountain.destroy();
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.ourValues {
  margin-top: 130px;
  margin-bottom: 130px;
  position: relative;
  h5 {
    margin: 0 0 0.5em;
  }
  .mountain {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $color;
      canvas {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        right: 0;
      }
    }
  .ourValuesContainer {
    .prefix {
      margin: 1em 0;
      font-size: 17px;
      @media screen and (max-width: $small) {
        font-size: 15px;
      }
    }
    .values {
      display: flex;
      padding-bottom: 120px;
      .value {
        transform: translate(0);
        transition: transform 1000ms ease;
        min-height: 500px;
        @media screen and (max-width: $small) {
          min-height: 400px;
        }
        h3 {
          font-size: 135px;
          line-height: 150px;
          margin: 0 60px 50px 0;
          font-weight: 400;
          transform: translate(-9px);
          .academy & {
            color: $academy;
          }
          @media screen and (max-width: $medium) {
            font-size: 100px;
            line-height: 110px;
            transform: translate(-5px);
          }
          @media screen and (max-width: $small) {
            font-size: 70px;
            line-height: 80px;
          }
          @media screen and (max-width: $xsmall) {
            transform: translate(0);
            font-size: 33px;
            line-height: 40px;
          }
        }
        .wrapper {
          padding: 0;
          width: 1180px;
          position: absolute;
          @media screen and (max-width: $medium) {
            width: 100%;
          }
        }
        .content {
          width: 33.33%;
          font-size: 17px;
          line-height: 30px;
          opacity: 0;
          transition: opacity 500ms ease;
          @media screen and (max-width: $medium) {
            width: 66.66%;
          }
          @media screen and (max-width: $small) {
            width: 100%;
            font-size: 15px;
            line-height: 24px;
          }
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>