<template>
  <div class="wrapper" id="offers">
    <h5>{{ data.title }}</h5>
    <div class="offerBoxes">
      <div class="box" v-for="(item, key) in data.offer" :key="key">
        <figure>
          <lazy-image :image="item.image"></lazy-image>
          <figcaption>
            <span class="number">0{{ key +1 }}</span>
            <h3>{{ item.title }}</h3>
            <div class="content" v-html="item.content"></div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './LazyImage';
export default {
  name: 'OfferBoxes',
  props: ['data'],
  components: {
    LazyImage
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.offerBoxes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    margin-bottom: 130px;
    display: block;
  }
  .box {
    width: 50%;
    padding: 0 20px 20px 0;
    box-sizing: border-box;
    @media screen and (max-width: $small) {
      width: 100%;
      padding: 0 0 20px 0;
    }
    figure {
      margin: 0;
      padding: 0;
      background: $color;
      display: block;
      position: relative;
      overflow: hidden;
      > * {
        position: absolute;
      }
      &:after {
        content: '';
        display: block;
        padding-top: 73%;
        transition: padding-top 500ms ease;
        @media screen and (max-width: $medium) {
          padding-top: 100%;
        }
      }
      img {
        left: 50%;
        transform: translate(-50%);
        height: 100%;
        display: block;
        opacity: 0.7;
        transition: opacity 500ms ease;
      }
      figcaption {
        color: white;
        padding: 30px 30px 60px;
        bottom: 0;
        left: 0;
        right: 0;
        .number {
          font-size: 17px;
          margin: 0 0 20px;
          display: block;
          @media screen and (max-width: $small) {
            font-size: 11px;
            margin: 0 0 10px;
          }
        }
        h3 {
          font-size: 38px;
          line-height: 47px;
          font-weight: 300;
          margin: 0 0 30px;
          @media screen and (max-width: $medium) { 
            font-size: 30px;
            line-height: 38px;
          }
          @media screen and (max-width: $medium) {
            font-size: 25px;
            line-height: 30px;
          }
        }
        ul {
          margin: 0;
          box-sizing: border-box;
          padding: 0 0 0px 1.2em;
          font-size: 17px;
          line-height: 30px;
          font-weight: 300;
          max-height: 0;
          overflow: hidden;
          transition: padding 500ms ease, max-height 500ms ease;
          @media screen and (max-width: $medium) {
            font-size: 15px;
            line-height: 24px;
          }
        }
        svg {
          position: absolute;
          bottom: 30px;
          width: 16px;
          height: 16px;
          transform: rotate(-45deg);
          transition: transform 500ms ease;
          * {
            fill: white;
          }
        }
      }
    }

    &:hover,
    &.active {
      figure {
        @media screen and (max-width: $small) {
         &:after {
           padding-top: 110%;
         }
        }
        img {
          opacity: 0.25;
        }
        figcaption {
          ul {
            padding-bottom: 20px;
            max-height: 300px;
          }
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
</style>