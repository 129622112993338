<template>
    <div class="videoTeaser" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="showVideo = true">
      <lazy-image :image="src" ></lazy-image>
      <video-layer :youtubeId="youtubeId" :show="showVideo" @close="showVideo = false"></video-layer>
      <div class="label">
        View Video
      </div>
    </div>
</template>

<script>
import VideoLayer from './VideoLayer';
import LazyImage from './LazyImage';
export default {
  name: 'VideoTeaser',
  props: ['label', 'src', 'theme', 'youtubeId'],
  components: {
    VideoLayer,
    LazyImage
  },
  data() {
    return {
      showVideo: false
    }
  },
  methods: {
    mouseenter() {
      this.$store.commit('mouseHovering', { color: this.theme ? this.theme : 'whiteFocus', label: this.label })
    },
    mouseleave() {
      this.$store.commit('mouseHovering', null)
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/config';
  .videoTeaser {
    max-width: 319px;
    @media screen and (max-width: $small) {
      position: relative;
    }
    img {
      display: block;
      width: 100%;
    }
    .label {
      display: none;
      @media screen and (max-width: $medium) {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        transform: translate(-20%, -50%);
        font-size: 12px;
        letter-spacing: 0.135em;
        text-transform: uppercase;
        &:before {
          content: '';
          width: 77px;
          height: 77px;
          border-radius: 50%;
          background: white;
          display: block;
          position: absolute;
          top: 50%;
          right: 120%;
          transform: translate(50%,-50%);
          opacity: 0.3;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 120%;
          transform: translate(50%,-50%);
          opacity: 1;
          height: 0;
          width: 0;

          border-left: 15px solid white;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }
      }
    }
  }
</style>