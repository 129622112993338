<template>
  <div class="wrapper" v-if="data.button_label">
    <div class="downloadRequest">
      <div class="text" v-html="data.download_content"></div>
      <primary-button @mousedown="open()">{{ data.button_label }}</primary-button>
      <div class="downloadRequestForm" :class="{ active: active }">
        <div v-if="!success">
          <div class="fields">
            <div class="field" :class="{ error: error.email }">
              <input
                type="text"
                v-model="firstname"
                :placeholder="$root.translate('firstname')"
              />
            </div>
            <div class="field" :class="{ error: error.email }">
              <input
                type="text"
                v-model="lastname"
                :placeholder="$root.translate('lastname')"
              />
            </div>
          </div>
          <div class="fields" style="margin-bottom: 30px">
            <div class="field" :class="{ error: error.email }">
              <input
                type="text"
                v-model="email"
                :placeholder="$root.translate('email')"
              />
            </div>
            <div class="field" :class="{ error: error.company }">
              <input
                type="text"
                v-model="company"
                :placeholder="$root.translate('company')"
              />
            </div>
          </div>
          <div class="field">
            <input type="checkbox" id="newsletter" v-model="newsletter" />
            <label for="newsletter">{{
              $root.translate("newsletter_checkbox")
            }}</label>
          </div>
          <div class="field" :class="{ error: error.privacy }">
            <input type="checkbox" id="privacy" v-model="privacy" />
            <label
              for="privacy"
              v-html="$root.translate('privacy_checkbox')"
            ></label>
          </div>
          <primary-button theme="white" @mousedown="submit">{{
            $root.translate("submit")
          }}</primary-button>
        </div>
        <div class="success" v-if="success">
          <p>
            <strong>{{ $root.translate("request_success") }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "./PrimaryButton";
import APIService from "./../../helpers/APIService";

export default {
  name: "DownloadRequest",
  props: ["data", "post"],
  components: {
    PrimaryButton,
  },
  watch: {
    $route() {
      this.success = false;
    },
  },
  data() {
    return {
      email: "",
      firstname: "",
      lastname: "",
      company: "",
      privacy: false,
      newsletter: false,
      active: false,
      success: false,
      error: {
        email: false,
        company: false,
        firstname: false,
        lastname: false,
        privacy: false,
      },
    };
  },
  methods: {
    open() {
      this.active = !this.active;
      this.success = false;
    },
    submit() {
      if (!this.lastname) {
        this.error.lastname = true;
      }
      if (!this.firstname) {
        this.error.firstname = true;
      }
      if (!this.company) {
        this.error.company = true;
      }
      if (!this.email) {
        this.error.email = true;
      }
      if (!this.privacy) {
        this.error.privacy = true;
      }

      if (this.email && this.privacy) {
        Object.keys(this.error).forEach((key) => {
          this.error[key] = false;
        });

        const requestData = {
          email: this.email,
          company: this.company,
          firstname: this.firstname,
          lastname: this.lastname,
          id: this.post.id,
        };

        if (this.newsletter) {
          requestData.newsletter = true;
        }

        new APIService()
          .postRequest(requestData)
          .then((data) => {
            this.success = true;

            new APIService().postDownload(
              {
                id: this.post.id,
                token: data.token,
              },
              data.filename || "nunatak-paper"
            );
          })
          .catch(({ error }) => {
            Object.keys(error).forEach((key) => {
              this.error[key] = error[key];
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/config";
.downloadRequest {
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 100px;
  strong {
    font-size: 22px;
  }
  @media screen and (max-width: $medium) {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 60px;
    strong {
      font-size: 17px;
    }
  }
}

.downloadRequestForm {
  background: $primary;
  padding: 50px 100px 30px;
  @media screen and (max-width: $medium) {
    padding: 50px 0 30px 20px;
  }
  display: none;
  &.active {
    display: block;
  }
  .fields {
    display: flex;
    margin: 0 0 10px;
    @media screen and (max-width: $medium) {
      display: block;
    }
  }
  .field {
    margin-right: 20px;
    flex: 1;
    &.error {
      input {
        border: 1px solid red;
        color: red;
      }
    }
    input {
      padding: 20px 20px;
      background: transparent;
      border: 1px solid white;
      font-size: 17px;
      width: 100%;
      box-sizing: border-box;
      -webkit-appearance: none;
      font-family: $font;
    }
    &.error {
      label {
        color: red;
        &:after {
          border-color: red;
        }
      }
    }
  }
  .field {
    margin-bottom: 10px;
  }

  button {
    position: absolute;
    right: 140px;
    bottom: 50px;
    @media screen and (max-width: $medium) {
      position: relative;
      right: auto;
      bottom: auto;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999em;
    & + label {
      padding: 0 20px 0 40px;
      margin-bottom: 20px;
      position: relative;
      display: block;
      a {
        color: $color;
        text-decoration: underline;
      }
      &:before {
        content: "";
        width: 26px;
        height: 26px;
        border: 1px solid white;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 4px;
        width: 20px;
        height: 20px;
        background: $color;
        transform: translate(0, -50%) scale(0.8);
        opacity: 0;
        transition: opacity 500ms ease, transform 500ms ease;
      }
    }
    &:checked + label {
      &:after {
        opacity: 1;
        transform: translate(0, -50%) scale(1);
      }
    }
  }
}
</style>
