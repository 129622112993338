<template>
  <div v-if="page" class="pageContent">
    <div class="wrapper">
      <h1 v-html="page.title.rendered"></h1>
      <div class="content" v-html="page.content.rendered"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import titleMixin from '../mixins/titleMixin'
import metaMixin from '../mixins/metaMixin'

export default {
  name: 'Page',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.page ? 'NUNATAK - ' + this.page.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.page) {
      const desc = this.page.acf.find(page => page.component === 'description');
      const keywords = this.page.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
      }
    }
  },
  computed: { ...mapState({
      page: state => state.page.page
    }),
  },
  components: {

  },
  watch: {
    $route() {
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let slug = this.$route.params.slug;
      return new Promise((resolve, reject) => {
        this.$store.dispatch('page/getPage', {
          slug: slug,
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          if(this.page) {
            this.$store.dispatch('language/setCurrentLanguage', this.page.lang);
            this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en']);
          }
          else {
            this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
            this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
          }
          resolve();
          this.$root.loading = false;
        }).catch(() => {
          this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
          this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
          reject()
        });
      }) 
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/config';
.pageContent {
  padding: 100px 0;
  h1 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin-bottom: 50px;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
  }
  .content {
    font-size: 17px;
    line-height: 30px;
  }
}
</style>