<template>
  <div v-if="publication" class="publicationContent">
    <div class="back wrapper" >
      <router-link :to="language == 'en' ? '/en/topics/' : '/themen/'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
        <span>{{ $root.translate('publications_back') }}</span>
      </router-link>
    </div>
    <dynamic-component v-for="(element, comp) in elements" :key="'s' + comp" :comp="element.component" :data="element.data" :post="publication"></dynamic-component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DynamicComponent from '../../components/elements/DynamicComponent'
import titleMixin from '../../mixins/titleMixin';
import { pathTranslate } from '../../helpers/NavigationService'
import metaMixin from '../../mixins/metaMixin'

export default {
  name: 'PublicationsDetail',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.publication ? 'NUNATAK - ' + this.publication.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.publication) {
      const desc = this.publication.acf.find(page => page.component === 'description');
      const keywords = this.publication.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null,
      }
    }
  },
  computed: { 
    ...mapState({
      publication: state => state.publication.post,
      page: state => state.page.page,
      language: state => state.language.language
    }),
    elements() {
      return this.publication.acf.filter((c) => c && typeof c.component !== 'undefined');
    },
    localizedPublications() {
      let shouldMatchLang = (url) => {
        let lang = this.language;
        const ifEN = url.indexOf('/en') == 0 && lang == 'en';
        const ifDE = url.indexOf('/en') != 0 && lang == 'de';

        return ifEN || ifDE;
      }
      let publications = this.allPublications ? this.allPublications.filter((route) => shouldMatchLang(route.url) ) : null;
      return publications;
    },
    firstPublication(){
      const pub = this.localizedPublications ? this.localizedPublications[0] : null;
      return pub;
    } 
  },
  components: {
    DynamicComponent
  },
  watch: {
    $route(to) {
      if (to.path.startsWith('/themen') || to.path.startsWith('/en/topics')) {
        this.init();
      }
    },
    allPublications(value) {
      if(value) {
        if(!this.$route.params.slug && this.firstPublication) {
          const toFirst = pathTranslate('publications', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de') + this.firstPublication.slug;
          this.$router.push(toFirst)
        }
      }
    }
  },
  serverPrefetch() {
    this.init();
  },
  asyncData({store, route}) {
    return store.dispatch('publication/getPublication', {
      slug: route.params.slug,
      language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if(!this.$route.params.slug && this.firstPublication) {
        const toFirst = pathTranslate('publications', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de') + this.firstPublication.slug;
        this.$router.push(toFirst)
      }
      return new Promise((resolve) => {
        const lang = (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de';
        this.$store.dispatch('page/getPage', {
          slug: lang == 'en' ? 'topics' : 'themen',
          language: lang
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('publication/getPublication', {
            slug: this.$route.params.slug,
            language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
          })
          .then(() => {
            resolve()
            this.$root.loading = false;
            if(typeof window !== 'undefined') window.scrollTo(0,0);
          })
          .catch((e) => {
            this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
            this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
            console.log(e);
            if(typeof window !== 'undefined') window.scrollTo(0,0);
            resolve();
          });
          this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de')
        })
      });
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config.scss';
.publicationContent {
  padding-top: 40px;
  margin-bottom: 150px;
}

.back {
    margin-top: 28px;
    margin-bottom: 50px;
    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
      left: -2vw;
    }
    a {
      color: $color;
      text-decoration: none;
      transition: color 500ms ease;
      svg {
        transition: transform 500ms ease;
          * {
            transition: fill 500ms ease;
          }
        }
      &:hover {
        color: $primary;
        svg {
          transform: translate(-5px) rotate(180deg);
          * {
            fill: $primary;
          }
        }
      }
    }
    @media screen and (max-width: $large) {
      svg {
        left: 20px;
      }
      padding: 0 0 0 60px;
    }
  }

</style>