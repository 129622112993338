
import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    loading: true,
    page: null
  },
  mutations: {
    setPage(state, post) {
      state.page = post
    },
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    getPage({ commit }, { slug, language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPageBySlug(slug, language).then( (post) => {
          let p = post.data[0];
          if(!p) return;
          const elements = [];
          
          if (p.acf) {
            Object.keys(p.acf).forEach((key) => {
              let data = p.acf[key];
              if( data ) {
                if(data.length == 1 && Object.keys(p.acf[key][0])[0] === key) {
                  data = p.acf[key][0][key];
                }
                if(key === 'blog_content') {
                  key = 'content_container';
                  data = p.acf['blog_content'][0]['content_container']
                }
                elements.push({
                  component: key,
                  data: data
                })
              }
            });
          }
          p.acf = elements;

          const hooks = (p) => {
            // ACADEMY (DE / EN)
            if(p.slug === 'academy') {
              let elements = [];
              const academy = [
                'stage',
                'intro',
                'academy_triangle',
                'academy_boxes',
                'image',
                'our_values',
                'career_gallery',
                'advisory_board',
                'project_reference',
                'contact_teaser',
              ];
              academy.forEach((key) => {
                elements.push(p.acf.filter(value => key === value.component)[0])
              })
              p.acf = elements;
              p.academyTheme = 1;
            }
            // HOME
            if(p.slug == 'home' || p.slug == 'home-2') {
              let elements = [];
              const modules = [
                'mountain_stage',
                'intro',
                'news_slider',
                'expertise_boxes_human_centric',
                'project_reference',
                'academy_teaser',
                'career_gallery',
                'great_teaser',
              ];
              modules.forEach((key) => {
                elements.push(p.acf.filter(value => key === value.component)[0])
              })
              p.acf = elements;
            }

            // HOME
            if(p.slug == 'expertise' || p.slug == 'expertise-2') {
              let elements = [];
              const modules = [
                'stage',
                'expertise_boxes_human_centric',
                'client_logos',
                'catch_line',
                'facts',
                'great_teaser',
              ];
              modules.forEach((key) => {
                elements.push(p.acf.filter(value => key === value.component)[0])
              })
              p.acf = elements;
            }

            // HOME
            if(p.slug == 'karriere' || p.slug == 'career') {
              let elements = [];
              const modules = [
                'stage',
                'intro',
                'career_gallery',
                'team_teaser',
                'offer_boxes',
                'slider',
                'steps',
                'jobs',
                'faqs',
                'contact_teaser',
                'our_values',
                //'newsletter',
                //'news_slider'
              ];
              modules.forEach((key) => {
                try {
                  if (p.acf.find(value => key === value.component)) {
                    elements.push(p.acf.find(value => key === value.component))
                  } else {
                    elements.push({ component: key, data: {} }); // default case;
                  }
                } catch(e) {
                  // eslint-disable-next-line no-console
                  console.log(e, key, p.acf);
                }
              })
              p.acf = elements;
            }
            return p;
          }
          resolve(hooks(p));
          // eslint-disable-next-line no-console
          commit('setPage', hooks(p))
          commit('loading', false);
        }).catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
          reject(e)})
      })
    }
  }
}