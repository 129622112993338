<template>
  <div class="posts" v-if="page">
    <stage v-if="stageData" :data="stageData" :post="page"></stage>
    <div class="wrapper">
      <div class="newsFilter">
        <h5>{{ $root.translate('news_filter') }}</h5>
        <nav>
          <ul>
            <li><a href="#all" :class="{ active: filterByCategories.length == 0}" @click="removeFilter()">{{ $root.translate('all_news')}}</a></li>
            <li v-for="(category, index) in categories" :key="index"><a :href="`#${category.slug}`" :class="{ active: filterByCategories.length && filterByCategories.find(filterByCategory => filterByCategory.id == category.id)}" @click="toggleFilter(category)">{{category.name}}</a></li>
          </ul>
        </nav>
      </div>
      <div class="newsHighlighted" v-if="highlightedPost && showList">
        <div class="left">
          <h5>{{ $root.translate('featured_article') }}</h5>
          <h4 v-html="highlightedPost.title.rendered"></h4>
          <span class="date">{{ formatDate(highlightedPost.date) }} <span class="category"><a v-for="cat in getCategory(highlightedPost.categories)" :key="cat.id" :href="`#${cat.slug}`" @click="filter([cat])"># {{ cat.name }}</a></span></span>
          <div class="intro">
            <div v-html="highlightedPost.acf[1].data"></div>
          </div>
          <primary-button :to="(language == 'en' ? '/en' : '') + '/news/' + highlightedPost.slug">{{ $root.translate('read_article') }}</primary-button>
        </div>
        <div class="right">
          <div>
            <router-link :to="(language == 'en' ? '/en' : '') + '/news/' + highlightedPost.slug">
              <lazy-image :image="highlightedPost.acf[0].data.header_image" :refreshCount="refreshCount"></lazy-image>
            </router-link>
            <small>{{ highlightedPost.acf[0].data.header_image.caption }}</small>
          </div>
        </div>
      </div>
      <div class="newsEntries" v-if="showList">
        <h5>{{ $root.translate('latest_news') }}</h5>
        <div class="newsEntriesContainer">
          <div class="item" v-for="(item) in otherPosts" :key="item.id">
              <figure>
                <router-link :to="(language == 'en' ? '/en' : '') + `/news/${item.slug}`">
                  <div>
                      <lazy-image :refreshCount="refreshCount" :image="item.acf[0].data.header_image"></lazy-image>
                  </div>
                </router-link>
                <figcaption>
                  <div>
                    <span class="date">{{ formatDate(item.date) }} <span class="category"><a v-for="cat in getCategory(item.categories)" :key="cat.id" :href="`#${cat.slug}`" @click="filter([cat])"># {{ cat.name }}</a></span></span>
                    <router-link :to="(language == 'en' ? '/en' : '') + `/news/${item.slug}`"><span class="title" v-html="item.title.rendered"></span></router-link>
                  </div>
                  <router-link :to="(language == 'en' ? '/en' : '') + '/news/' + item.slug" class="arrowLink">
                    {{ $root.translate('read_article') }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                  </router-link>
                </figcaption>
              </figure>
          </div>
          <div class="furtherButton" v-if="!showFurther && furtherPosts">
            <primary-button @click="showFurther = true">{{ $root.translate('more_news') }}</primary-button>
          </div>
          <div class="furtherEntries" :class="{ show: showFurther }">
            <div class="item" v-for="(item) in furtherPosts" :key="item.id">
              <router-link :to="(language == 'en' ? '/en' : '') + `/news/${item.slug}`">
                <figure>
                  <div>
                    <lazy-image :image="item.acf[0].data.header_image" :refreshCount="refreshCount"></lazy-image>
                  </div>
                  <figcaption>
                    <div>
                      <span class="date">{{ formatDate(item.date) }} <span class="category"><a v-for="cat in getCategory(item.categories)" :key="cat.id" :href="`#${cat.slug}`" @click="filter([cat])"># {{ cat.name }}</a></span></span>
                      <span class="title" v-html="item.title.rendered"></span>
                    </div>
                    <router-link :to="(language == 'en' ? '/en' : '') + '/news/' + item.slug" class="arrowLink">
                      {{ $root.translate('read_article') }}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                    </router-link>
                  </figcaption>
                </figure>
              </router-link>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Stage from '../../components/elements/Stage';
import PrimaryButton from '../../components/elements/PrimaryButton';
import titleMixin from '../../mixins/titleMixin';
import metaMixin from '../../mixins/metaMixin';
import LazyImage from './../../components/elements/LazyImage';

export default {
  name: 'NewsIndex',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.page ? 'NUNATAK - ' + this.page.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.page) {
      const desc = this.page.acf.find(page => page.component === 'description');
      const keywords = this.page.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null
      }
    }
  },
  data() {
    return {
      filterByCategories: [],
      showFurther: false,
      refreshCount: 0,
      showList: true
    }
  },
  computed: { 
    ...mapState({
      posts: state => state.post.posts,
      page: state => state.page.page,
      categories: state => state.category.categories,
      language: state => state.language.language
    }),
    stageData() {
      return this.page && this.page.acf.length && this.page.acf[0] && this.page.acf[0].data ? this.page.acf[0].data : null;
    },
    filteredPosts() {
      if(this.posts && this.filterByCategories.length) {
        return this.posts.filter( post => post.categories.find(catId => this.filterByCategories.map(c => c.id).includes(catId)) );
      }
      else {
        return this.posts;
      }
    },
    sortedPosts() {
      return this.filteredPosts ? this.filteredPosts.slice(0).sort((a, b) => {
        if (!a.sticky && b.sticky) {
          return 1;
        }
        else if(a.sticky && !b.sticky) {
          return -1;
        }
        else {
          return 0;
        }
      }) : null;
    },
    highlightedPost() {
      if(this.filterByCategories.length === 0 && this.sortedPosts && this.sortedPosts.length && this.sortedPosts[0].sticky) {
        return this.sortedPosts[0];
      }
      return null;
    },
    otherPosts() {
      if(this.sortedPosts && this.sortedPosts.length && this.highlightedPost) {
        return this.sortedPosts.slice(1, 13);
      } else if (this.sortedPosts) {
        return this.sortedPosts.slice(0, 12);
      }
      else {
        return null;
      }
    },
    furtherPosts() {
      if (this.sortedPosts && this.sortedPosts.length > 12) {
        return this.sortedPosts.slice(13);
      }
      else {
        return null;
      }
    },
  },
  watch: {
    filterByCategories() {
      // refresh list
      this.showList = false;
      setTimeout(() => this.showList = true, 50);
    }
  },
  components: {
    Stage,
    PrimaryButton,
    LazyImage
  },
  serverPrefetch() {
    this.init();
  },
  mounted() {    
    this.init();
  },
  methods: {
    init() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('page/getPage', {
          slug: 'news',
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('language/setCurrentLanguage', this.page.lang)
          .then(() => {
            this.$store.dispatch('category/getCategories').then(() => {
              this.$nextTick(() => this.checkURL());
            });
            this.$store.dispatch('post/getPosts', { language: this.language });
            resolve();
            this.$root.loading = false;
          })
          .catch(() => reject())
        }).catch(() => reject());
      })
      
    },
    removeFilter() {
      this.filterByCategories = [];
      this.refreshCount += 1;
    },
    toggleFilter(category) {
      if(this.filterByCategories.includes(category)) {
        this.filterByCategories = this.filterByCategories.filter((c) => c !== category);
      }
      else {
        this.filterByCategories.push(category);
      }
      this.refreshCount += 1;
    },
    filter(categories) {
      this.filterByCategories = categories;
      this.refreshCount += 1;
    },
    checkURL() {
      if(this.$route.hash) {
        const categories = this.categories.filter(c => this.$route.hash.slice(1).trim().split(',').includes(c.slug));
        if(categories.length) {
          this.filter(categories);
        }
      }
    },
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    getCategory(ids) {
      if(!this.categories) return null;
      return this.categories.filter((category) => ids.includes(category.id) );
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.posts {
  min-height: calc(100vh - 80px);
}
.newsFilter {
  padding: 40px 0 0 0;
  margin-bottom: 85px;
  @media screen and (max-width: $medium) {
    margin-bottom: 50px;
  }
  h5 {
    margin-bottom: 35px;
  }
  nav {
    margin-bottom: 100px;
    @media screen and (max-width: $medium) {
      margin-bottom: 80px;
    }
    ul {
      display: flex;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      
      li {
        display: block;
        margin-right: 15px;
        a {
          color: $color;
          text-align: center;
          text-decoration: none;
          position: relative;
          display: block;
          white-space: nowrap;
          width: 150px;
          line-height: 30px;
          border-radius: 15px;
          background: #d9d9d9;
          font-size: 20px;
          transition: background-color 500ms ease;
          margin-bottom: 15px;
          @media screen and (max-width: $small) {
            width: auto;
            padding: 0 15px;
            max-width: 120px;
            font-size: 15px;
          }
          &:before {
            content: '#';
            display: inline-block;
            margin-right: 5px;
          }
          &.active {
            background: $primary;
          }
          &:hover {
            &:after {
              width: 100%
            }
          }
        }
      }
    }
  }
}
.newsHighlighted {
  display: flex;
  margin-bottom: 130px;
  @media screen and (max-width: $small) {
    display: block; 
    margin-bottom: 80px;
  }
  .right {
    min-width: 45%;
    position: relative;
    > div {
      width: 50vw;
      overflow: hidden;
      height: 100%;
      position: absolute;
      background: $primary;
      @media screen and (max-width: $small) {
        width: auto;
        position: relative;
        height: auto;
        overflow: hidden;
        &:after {
          content: '';
          display: block;
          padding-top: 80%;
        }
        small {
          display: block;
          padding-top: 10px;
          font-size: 10px;
          position: absolute;
          bottom: 0;
        }
      }
      img {
        //max-height: 690px;
        height: calc(100%);
        @media screen and (max-width: $small) {
          //height: 100%;
          position: absolute
        }
      }
    }
  }
  .left {
    min-width: 55%;
    padding-right: 80px;
    box-sizing: border-box;
    @media screen and (max-width: $small) {
      padding-right: 0;
      margin-bottom: 40px;
    }
    h5 {
      margin: 0 0 1em;
    }
  }

  .date {
    letter-spacing: 0.135em;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin: 8px 0 15px;
    .category {
      color: $primary;
      display: inline-block;
      margin-left: 5px;
      a {
        text-decoration: none;
      }
    }
  }

  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: normal;
    margin: 0 0 30px;
    font-weight: 300;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }

    &:after {
      content: '';
      background: $primary;
      height: 6px;
      width: 55px;
      display: block;
      margin: 30px 0;
    }
  }
  .intro {
    font-size: 20px;
    line-height: 28px;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;
     @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    }

    
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255, 0.9) 10%, rgba(255,255,255,0) 100%);
    }
  }
}
.newsEntries {
  .newsEntriesContainer {
    display: flex; 
    flex-wrap: wrap;
    margin-bottom: 210px;
    @media screen and (max-width: $small) {
      margin-bottom: 210px;
    }

    .furtherButton {
      display: block;
      min-width: 100%;
    }
    .furtherEntries {
      flex-wrap: wrap;
      display: none;
      &.show {
        display: flex;
      }
    }
    .item {
      width: 33.33%;
      margin-bottom: 100px;
      @media screen and (max-width: $small) {
        width: 100%;
        margin-bottom: 50px;
      }
      a {
        text-decoration: none;
      }
      figure {
        margin: 0;
        padding: 0;
        display: flex;
        min-height: 100%;
        flex-direction: column;
        .ie & {
          display: block;
        }
        > a > div {
          position: relative;
          overflow: hidden;
          margin: 0 20px 0 0;
          background: $primary;
          @media screen and (max-width: $small) {
            margin: 0;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transition: transform 500ms ease;
            transform: translate(-50%) scale(1);
            height: 100%;
          }
          &:after {
            content: '';
            display: block;
            padding-top: 80%;
          }
        }
        figcaption {
          text-decoration: none;
          color: $color;
          flex: 1;
          //min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .date {
            letter-spacing: 0.135em;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin: 8px 0 15px;
            .category {
              color: $primary;
              display: inline-block;
              //margin-left: 20px;
              a {
                display: inline-block;
                margin: 0 5px;
              }
            }
          }

          .title {
            font-size: 27px;
            line-height: 35px;
            display: block;
            margin-bottom: 15px;
            max-width: 90%;
            color: $color;
            //min-height: 200px;
            @media screen and (max-width: $small) {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
      &:hover {
        figure {
          > a > div {
            img {
              transform: translate(-50%) scale(1.1);
            }
          } 
        }
      }
    }
  }
}
</style>