<template>
  <div v-if="post">
    <dynamic-component v-for="(element, comp) in post.acf" :key="comp" :comp="element.component" :data="element.data" :post="post"></dynamic-component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DynamicComponent from '../../components/elements/DynamicComponent';
import titleMixin from '../../mixins/titleMixin';
import metaMixin from '../../mixins/metaMixin';

export default {
  name: 'NewsDetail',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.post ? 'NUNATAK - ' + this.post.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.post) {
      const desc = this.post.acf.find(page => page.component === 'description');
      const keywords = this.post.acf.find(page => page.component === 'keywords');
      const stage = this.post.acf.find(page => page.component === 'stage');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: stage ? stage.data.header_image.url : null
      }
    }
  },
  asyncData({store, route}) {
    return new Promise((resolve) => {
      Promise.all(
        [
          store.dispatch('post/getPost', {
            slug: route.params.slug,
            language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
          })
        ]
      ).finally(() => resolve());
    }) 
  },
  computed: { ...mapState({
      post: state => state.post.post
    }),
    routeSlug() {
      return this.$route.params.slug;
    }
  },
  components: {
    DynamicComponent
  },
  watch: {
    routeSlug() {
      this.init();
    }
  },
  serverPrefetch() {
    return this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      return new Promise((resolve) => { 
        this.$store.dispatch('post/getPost', {
          slug: this.$route.params.slug,
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.post.translations[(this.post.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('language/setCurrentLanguage', this.post.lang)
          resolve();
          this.$root.loading = false;
          if(typeof window !== 'undefined') window.scrollTo(0,0);
        }).catch((e) => {
          console.log(e);
          if(typeof window !== 'undefined') window.scrollTo(0,0);
          resolve()
        })
      });
    }
  }
}
</script>