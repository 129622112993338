<template>
  <div class="academyBoxes">
    <div class="wrapper">
      <div class="academyBoxesContainer">
        <div v-for="(topic, key) in data.topic" :key="key" :class="{ active: activeTopic == topic }" :id="topic.id">
          <h4 @click="showMore(topic)"><small>0{{ key + 1}}</small> {{ topic.title }}</h4>
          <p v-if="topic.description">{{ topic.description }}</p>
          <ul class="active">
            <li v-for="(item, k) in topic.list_items.slice(0, 4)" :key="k" :class="{ active: activeItem == item}">
              <a href="#" @click.prevent="toggleItem(item, topic)">
                {{ item.title }}
                <svg v-if="item.description" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </a>
              <div v-if="item.description" :class="{ active: activeItem == item}">{{ item.description }}</div>
            </li>
          </ul>
          <div v-if="topic.list_items.length > 4">
            <a v-if="showMoreTopic != topic || activeTopic != topic" class="arrowLink" href="#" @click.prevent="showMore(topic)">
              {{ $root.translate('before_topic_more') }} {{ $root.translate('after_topic_more') }}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </a>
            <ul :class="{ active: showMoreTopic == topic && activeTopic == topic }">
              <li v-for="(item, k) in topic.list_items.slice(4)" :key="k" :class="{ active: activeItem == item}">
                <a href="#" @click.prevent="toggleItem(item, topic)">
                  {{ item.title }}
                  <svg v-if="item.description" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                </a>
                <div v-if="item.description" :class="{ active: activeItem == item}">{{ item.description }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcademyBoxes',
  props: ['data'],
  data() {
    return {
      activeTopic: null,
      activeItem: null,
      showMoreTopic: null
    }
  },
  mounted() {
    // this.$root.$on('academyBoxes.active', (activeIndex) => {
    //   this.activeItem = null;
    //   this.showMoreTopic = this.activeTopic = this.data.topic[activeIndex];
    // })
  },
  methods: {
    toggleItem(item, topic) {
      if(item != null  && !item.description) return;
      [].forEach.call(document.querySelectorAll('[data-link]'), (el) => el.classList.remove('active'));
      if(this.activeItem === item) {
        this.activeItem = null;
        this.activeTopic = null;
      } else {
        this.activeItem = item;
        this.activeTopic = topic;
        document.querySelector('[data-link="' + topic.id + '"]').classList.add('active');
      }
    },
    showMore(topic) {
      this.activeItem = null;
      this.activeTopic = topic;
      this.showMoreTopic = topic;
      [].forEach.call(document.querySelectorAll('[data-link]'), (el) => el.classList.remove('active'));
      document.querySelector('[data-link="' + topic.id + '"]').classList.add('active');
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.academyBoxes {
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    margin-bottom: 100px;

    }
  .wrapper {
    z-index: 9;
  }
  .academyBoxesContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: $small) {
      display: block
    }
    > div {
      padding: 30px;
      width: calc(33.33% - 20px);
      margin-right: 20px;
      @media screen and (max-width: $small) {
        width: auto;
        margin-right: 0;
      }
      > p {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 500ms ease, max-height 500ms ease, margin 500ms ease;
        margin: 0;
      }

      .arrowLink {
        margin-top: 30px;
      }
      h4 {
        font-size: 37px;
        line-height: 45px;
        font-weight: normal;
        margin: 0 0 0.75em;
        cursor: pointer;
        @media screen and (max-width: $small) {
          font-size: 27px;
          line-height: 35px;
          margin: 0 0 0.5em
        }
        small {
          display: block;
          font-size: 19px;
        }
      }
      ul {
        margin: 0;
        padding: 0 0 0 1em;
        opacity: 0;
        max-height: 0;
        transition: opacity 500ms ease, max-height 500ms ease;
        overflow: hidden;
        &.active {
          opacity: 1;
          max-height: 1000px;
        }
        li {
          a {
            font-size: 19px;
            line-height: 32px;
            text-decoration: none;
            color: $color;
            position: relative;
            transition: color 500ms ease;
            @media screen and (max-width: $small) {
              font-size: 17px;
              line-height: 32px;
            }
            svg {
              width: 16px;
              height: 16px;
              transform: rotate(-45deg) translate(0, 10px);
              display: inline-block;
              margin-left: 10px;
              position: absolute;
              left: 100%;
              transition: transform 500ms ease;
            }
            &:hover {
              color: $academy;
              svg {
                transform: rotate(0deg) translate(0, 7px);
                * {
                  fill: $academy;
                }
              }
            }
          }
          &.active {
            color: $primary !important;
            
            a {
              color: $primary !important;
              svg {
                transform: rotate(90deg) translate(6px, 0);
                * {
                  fill: $primary !important;
                }
              }
            }
          }
          div {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: max-height 500ms ease, opacity 500ms ease, padding 500ms ease;
            padding: 0 ;
            font-size: 17px;
            line-height: 24px;
            box-sizing: border-box;
            color: white;
            @media screen and (max-width: $small) {
              font-size: 15px;
              line-height: 24px;
            }
            &.active {
              max-height: 500px;
              opacity: 1;
              padding: 0 0 20px;
            }
          }
        }
      }

      &.active {
        background: $academy;
        color: white;
        > p {
          opacity: 1;
          max-height: 200px;
          margin: 0 0 1em;
          padding-top: 0.2em;
        }
        ul {
          li {
            a {
              color: white;
              svg {
                * {
                  fill: white;
                }
              }
              &:hover {
                color: $primary;
                svg {
                  * {
                    fill: $primary;
                  }
                }
              }
            }
          }
        }
        a.arrowLink {
          color: white;
          svg {
            * {
              fill: white;
            }
          }
        }
      }
    }
  }
} 
</style>