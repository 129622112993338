import Axios from "axios";

class APIService {
  constructor() {
    this.axios = Axios.create();
    //Axios.defaults.headers.common['Authorization'] = 'Basic ' + window.btoa('guest:letmeinpleasethx');
    this.endpoint = 'https://admin.nunatak.com/wp-json/';
    
    if(typeof window == 'undefined') return;
    if(window.location.hostname === 'nunatak.buzzinmonkey.com') {
      this.endpoint = 'http://nunatak-admin.buzzinmonkey.com/wp-json/'
      if(!document.querySelector('#trainingBar')) {
        // eslint-disable-next-line no-console
        const div = document.createElement('div');
        div.id = 'trainingBar'
        div.style.backgroundColor = 'red';
        div.style.position = 'fixed';
        div.style.zIndex = 1000;
        div.style.left = 0;
        div.style.right = 0;
        div.style.bottom = 0;
        div.style.opacity = 0.5;
        div.style.color = 'white';
        //div.style.height = '10px';
        div.style.textAlign = 'center';
        div.style.textTransform = 'uppercase';
        div.style.fontWeight = 'bold';
        div.innerText = 'Test Version'
        document.body.appendChild(div);

        const divT = document.createElement('div');
        divT.id = 'trainingBar'
        divT.style.backgroundColor = 'red';
        divT.style.position = 'fixed';
        divT.style.zIndex = 1000;
        divT.style.left = 0;
        divT.style.right = 0;
        divT.style.top = 0;
        divT.style.opacity = 0.5;
        divT.style.color = 'white';
        //div.style.height = '10px';
        divT.style.textAlign = 'center';
        divT.style.textTransform = 'uppercase';
        divT.style.fontWeight = 'bold';
        divT.innerText = 'Test Version'
        document.body.appendChild(divT);
      }
      
    }
  }
  postNewsletter(data) {
    return new Promise((resolve, reject) => {
      this.axios.post(this.endpoint + 'nunatak/v1/newsletter', data)
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data);
      })
    })
  }
  postRequest(data) {
    return new Promise((resolve, reject) => {
      this.axios.post(this.endpoint + 'nunatak/v1/request', data)
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error.response.data);
      })
    })
  }
  postDownload(data, filename) {
      this.axios({
        url: this.endpoint + 'nunatak/v1/download', 
        data,
        responseType: 'blob',
        method: 'POST'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
  }
  getPageBySlug(slug, language) {
    return this.getBySlug(slug, 'page', language);
  }
  getPostBySlug(slug, language) {
    return this.getBySlug(slug, 'post', language);
  }
  getProjectBySlug(slug, language) {
    return this.getBySlug(slug, 'project', language);
  }
  getPublicationBySlug(slug, language) {
    return this.getBySlug(slug, 'publication', language);
  }
  getPDFBySlug(slug, language) {
    return  new Promise((resolve, reject) => {
      this.axios({
        params: {
          slug,
        },
        url: this.endpoint + 'wp/v2/pdf',
        method: 'get'
      })
      .then(({ data }) => resolve({ data: data.filter((d) => d.lang == language )}) )
      .then(reject);
    })
  }
  getBySlug(slug, type, language = 'de') {
    return new Promise((resolve, reject) => {
      this.axios({
        params: {
          slug,
        },
        url: this.endpoint + 'wp/v2/'+type+'s',
        method: 'get'
      })
      .then(({ data }) => resolve({ data: data.filter((d) => d.lang == language )}) )
      .then(reject);
    })
  }

  getPosts(type, language) {
    language = language ? language : localStorage.getItem('language');
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'wp/v2/'+type + '?per_page=100&lang=' + language,
        method: 'get'
      })
      .then(resolve)
      .then(reject);
    })
  }

  getOptions() {
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'acf/v3/options/options',
        method: 'get'
      })
      .then(resolve)
      .then(reject);
    })
  }

  getMenuBySlug(slug) {
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'nunatak/v1/menus/' + slug,
        method: 'get'
      })
      .then(resolve)
      .then(reject);
    })
  }

  getRoutes(language) {
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'nunatak/v1/routes/' + language,
        method: 'get'
      })
      .then(resolve)
      .then(reject);
    })
  }

  getCategories(categoriesIds, slug = 'categories') {
    const language =  typeof localStorage !== 'undefined' ?  localStorage.getItem('language') || 'de' : 'de';
    return new Promise((resolve, reject) => {
      this.axios({
        params: {
          include: categoriesIds ? categoriesIds.join(',') : null,
          lang: language
        },
        url: this.endpoint + 'wp/v2/' + slug,
        method: 'get'
      })
      .then(({ data }) => resolve({ data: data.filter(d => d.name != 'Uncategorized')}))
      .then(reject);
    })
  }
  
  getJobCategories() {
    const language =  typeof localStorage !== 'undefined' ?  localStorage.getItem('language') || 'de' : 'de';
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'wp/v2/jobcategory',
        method: 'get',
        params: {
          lang: language
        }
      })
      .then(resolve)
      .then(reject);
    })
  }

  getLanguages() {
    return new Promise((resolve, reject) => {
      this.axios({
        url: this.endpoint + 'pll/v1/languages',
        method: 'get'
      })
      .then(resolve)
      .then(reject);
    })
  }
}
export default APIService;