<template>
  <div id="app" :class="{ appLoading: $root.loading }">
    <navigation></navigation>
    <main :class="{ appLoading: $root.loading }">

      <router-view v-slot="{ Component }">
          <transition>
            <component :is="Component" />
          </transition>
        </router-view>

      <div class="des-lines">
        <div class="des-lines__line"></div>
        <div class="des-lines__line"></div>
        <div class="des-lines__line"></div>
        <div class="des-lines__line"></div>
      </div>
      <mouse-area></mouse-area>
      <to-top></to-top>
      <theme-footer></theme-footer>
    </main>
    <cookie></cookie>
    <loading :show="false && loading"></loading>
  </div>
</template>

<script>
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}
import { hasNecessaryRoute } from 'vue-router';
import Navigation from './components/Navigation'
import ThemeFooter from './components/ThemeFooter'
import MouseArea from './components/MouseArea'
import ToTop from './components/ToTop'
import Loading from './components/Loading'
import { mapState } from 'vuex'

import Cookie from './components/Cookie';

import lang_de from './l18n/de.json';
import lang_en from './l18n/en.json';

export default {
  name: 'app',
  components: {
    Navigation,
    MouseArea,
    ThemeFooter,
    Loading,
    ToTop,
    Cookie
  },
  data() {
    return {
      acceptedCookies: [],
      loading: true
    }
  },
  watch: {
    '$route'(value, old) {
      if(typeof window !== 'undefined' && window.ScrollMagicController) {
        window.ScrollMagicController.destroy(true);
        window.ScrollMagicController = new ScrollMagic.Controller();

      }
      // eslint-disable-next-line
      const onSameArea = (value.path.indexOf('themen') !== -1 && old.path.indexOf('themen') !== -1) || (value.path.indexOf('topics') !== -1 && old.path.indexOf('topics'));
      if(value.path !== old.path && !onSameArea) {
        //this.loading = true;
        // setTimeout(() => this.pageLoaded(), 1500);
        if(typeof window !== 'undefined') window.scrollTo(0,0);
      }
      setTimeout(() => {
        if(typeof window !== 'undefined' && window.gtag) {
          window.gtag('config', 'UA-50642053-3', {
            'page_title' : document.title,
            'page_path': value.path
          });
        }
      }, 500)
    }
  },
  computed: mapState({
    routes: state => state.routes.all,
    language: state => state.language.language,
  }),
  mounted(){

    this.$router.beforeEach((to, from, next) => {
      const onSameArea = (to.path.indexOf('themen') !== -1 && from.path.indexOf('themen') !== -1) || (to.path.indexOf('topics') !== -1 && from.path.indexOf('topics'));
      hasNecessaryRoute
      if(to.path !== from.path && !onSameArea) {
        //this.loading = true;
        setTimeout(() => next(), 210);
      }
      else {
        next();
      }
    })

    if(typeof window !== 'undefined') {
      window.ScrollMagicController = new ScrollMagic.Controller();
      window.addEventListener('resize', () => {
        if(window.ScrollMagicController) {
          window.ScrollMagicController.update(true);
        }
      })
    }

    setTimeout(() => this.pageLoaded(), 4000);
    setTimeout(() => {
        if(typeof window !== 'undefined' && window.gtag) {
          window.gtag('config', 'UA-50642053-3', {
            'page_title' : document.title,
            'page_path': this.$route.path
          });
        }
      }, 500)
  },
  methods: {
    translate(key) {
      if(this.language === 'de') {
        return lang_de[key] || '__ NO TRANSLATION __';
      }

      if(this.language === 'en') {
        return lang_en[key] || '__ NO TRANSLATION __';
      }
    },
    pageLoaded() {
      this.loading = false;
      if(this.$route.hash) {
        const moduleTop = document.querySelector(this.$route.hash) ? document.querySelector(this.$route.hash).getBoundingClientRect().top - 150 : 0;
        if(typeof window !== 'undefined') window.scrollTo(0, moduleTop)
      }
    },
  }
}
</script>

<style lang="scss">
@import "styles/fonts";
@import "styles/index";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#app {
  main {
    transition: opacity 200ms ease;
  }
  .appLoading {
    opacity: 0;
  }
}
</style>
