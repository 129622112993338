<template>
  <div class="clientLogos" v-if="data.gallery.length">
    <div class="background"></div>
    <div class="wrapper">
      <h5>{{ data.title }}</h5>
      <ul>
        <li v-for="(item, key) in data.gallery" :key="key"><img :src="item.url" :alt="item.alt"></li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ClientLogos',
  props: ['data']
}
</script>

<style lang="scss">
@import '../../styles/config';
.clientLogos {
  position: relative;
  padding: 80px 0;
  margin-bottom: 130px;
  @media screen and (max-width: $small) {
    margin-bottom: 80px;
    padding: 40px 0;
  }
  h5 {
    margin: 0 0 50px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    li {
      width: 25%;
      padding: 0 30px;
      box-sizing: border-box;
      @media screen and (max-width: $small) {
        width: 50%;
      }
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  .background {
    background: $color;
    display: block;
    position: absolute;
    top: 0;
    right: 80px;
    left: 0;
    bottom: 0;
    @media screen and (max-width: $medium) {
      right: 0;
    }
  }
}
</style>