<template>
  <div class="newsletter">
    <div class="wrapper">
      <div class="newsletterContainer">
        <h5 class="white">{{ data.title || $root.translate('newsletter') }}</h5>
        <h4 ref="title" @mouseenter="triggerEmoji" @mousemove="moveEmoji" @mouseleave="stopEmoji">{{ data.subline || $root.translate('newsletter_subline') }}</h4>
        <div class="content">{{ data.text || null }}</div>
        <div class="form" v-if="!success">
          <div class="field">
            <input type="text" v-model="firstname" :placeholder="$root.translate('firstname')" >
          </div>
          <div class="field">
            <input type="text" v-model="lastname" :placeholder="$root.translate('lastname')" >
          </div>
          <div class="field" :class="{error: error.email}">
            <input type="text" v-model="email" :placeholder="$root.translate('email')" >
          </div>
          <div class="field" :class="{error: error.privacy}">
            <input type="checkbox" id="privacy" v-model="privacy" >
            <label for="privacy" v-html="$root.translate('privacy_checkbox')"></label>
          </div>
          <primary-button theme="white" @click="submit">{{ $root.translate('submit') }}</primary-button>
        </div>
        <div class="success" v-if="success">
          <p><strong>{{ $root.translate('newsletter_success') }}</strong></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from './PrimaryButton';
import APIService from '../../helpers/APIService';
export default {
  name: 'Newsletter',
  props: ['data'],
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      privacy: false,
      emojiInterval: null,
      mouseX: 0,
      mouseY: 0,
      success: false,
      error: {
        email: false,
        privacy: false
      }
    }
  },
  components: {
    PrimaryButton
  },
  methods: {
    triggerEmoji() {
      if(!this.data.title) return;
      const pushEmoji = () => {
        
        const element = document.createElement('span');
        element.setAttribute('class', 'cocktail')
        let cocktails = ["🍹", "🍸", "🍺"]
        element.innerText = cocktails[Math.round(Math.random() * 2)];
        element.style.position = 'fixed';
        element.style.fontSize = "27px";
        element.style.top = this.mouseY +  (Math.random() * 20 - 10) + 'px';
        element.style.left = this.mouseX +  (Math.random() * 20 - 10) + 'px';
        document.body.appendChild(element);
        setTimeout(() => document.body.removeChild(element), 500);
      }
      
      this.emojiInterval = setInterval(() => {
        pushEmoji();
      }, 50);
    },
    moveEmoji($event) {
      if(!this.data.title) return;
      this.mouseX = $event.clientX;
      this.mouseY = $event.clientY;
    },
    stopEmoji() {
      if(!this.data.title) return;
      clearInterval(this.emojiInterval);
    },
    submit() {
      if (!this.email) {
        this.error.email = true;
      }
      if (!this.privacy) {
        this.error.privacy = true;
      }

      if(this.email && this.privacy) {
        (new APIService).postNewsletter({
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          fridayBar: !!this.data.title
        }).then(() => {
          this.success = true;
        }).catch(({error}) => {
          this.error.email = error.email
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';

.cocktail {
  animation: cocktail 500ms ease;
  @keyframes cocktail {
    0% { transform: translate(0,0) }
    100% { transform: translate(0,-200px) }
  }
}

.newsletter {
  position: relative;
  padding: 50px 0;
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    margin-bottom: 130px;
  }
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    width: 65%;
    background: $primary;
    @media screen and (max-width: $medium) {
      width: 100%;
    }
  }
  .content {
    font-size: 17px;
    line-height: 30px;
    @media screen and (max-width: $small) { 
      font-size: 15px;
      line-height: 24px;
    }
  }
  .newsletterContainer {
    max-width: 60%;
    @media screen and (max-width: $medium) {
      max-width: 100%;
    }
    h4 {
      font-size: 38px; 
      line-height: 47px;
      font-weight: 300;
      margin: 0 0 1em;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .form {
      .field {
        margin: 30px 0;
        @media screen and (max-width: $medium) {
          margin: 30px 0;
        }
        @media screen and (max-width: $small) {
          margin: 30px 0;
        }
        input[type=text] {
          display: block;
          width: 80%;
          background: transparent;
          border: none;
          padding: 15px;
          color: white;
          font-size: 17px;
          border: 1px solid white;
          font-family: $font;
          outline: none;
          max-width: 500px;
          -webkit-appearance: none;
          @media screen and (max-width: $small) {
            width: 100%;
            font-size: 15px;
            border: 1px solid white;
            border-radius: 0;
            box-shadow: 0;
            outline: none;
            padding: 15px 15px;
            box-sizing: border-box
          }
        }
        input[type=checkbox] {
          position: absolute;
          top: 0;
          left: -9999em;
          & + label {
            padding: 0 0 0 40px;
            position: relative;
            display: block;
            a {
              color: $color;
            }
            &:before {
              content: '';
              width: 26px;
              height: 26px;
              border: 1px solid white;
              display: block;
              position: absolute;
              left: 0;
              top: 8px;
              transform: translate(0, -50%);
            }
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 8px;
              left: 4px;
              width: 20px;
              height: 20px;
              background: $color;
              transform: translate(0, -50%) scale(0.8);
              opacity: 0;
              transition: opacity 500ms ease, transform 500ms ease;
            }

          }
          &:checked + label {
            &:after {
              opacity: 1;
              transform: translate(0, -50%) scale(1);
            }
          }
        }

        &.error {
          input[type=text] {
            border-bottom: 1px solid red;
          }
          input[type=checkbox] {
            & + label {
              color: red;
              &:before {
                border: 1px solid red;
              }
            }
          }
          
        }
      }
    }
  }
}
</style>