import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    all: [],
    oppositeAll: [],
    oppositePage: null
  },
  mutations: {
    setRoutes(state, routes) {
      state.all = routes;
    },
    setOppositeRoutes(state, routes) {
      state.oppositeAll = routes;
    },
    setOppositePage(state, page) {
      state.oppositePage = page;
    }
  },
  actions: {
    getRoutes({ commit }, language) {
      (new APIService).getRoutes(language == 'de' ? 'en' : 'de').then( (response) => {
        commit('setOppositeRoutes', response.data);
      })
      return new Promise((resolve) => {
        (new APIService).getRoutes(language).then( (response) => {
          commit('setRoutes', response.data);
          resolve();
        })
      });
    },
    setOppositePage({ commit, state }, id) {
      return new Promise((resolve) => {
        const page = state.oppositeAll.find(data => data.id == id);
        if(page) {
          commit('setOppositePage', page);
        }
        resolve();
      })
    }
  }
}