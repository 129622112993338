<template>
  <div>
    <div v-if="pdf" class="publicationContent">
      <!-- <div class="back wrapper" >
        <router-link :to="language == 'en' ? '/en/topics/' : '/themen/'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          <span>{{ $root.translate('publications_back') }}</span>
        </router-link>
      </div> -->
      <dynamic-component v-for="(element, comp) in elements" :key="'s' + comp" :comp="element.component" :data="element.data" :post="pdf"></dynamic-component>
      <p-d-f-viewer v-if="file" :data="{ file: file.data.url }"></p-d-f-viewer>
      <related-news :data="{ categories: [] }"></related-news>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DynamicComponent from '../components/elements/DynamicComponent'
import titleMixin from '../mixins/titleMixin';
import metaMixin from '../mixins/metaMixin'
import PDFViewer from '@/components/elements/PDFViewer.vue';
import RelatedNews from '@/components/elements/RelatedNews.vue';

export default {
  name: 'PDFDetail',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.pdf ? 'NUNATAK - ' + this.pdf.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.pdf) {
      const desc = this.pdf.acf.find(page => page.component === 'description');
      const keywords = this.pdf.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null,
      }
    }
  },
  computed: { 
    ...mapState({
      pdf: state => state.pdf.pdf,
      page: state => state.page.page,
      language: state => state.language.language
    }),
    file() {
      return this.pdf.acf.find((entry) => entry.component == 'file')
    },
    elements() {
      return this.pdf && this.pdf.acf.filter((c) => c && typeof c.component !== 'undefined');
    },
    localizedPublications() {
      let shouldMatchLang = (url) => {
        let lang = this.language;
        const ifEN = url.indexOf('/en') == 0 && lang == 'en';
        const ifDE = url.indexOf('/en') != 0 && lang == 'de';

        return ifEN || ifDE;
      }
      let publications = this.allPublications ? this.allPublications.filter((route) => shouldMatchLang(route.url) ) : null;
      return publications;
    },
    firstPublication(){
      const pub = this.localizedPublications ? this.localizedPublications[0] : null;
      return pub;
    } 
  },
  components: {
    DynamicComponent,
    PDFViewer,
    RelatedNews
},
  watch: {
    $route(to) {
      if (to.path.startsWith('/pdf') || to.path.startsWith('/en/pdf')) {
        this.init();
      }
    }
  },
  serverPrefetch() {
    this.init();
  },
  asyncData({store, route}) {
    return store.dispatch('pdf/getPDF', {
      slug: route.params.slug,
      language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      return new Promise((resolve) => {
        const lang = (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de';
        this.$store.dispatch('page/getPage', {
          slug: lang == 'en' ? 'topics' : 'themen',
          language: lang
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('pdf/getPDF', {
            slug: this.$route.params.slug,
            language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
          })
          .then(() => {
            resolve()
            this.$root.loading = false;
          })
          .catch((e) => {
            this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
            this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
            console.log(e);
            resolve();
          });
          this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de')
        })
      });
    }
  }
}
</script>

<style lang="scss">
@import '../styles/config.scss';
.publicationContent {
  padding-top: 40px;
  margin-bottom: 150px;
}

.back {
    margin-top: 28px;
    margin-bottom: 50px;
    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
      left: -2vw;
    }
    a {
      color: $color;
      text-decoration: none;
      transition: color 500ms ease;
      svg {
        transition: transform 500ms ease;
          * {
            transition: fill 500ms ease;
          }
        }
      &:hover {
        color: $primary;
        svg {
          transform: translate(-5px) rotate(180deg);
          * {
            fill: $primary;
          }
        }
      }
    }
    @media screen and (max-width: $large) {
      svg {
        left: 20px;
      }
      padding: 0 0 0 60px;
    }
  }

</style>