<template>
  <div class="posts" v-if="page">
    <stage v-if="stageData" :data="stageData" :post="page"></stage>
    <div class="wrapper">
      <div class="publicationsFilter">
        <h5>{{ $root.translate('publications_filter') }}</h5>
        <nav>
          <ul>
            <li><a href="#all" :class="{ active: filterByCategories.length == 0}" @click="removeFilter()">{{ $root.translate('all_publications')}}</a></li>
            <li v-for="(category, index) in categories" :key="index"><a :href="`#${category.slug}`" :class="{ active: filterByCategories.length && filterByCategories.find(filterByCategory => filterByCategory.id == category.id)}" @click="toggleFilter(category)">{{category.name}}</a></li>
          </ul>
        </nav>
      </div>
      <div class="publicationsHighlighted" :style="{ backgroundColor: this.highlightBackground, color: this.highlightTextColor }" v-if="highlightedPost && showList">
        <div class="left">
          <div>
            <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/` + highlightedPost.slug">
              <lazy-image @imageLoaded="getAverageRGB" :image="highlightedPost.acf[0].data" :refreshCount="refreshCount"></lazy-image>
            </router-link>
          </div>
        </div>
        <div class="right">
          <span class="date">{{ formatDate(highlightedPost.date) }} <span class="category"><a v-for="cat in getCategory(highlightedPost['publication-categories'])" :key="cat.id" :href="`#${cat.slug}`" @click="filter([cat])">{{ cat.name }}</a></span></span>
          <h4 v-html="highlightedPost.title.rendered"></h4>
          
          <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${highlightedPost.slug}`" class="arrowLink white">
            {{ $root.translate('to_publication') }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
          </router-link>

          <div v-if="isWhitepaper(highlightedPost)" class="whitepaperIcon">
            <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Ebene_1" x="0" y="0" style="enable-background:new 0 0 64 64" version="1.1" viewBox="0 0 64 64"><path d="M50 18v36H14V10h28z" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /><path d="M40.8 10v9.2H50M32 38.8V22M38 34l-6 6-6-6M42 40v6H22v-6" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /></svg>
          </div>
        </div>
        
      </div>
      <div class="publicationsEntries" v-if="showList">
        <div class="publicationsEntriesContainer">
          <div class="item" v-for="(item) in otherPosts" :key="item.id">
              <figure>
                <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`">
                  <div>
                      <lazy-image :refreshCount="refreshCount" :image="item.acf[0].data"></lazy-image>
                  </div>
                </router-link>
                <figcaption>
                  <div v-if="isWhitepaper(item)" class="whitepaperIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Ebene_1" x="0" y="0" style="enable-background:new 0 0 64 64" version="1.1" viewBox="0 0 64 64"><path d="M50 18v36H14V10h28z" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /><path d="M40.8 10v9.2H50M32 38.8V22M38 34l-6 6-6-6M42 40v6H22v-6" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /></svg>
                  </div>
                  <div>
                    <span class="date">{{ formatDate(item.date) }}</span>
                    <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`"><span class="title" v-html="item.title.rendered"></span></router-link>
                  </div>
                  <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`" class="arrowLink">
                    {{ $root.translate('to_publication') }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                  </router-link>
                </figcaption>
              </figure>
          </div>
          <div class="furtherButton" v-if="!showFurther && furtherPosts">
            <primary-button @click="toggleFurther()">{{ $root.translate('more_publications') }}</primary-button>
          </div>
          <div class="furtherEntries" :class="{ show: showFurther }">
            <div class="item" v-for="(item) in furtherPosts" :key="item.id">
              
              <figure>
                <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`">
                  <div>
                      <lazy-image :refreshCount="refreshCount" :image="item.acf[0].data"></lazy-image>
                  </div>
                </router-link>
                <figcaption>
                  <div v-if="isWhitepaper(item)" class="whitepaperIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Ebene_1" x="0" y="0" style="enable-background:new 0 0 64 64" version="1.1" viewBox="0 0 64 64"><path d="M50 18v36H14V10h28z" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /><path d="M40.8 10v9.2H50M32 38.8V22M38 34l-6 6-6-6M42 40v6H22v-6" style="fill:none;stroke:#000;stroke-width:2.126;stroke-linecap:square" /></svg>
                  </div>
                  <div>
                    <span class="date">{{ formatDate(item.date) }}</span>
                    <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`"><span class="title" v-html="item.title.rendered"></span></router-link>
                  </div>
                  <router-link :to="(language == 'en' ? '/en/topics' : '/themen') + `/${item.slug}`" class="arrowLink">
                    {{ $root.translate('to_publication') }}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                  </router-link>
                </figcaption>
              </figure>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Stage from '../../components/elements/Stage';
import PrimaryButton from '../../components/elements/PrimaryButton';
import titleMixin from '../../mixins/titleMixin';
import metaMixin from '../../mixins/metaMixin';
import LazyImage from './../../components/elements/LazyImage';

export default {
  name: 'PublicationsIndex',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.page ? 'NUNATAK - Topics' : 'NUNATAK';
  },
  meta() {
    if(this.page) {
      const desc = this.page.acf.find(page => page.component === 'description');
      const keywords = this.page.acf.find(page => page.component === 'keywords');
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null
      }
    }
  },
  data() {
    return {
      filterByCategories: [],
      showFurther: false,
      refreshCount: 0,
      showList: true,
      highlightBackground: 'rgb(255,255,255)',
      highlightTextColor: '#ffffff'
    }
  },
  computed: { 
    ...mapState({
      publications: state => state.publication.posts,
      page: state => state.page.page,
      categories: state => state.category.publicationCategories?.filter(c => c.id !== 1039 && c.id !== 1041),
      language: state => state.language.language
    }),
    stageData() {
      return this.page && this.page.acf.length && this.page.acf[0] && this.page.acf[0].data ? this.page.acf[0].data : null;
    },
    filteredPosts() {
      if(this.publications && this.filterByCategories.length) {
        return this.publications.filter( post => post['publication-categories'].find(catId => this.filterByCategories.map(c => c.id).includes(catId)) );
      }
      else {
        return this.publications;
      }
    },
    sortedPosts() {
      return this.filteredPosts ? this.filteredPosts.slice(0).sort((a, b) => {
        if (!(a['publication-categories'].includes(1039) || a['publication-categories'].includes(1041)) && (b['publication-categories'].includes(1039) || b['publication-categories'].includes(1041))) {
          return 1;
        }
        else if((a['publication-categories'].includes(1039) || a['publication-categories'].includes(1041)) && !(b['publication-categories'].includes(1039) || b['publication-categories'].includes(1041))) {
          return -1;
        }
        else {
          return 0;
        }
      }) : null;
    },
    highlightedPost() {
      if(this.filterByCategories.length === 0 && this.sortedPosts && this.sortedPosts.length && (this.sortedPosts[0]['publication-categories'].includes(1039) || this.sortedPosts[0]['publication-categories'].includes(1041))) {
        return this.sortedPosts[0];
      }
      return null;
    },
    otherPosts() {
      if(this.sortedPosts && this.sortedPosts.length && this.highlightedPost) {
        return this.sortedPosts.slice(1, 13);
      } else if (this.sortedPosts) {
        return this.sortedPosts.slice(0, 12);
      }
      else {
        return null;
      }
    },
    furtherPosts() {
      if (this.sortedPosts && this.sortedPosts.length > 12) {
        return this.sortedPosts.slice(13);
      }
      else {
        return null;
      }
    },
  },
  watch: {
    filterByCategories() {
      // refresh list
      this.showList = false;
      setTimeout(() => this.showList = true, 50);
    }
  },
  components: {
    Stage,
    PrimaryButton,
    LazyImage
  },
  serverPrefetch() {
    this.init();
  },
  mounted() {    
    this.init();
  },
  methods: {
    isWhitepaper(post) {
      return post['publication-categories'].includes(1029) || post['publication-categories'].includes(1037)
    },
    toggleFurther() {
      this.showFurther = true;
    },
    init() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('page/getPage', {
          slug: 'news',
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          this.$store.dispatch('routes/setOppositePage', this.page.translations[(this.page.lang == 'en') ? 'de' : 'en']);
          this.$store.dispatch('language/setCurrentLanguage', this.page.lang)
          .then(() => {
            this.$store.dispatch('category/getPublicationCategories').then(() => {
              this.$nextTick(() => this.checkURL());
            });
            this.$store.dispatch('publication/getPublications', { language: this.language });
            resolve();
            this.$root.loading = false;
          })
          .catch(() => reject())
        }).catch(() => reject());
      })
      
    },
    removeFilter() {
      this.filterByCategories = [];
      this.refreshCount += 1;
    },
    toggleFilter(category) {
      if(this.filterByCategories.includes(category)) {
        this.filterByCategories = this.filterByCategories.filter((c) => c !== category);
      }
      else {
        this.filterByCategories.push(category);
      }
      this.refreshCount += 1;
    },
    filter(categories) {
      this.filterByCategories = categories;
      this.refreshCount += 1;
    },
    checkURL() {
      if(this.$route.hash) {
        const categories = this.categories.filter(c => this.$route.hash.slice(1).trim().split(',').includes(c.slug));
        if(categories.length) {
          this.filter(categories);
        }
      }
    },
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    getCategory(ids) {
      if(!this.categories || !this.ids) return null;
      return this.categories.filter((category) => ids.includes(category.id) );
    },
    getAverageRGB(imgEl) {
      imgEl.crossOrigin = "Anonymous";
      var blockSize = 5, // only visit every 5 pixels
          defaultRGB = 'rgb(0,0,0)', // for non-supporting envs
          canvas = document.createElement('canvas'),
          context = canvas.getContext && canvas.getContext('2d'),
          data, width, height,
          i = -4,
          length,
          rgb = {r:0,g:0,b:0},
          count = 0;

      if (!context) {
          return defaultRGB;
      }
      
      height = canvas.height = ~~((imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height) / 10);
      width = canvas.width = ~~((imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width) / 10);

      // document.body.appendChild(canvas);
      context.drawImage(imgEl, 0, 0, width, height);

      try {
          data = context.getImageData(0, 0, width, height);
      } catch(e) {
          /* security error, img on diff domain */
          return defaultRGB;
      }

      length = data.data.length;
      while ( (i += blockSize * 4 * 10) < length ) {
          ++count;
          rgb.r += data.data[i];
          rgb.g += data.data[i+1];
          rgb.b += data.data[i+2];
      }
      // ~~ used to floor values
      rgb.r = ~~(rgb.r/count);
      rgb.g = ~~(rgb.g/count);
      rgb.b = ~~(rgb.b/count);

      this.highlightTextColor = (rgb.r + rgb.g + rgb.b) / (255*3) > 0.65 ? '#363e48' : '#ffffff'
      this.highlightBackground = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.posts {
  min-height: calc(100vh - 80px);
}
.publicationsFilter {
  padding: 40px 0 0 0;
  margin-bottom: 85px;
  @media screen and (max-width: $medium) {
    margin-bottom: 50px;
  }
  h5 {
    margin-bottom: 35px;
  }
  nav {
    margin-bottom: 100px;
    @media screen and (max-width: $medium) {
      margin-bottom: 80px;
    }
    ul {
      display: flex;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      
      li {
        display: block;
        margin-right: 15px;
        a {
          color: $color;
          text-align: center;
          text-decoration: none;
          position: relative;
          display: block;
          white-space: nowrap;
          width: 150px;
          line-height: 30px;
          border-radius: 15px;
          background: #d9d9d9;
          font-size: 20px;
          transition: background-color 500ms ease;
          margin-bottom: 15px;
          @media screen and (max-width: $small) {
            width: auto;
            padding: 0 15px;
            max-width: 120px;
            font-size: 15px;
          }
          &.active {
            background: $primary;
          }
          &:hover {
            &:after {
              width: 100%
            }
          }
        }
      }
    }
  }
}
.publicationsHighlighted {
  display: flex;
  margin-bottom: 130px;
  min-height: 362px;
  background: #4f1e87;
  align-items: flex-end;
  position: relative;

  @media screen and (max-width: $small) {
    display: block; 
    margin-bottom: 80px;
  }
  .left {
    min-width: 33.333%;
    position: relative;
    overflow: hidden;
    min-height: 362px;
    @media screen and (max-width: $small) {
      min-height: 300px;
    }
    > div {
      overflow: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      background: $primary;
      @media screen and (max-width: $small) {
        width: auto;
        position: relative;
        height: auto;
        overflow: hidden;
        &:after {
          content: '';
          display: block;
          padding-top: 80%;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media screen and (max-width: $small) {
          //height: 100%;
          position: absolute
        }
      }
    }
  }
  .right {
    min-width: 66.666%;
    padding: 50px 80px 50px;
    box-sizing: border-box;
    @media screen and (max-width: $small) {
      padding-right: 0;
      margin-bottom: 40px;
      padding: 20px 30px;
    }
    h5 {
      margin: 0 0 1em;
    }
    
    .whitepaperIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 76px;
      height: 76px;
      border-radius: 50%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 50px;
      }
    }
  }

  .date {
    letter-spacing: 0.135em;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin: 8px 0 15px;
    .category {
      color: $primary;
      display: inline-block;
      margin-left: 5px;
      a {
        text-decoration: none;
      }
    }
  }

  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: normal;
    margin: 0 0 30px;
    font-weight: 300;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .intro {
    font-size: 20px;
    line-height: 28px;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;
     @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    }
  }
}
.publicationsEntries {
  .publicationsEntriesContainer {
    display: flex; 
    flex-wrap: wrap;
    margin-bottom: 210px;
    @media screen and (max-width: $small) {
      margin-bottom: 210px;
    }

    .furtherButton {
      display: block;
      min-width: 100%;
    }
    .furtherEntries {
      flex-wrap: wrap;
      display: none;
      &.show {
        display: flex;
      }
    }
    .item {
      width: 33.33%;
      margin-bottom: 100px;
      @media screen and (max-width: $small) {
        width: 100%;
        margin-bottom: 50px;
      }
      a {
        text-decoration: none;
      }
      figure {
        margin: 0;
        padding: 0;
        display: flex;
        min-height: 100%;
        flex-direction: column;
        .ie & {
          display: block;
        }
        > a > div {
          position: relative;
          overflow: hidden;
          margin: 0 20px 0 0;
          background: $primary;
          @media screen and (max-width: $small) {
            margin: 0;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transition: transform 500ms ease;
            transform: translate(-50%) scale(1);
            height: 100%;
          }
          &:after {
            content: '';
            display: block;
            padding-top: 56.5%;
          }
        }
        figcaption {
          position: relative;
          text-decoration: none;
          color: $color;
          flex: 1;
          //min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .whitepaperIcon {
            position: absolute;
            top: 0px;
            transform: translate(0,-50%);
            right: 30px;
            width: 76px;
            height: 76px;
            border-radius: 50%;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              width: 50px;
            }
          }
          .date {
            letter-spacing: 0.135em;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin: 8px 0 15px;
            .category {
              color: $primary;
              display: inline-block;
              //margin-left: 20px;
              a {
                display: inline-block;
                margin: 0 5px;
              }
            }
          }

          .title {
            font-size: 27px;
            line-height: 35px;
            display: block;
            margin-bottom: 15px;
            max-width: 90%;
            color: $color;
            //min-height: 200px;
            @media screen and (max-width: $small) {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
      &:hover {
        figure {
          > a > div {
            img {
              transform: translate(-50%) scale(1.1);
            }
          } 
        }
      }
    }
  }
}
</style>