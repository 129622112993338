import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    posts: null,
    post: null
  },
  mutations: {
    setPosts(state, post) {
      state.posts = post
    },
    setPost(state, post) {
      state.post = post
    }
  },
  actions: {
    getPosts({ commit }, { language }) {
      return new Promise((resolve, reject)  => {
        (new APIService).getPosts('posts', language).then( (post) => {
          let posts = [];
          post.data.forEach((p) => {
            const elements = [];
            Object.keys(p.acf).forEach((key) => {
              let data = p.acf[key];
              if(p.acf[key].length == 1 && Object.keys(p.acf[key][0])[0] === key) {
                data = p.acf[key][0][key];
              }

              if(key === 'content_container') {
                return true;
              }
              if(key === 'blog_content') {
                key = 'content_container';
                data = p.acf['blog_content'].length ? p.acf['blog_content'][0]['content_container'] : null
              }
              elements.push({
                component: key,
                data: data
              })
            });
            p.acf = elements;
            posts.push(p)
          })
          
          commit('setPosts', posts);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    },
    getPost({ commit }, { slug, language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPostBySlug(slug, language).then( (post) => {
          let p = post.data[0];
          const elements = [];
          (new APIService).getCategories(post.data[0].categories).then((categories) => {
            p.categories = categories.data;
            Object.keys(p.acf).forEach((key) => {
              let data = p.acf[key];
              if(p.acf[key].length == 1 && Object.keys(p.acf[key][0])[0] === key) {
                data = p.acf[key][0][key];
              }
              if(key === 'content_container') {
                return true;
              }
              if(key === 'blog_content') {
                key = 'content_container';
                data = p.acf['blog_content'].length ? p.acf['blog_content'][0]['content_container'] : null
              }
              elements.push({
                component: key,
                data: data
              })
            });
            elements.push({
              component: 'related-news',
              data: {
                categories: p.categories
              }
            })
            elements.push({
              component: 'newsletter',
              data: {
              }
            })
            p.acf = elements;
            resolve(p);
            commit('setPost', p)
            commit('page/loading', false);
          });
        }).catch(e => reject(e));
      })
      
    }
  }
}