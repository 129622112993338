<template>
<div class="textSplit wrapper">
  <div class="textSplitContainer">
    <div class="right">
      <h5>{{ data.right.title }}</h5>
      <div v-html="data.right.text"></div>
    </div>
    <div class="left">
      <h5>{{ data.left.title }}</h5>
      <div v-html="data.left.text"></div>
    </div>
  </div>
  <div class="textSplitLink" v-if="data.link && data.link.url">
    <a :href="data.link.url" @click="pathTo(data.link.url, $event)" class="arrowLink">
      {{ data.link.title }}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
    </a>
  </div>
</div>
  
  
</template>

<script>
import { toLink, getCoords } from '../../helpers/NavigationService';
export default {
  name: 'TextSplit',
  props: ['data'],
  methods: {
    pathTo(path, $event) {
       $event.preventDefault();
      if(path.indexOf('#') === 0) {
        const moduleTop = document.querySelector(path) ? getCoords(document.querySelector(path)).top : 0;
        window.scrollTo(0, moduleTop)
        return true;
      }
     
      const link = toLink(path);
      if(link.type === 'intern') {
        this.$router.push(link.link);
      }
      else {
        window.open(link.link);
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.textSplit {
  margin-bottom: 130px !important; 
  @media screen and (max-width: $medium) {
    margin-bottom: 100px !important;
  }
  .textSplitContainer {
    display: flex;
    @media screen and (max-width: $medium) {
      display: block;
    }
    .right,
    .left {
      flex: 1; 
      div {
        font-size: 27px;
        line-height: 39px;
        width: 90%;
        @media screen and (max-width: $medium) {
          margin-bottom: 2em;
        }
        @media screen and (max-width: $small) {
          font-size: 20px;
          line-height: 25px;
        }
      }
  }
  
  }
  .textSplitLinks {
    a {
      font-size: 15px;
      line-height: 1.2em;
      @media screen and (max-width: $medium) {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>