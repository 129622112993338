<template>
  <div id="projectreferences" class="projectReferenceTeaser projectReferenceMountain" v-if="project">
    <div class="projectReferenceMountainArea">
      <div class="wrapper">
        <h5>{{ data.title }}</h5>
        <h4 v-html="project.title.rendered"></h4>
        <router-link :to="pathTo(project.slug)" class="arrowLink">
          {{ $root.translate('to_project')}}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
        </router-link>
      </div>
      <div class="mountain" id="projectReferenceMountain">
        <img src="./../../assets/images/nunatak-mountain-project-reference.png" alt="Nunatak Mountains for projects" />
      </div>
      <div class="processOverlay">
        <div class="processPoint" v-if="project.acf.project_process[0]">
          <router-link :to="pathTo(project.slug)">
            {{ $root.translate('process_step_1') }}
          </router-link>
          <div class="processPoint__content" v-html="project.acf.project_process[0].content"></div>
        </div>
        <div class="processPoint" v-if="project.acf.project_process[1]">
          <router-link :to="pathTo(project.slug)">
            {{ $root.translate('process_step_2') }}
          </router-link>
          <div class="processPoint__content" v-html="project.acf.project_process[1].content"></div>
        </div>
        <div class="processPoint" v-if="project.acf.project_process[2]">
          <router-link :to="pathTo(project.slug)">
            {{ $root.translate('process_step_3') }}
          </router-link>
          <div class="processPoint__content"  v-html="project.acf.project_process[2].content"></div>
        </div>
         <div class="processWay"></div>
      </div>
    </div>
    <div class="wrapper">
      <div class="bottom-button">
        <primary-button :to="pathTo(project.slug)">{{ $root.translate('all_projects')}}</primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { pathTranslate } from '../../helpers/NavigationService';
import PrimaryButton from './PrimaryButton';
export default {
  name: 'projectReference',
  props: ['data', 'post'],
  components: {
    PrimaryButton
  },
  data() {
    return {
      project: null,
      showContent: 0
    }
  },
  computed: { ...mapState({
      expertises: state => state.expertise.all,
      projects: state => state.project.projects,
      language: state => state.language.language
    }),
    isAcademy() {
      return !!this.post.academyTheme;
    },
    filteredProjects() {
      return this.projects.filter((project) => project.acf.is_an_academy_project === this.isAcademy);
    },
  },
  watch: {
    language() {
      this.$store.dispatch('project/getProjects', { language: this.language }).then(() => {
        this.project = this.filteredProjects[Math.floor(Math.random() * this.filteredProjects.length)];
      });
    }
  },
  mounted() {
    this.$store.dispatch('project/getProjects', { language: this.language }).then(() => {
      this.project = this.filteredProjects[Math.floor(Math.random() * this.filteredProjects.length)];
    });
  },
  methods: {
    pathTo(projectName) {
      return pathTranslate('project', this.language) + projectName;
    },
    toggleContent(index) {
      if(window.innerWidth > 768) {
        if(this.showContent === index) {
          this.showContent = 0;
        }
        else {
          this.showContent = index;
        }
      }
      else {
        this.$router.push(this.pathTo(this.project.slug));
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.projectReferenceTeaser {
  margin-bottom: 130px;
  position: relative;
  .processOverlay {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    //max-width: 1600px;
    min-width: 1000px;
    
    @media screen and (max-width: 1000px) {
      left: 30%;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 42%;
    }
    .processPoint {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      transition: transform 500ms ease, background-color 500ms ease, padding 500ms ease;
      z-index: 12;
      a {
        color: white;
        padding: 5px 20px;
        background: $color;
        text-decoration: none;
        transition: background-color 500ms ease;
        font-size: 17px;
        line-height: 30px;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
        }
        &.active {
          background-color: $primary !important;
          color: $color;
          .academy & {
            background-color: $academy !important;
            color: white;
          }
        }
      }

      &__content {
        position: absolute;
        top: calc(100% - 1px);
        background: $primary;
        color: $color;
        opacity: 0;
        width: 450px;
        padding: 20px 20px 0;
        box-sizing: border-box;
        transition: opacity 500ms ease;
        pointer-events: none;
        .academy & {
          background: $academy;
          color: white;
        }
        &.active {
          opacity: 1;
        }
        ul,
        p {
          margin: 0 0 20px;
          font-size: 15px;
          line-height: 24px;
          @media screen and (max-width: $small) {
            font-size: 15px;
            line-height: 24px;
          }
        }
        ul {
          padding: 0 0 0 1em;
        }
      }

      @media screen and (min-width: $small) {
        &:hover {
          transform: translate(-50%, -50%) scale(1.2) ;
          .processPoint__content {
            opacity: 1;
          }
          a {
            background-color: $primary !important;
            color: $color;
          }
          .academy & {
            a {
              background-color: $academy !important;
              color: white;
            }
          }
        }
      }
      
      &:nth-child(1) {
        transform: translate(8vw, 7vw);
        @media screen and (min-width: 1000px) {
          &:hover {
            transform: translate(8vw, 7vw) scale(1.1);
          }
        }
      }
      &:nth-child(2) {
        transform: translate(10vw, 0vw);
        @media screen and (min-width: 1000px) {
          &:hover {
            transform: translate(10vw, 0vw) scale(1.1);
          }
        }
      }
      &:nth-child(3) {
        transform: translate(12.5vw, -10.5vw);
        @media screen and (min-width: 1000px) {
          &:hover {
            transform: translate(12.5vw, -10.5vw) scale(1.1);
          }
        }
      }
      
      @media screen and (max-width: 1000px) {
        &:nth-child(1) {
          transform: translate(80px, 60px);
        }
        &:nth-child(2) {
          transform: translate(100px, 10px)
        }
        &:nth-child(3) {
          transform: translate(125px, -120px);
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        background: white;
        width: 11px;
        height: 11px;
        box-shadow: 0 0 15px 5px lighten($primary, 5%);
        border: 1px solid $primary;
        border-radius: 50%;
        transform: scale(1) translate(-50%, -50%);
        transition: transform 500ms ease;
        z-index: 2;
        .academy & {
          border: 1px solid $academy;
          box-shadow: 0 0 15px 5px lighten($academy, 10%);
        }
      }
      &.active {
        &:before {
          transform: scale(1.2) translate(-50%, -50%);
        }
      }
    }
    .processWay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      width: 11px;
      height: 11px;
      box-shadow: 0 0 50px $primary;
      border: 1px solid $primary;
      border-radius: 50%;
    }
  }

  .bottom-button {
    bottom: 80px;
    position: absolute;
    transform: translate(0, 50%);
  }
  > .wrapper { 
    position: static;
  }
}
  
  .projectReferenceMountainArea {
    position: relative;
    #projectReferenceMountain { 
      background: white;
      position: relative;
      bottom: 80px;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      min-width: 1000px;
      @media screen and (max-width: 1000px) {
        left: 30%;
      }
      &:before {
        content: '';
        display: block;
        padding-top: 42%;

      }
      img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        //max-width: 1600px;
        width: 100%;
      }
      canvas {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .wrapper {
      z-index: 10;
      h4 {
        font-weight: 300;
        font-size: 38px;
        line-height: 50px;
        padding: 0.1em 0 0 0; 
        margin: 0 0 0.75em;
        max-width: 1000px;
        @media screen and (max-width: $medium) { 
          font-size: 30px;
          line-height: 38px;
        }
        @media screen and (max-width: $small) {
          font-size: 25px;
          line-height: 30px;
        }
      }
    }
  }
</style>