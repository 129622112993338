<template>
  <div class="contactTeaser" v-if="person">
    <div class="wrapper">
      <div class="content">
        <h5>{{ data.title }}</h5>
        <h4>{{ data.subline }}</h4>
        <div class="contact" v-if="person">
          <span class="name">{{ person.title.rendered }}</span>
          <span class="position">{{ person.acf.team_member.position }}</span>
          <a class="phone" :href="`tel:${person.acf.team_member.phone}`">{{ person.acf.team_member.phone }}</a>
          <a class="email" href="" @click.prevent="mailTo(encodeEmail(person.acf.team_member.email))">{{ person.acf.team_member.email }}</a>
        </div>
      </div>
      <div class="image" v-if="person">
        <lazy-image :image="person.acf.team_member.image"></lazy-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LazyImage from './LazyImage';
import { emailMixin } from '../../mixins/emailMixin';

export default {
  name: 'ConactTeaser',
  props: ['data'],
  mixins: [ emailMixin ],
  components: {
    LazyImage
  },
  data() {
    return {
      contactEmail: ''
    }
  },
  computed: {
    ...mapState({
      members: state => state.team.members
    }),
    person() {
      return this.members ? this.members.filter((member) => member.id === this.data.person.ID )[0] : null;
    }
  },
  mounted() {
    if(!this.members) {
      this.$store.dispatch('team/getAll').then(() => {
      })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.contactTeaser {
  position: relative;
  margin-bottom: 210px;
  @media screen and (max-width: $small) {
    margin-bottom: 80px;
  }
  h5 {
    margin: 0 0 0.5em;
    .academy & {
      color: white;
    }
  }
  h4 {
    font-size: 38px;
    line-height: 47px;
    margin: 0 0 1em;
    font-weight: 300;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .wrapper {
    display: flex;
    box-sizing: border-box;
    @media screen and (max-width: $medium) {
      flex-direction: column-reverse;
      align-items: center;
      .image {
        max-width: 400px;
        img {
          width: 100%;
        }
      }
    }
    img {
      max-width: 698px;
    }
    > * {
      flex: 1;
      position: relative;
      z-index: 10;
      color: white;
      
    }
    .content {
      padding: 40px 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: $medium) {
        padding: 40px 0 80px;
      }
      @media screen and (max-width: $small) {
        display: block;
        padding: 60px 0;
        width: 100%;
      }

      a,
      span {
        display: block;
        color: white;
        text-decoration: none;
        &.name {
          font-size: 27px;
          @media screen and (max-width: $small) {
            font-size: 20px;
          }
        }
        &.position {
          font-size: 18px;
          margin: 0 0 40px;
          @media screen and (max-width: $small) {
            font-size: 15px;
            margin: 0 0 20px;
          }
        }
        &.phone {
          font-size: 40px;
          white-space: nowrap;
          @media screen and (max-width: $small) {
            font-size: 25px;
          }
        }
        &.email {
          font-size: 18px;
          transition: color 500ms ease;
          &:hover {
            color: $primary;
          }
          @media screen and (max-width: $small) {
            font-size: 15px;
          }
        }
      }
    }
    &:after {
      content: '';
      display: block;
      width: 100vw;
      background: $color;
      top: 43px;
      bottom: 43px;
      position: absolute;
      left: 0;
      @media screen and (max-width: $small) {
        top: 100px;
        bottom: 0;
      }
      .academy & {
        background: $academy;
      }
    }
  }
}
</style>