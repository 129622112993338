export default {
  state: {
    color: false,
    label: ''
  },
  mutations: {
    mouseHovering(state, value) {
      if(value) {
        state.color = value.color ? value.color : null;
        state.label = value.label ? value.label : null;
      }
      else {
        state.color = false;
        state.label = null;
      }
      
    }
  }
}