<template>
  <div class="catchLine">
    <div class="background"></div>
    <div class="wrapper">
      <h5>{{ data.title }}</h5>
      <div class="content">
        {{ data.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatchLine',
  props: ['data']
}
</script>

<style lang="scss">
@import '../../styles/config';
.catchLine {
  margin: 0 0 130px;
  @media screen and (max-width: $small) {
    margin: 0 0 80px;
  }
  .content {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300; 
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
</style>