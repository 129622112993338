import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    languages: null,
    language: null
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages
    },
    setLanguage(state, language) {
      typeof localStorage !== 'undefined' ? localStorage.setItem('language', language) : null;
      state.language = language
    }
  },
  actions: {
    getAll({ commit }) {
      (new APIService).getLanguages().then( ({ data }) => {
        commit('setLanguages', data)
      })
    },
    setCurrentLanguage({ commit }, language) {
      commit('setLanguage', language);
    }
  }
}