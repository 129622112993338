import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    categories: null,
    publicationCategories: null,
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setPublicationCategories(state, categories) {
      state.publicationCategories = categories;
    }
  },
  actions: {
    getCategories({ commit }) {
      return new Promise((resolve, reject)  => {
        (new APIService).getCategories().then( (categories) => {
          commit('setCategories', categories.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    },
    getPublicationCategories({ commit }) {
      return new Promise((resolve, reject)  => {
        (new APIService).getCategories(null, 'publication-categories').then( (categories) => {
          commit('setPublicationCategories', categories.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
      });
    }
  }
}