<template>
  <div class="academyTeaser">
    <div class="wrapper">
      <div class="left">
        <h5>
          <svg  class="academyLogo" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="uuid-d4f10521-e990-434f-b2d7-9b399058eaa9" x="0" y="0" style="enable-background:new 0 0 702.8 186.4" version="1.1" viewBox="0 0 702.8 186.4"><path d="M62.7 62.4 25.6 7.9H8.8v79.6h15.5V33.6l37.2 53.9h16.7V7.9H62.7zM170.6 54.9c0 11.3-6.7 18.8-16.9 18.8-10.3 0-17.1-7.6-17.1-18.9v-47h-15.5V55c0 20.5 12.9 34.2 32.4 34.2 19.6 0 32.7-13.7 32.7-34.2V7.9h-15.5v47h-.1zM282.9 62.4 245.8 7.9H229v79.6h15.5V33.6l37.2 53.9h16.7V7.9h-15.5zM437.1 22.4h23.6v65.1h15.5V22.4h23.7V7.9h-62.8zM665.6 47l26.8-39.1h-17.5l-22.5 32.8h-7.6V7.9h-15.5v79.6h15.5V55.2h8.2l22.4 32.3h18.9zM366.6 7.9l-32 79.6h17.2l23.4-60.4 10.7 27.8h-10.5v14.2h16l7.1 18.4h18.3l-32-79.6zM552.2 7.9l-32 79.6h17.2l23.4-60.4 10.7 27.8h-11v14.2H577l7.1 18.4h18.3L570.3 7.9z" fill="#000D43"/><path d="M262.1 162.6h-23l-5.2 13.3h-5l19.7-48.8h3.9l19.8 48.8h-5.1l-5.1-13.3zm-1.4-3.7-10.1-26-10.1 26h20.2zM295.3 151.4c0-15.7 9.2-24.9 24.5-24.9 10.7 0 18.1 4.4 21.8 13.2l-4.3 1.5c-2.9-7.2-8.7-10.6-17.5-10.6-12.8 0-19.9 7.5-19.9 20.9s7.2 21.1 19.6 21.1c8.4 0 14.7-3.5 17.9-10.9l4.3 1.5c-4 8.8-11.7 13.4-22.3 13.4-15.1-.1-24.1-9.5-24.1-25.2zM398.4 162.6h-23l-5.2 13.3h-5l19.7-48.8h3.9l19.8 48.8h-5.1l-5.1-13.3zm-1.4-3.7-10.1-26-10.1 26H397zM435.8 127.1h14.3c17.4 0 27.3 9 27.3 24.8 0 15.5-9.7 24-27.4 24h-14.2v-48.8zm13.9 44.9c15.2 0 23.2-6.7 23.2-20.3 0-13.8-8-20.8-22.8-20.8h-9.7V172h9.3zM507.2 127.1h35v3.9h-30.5v18.2H539v3.9h-27.3v19h30.5v3.9h-35v-48.9zM622 127.1v48.8h-4.6v-43.3l-18.2 39.3h-4.5l-18.3-39.5v43.5h-4.5v-48.8h6.7l18.4 40 18.4-40h6.6zM669.2 157.3l-20.6-30.2h5.4l17.4 26.1 17.5-26.1h5.4l-20.6 30.2v18.6h-4.5v-18.6z" fill="#a0d0cb"/></svg>
        </h5>
          <h4>{{ data.subline }}</h4>
        <div>
          {{ data.content }}
        </div>
        <a href="#" @click="pathTo(data.link.url, $event)" class="arrowLink">
          {{ data.link.title }}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
        </a>
      </div>
      <div class="right">
        <video-teaser label="View Video" :src="data.image" theme="academyFocus" :youtubeId="data.youtube_id"></video-teaser>
      </div>
    </div>
  </div>
</template>

<script>
import VideoTeaser from './VideoTeaser';
import { toLink } from '../../helpers/NavigationService';

export default {
  name: 'AcademyTeaser',
  props: ['data'],
  components: {
    VideoTeaser
  },
  methods: {
    pathTo(path, $event) {
       $event.preventDefault();
      if(path.indexOf('#') === 0) {
        const moduleTop = document.querySelector(path) ? document.querySelector(path).offsetTop : 0;
        if(typeof window !== 'undefined') window.scrollTo(0, moduleTop)
        return true;
      }
     
      const link = toLink(path);
      if(link.type === 'intern') {
        this.$router.push(link.link);
      }
      else {
        if(typeof window !== 'undefined') window.open(link.link);
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.academyTeaser {
  background: #dbdbdb;
  padding: 80px 0 ;
  width: calc(100% - 80px);
  color: $color;
  overflow: hidden;
  margin-bottom: 130px;
  h5 {
    margin-bottom: 40px;
    svg {
      width: 220px;
      .st1 {
        fill: $color;
      }
      .st0 {
        fill: $academy;
      }
    }
    @media screen and (max-width: $small) {
      margin-bottom: 30px;
      svg {
        width: 150px;
      }
    }
  }
  @media screen and (max-width: $small) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: $medium) {
    width: 100%;
    padding: 50px 0;
  }
  .wrapper {
    transform: translate(40px);
    display: flex;
    @media screen and (max-width: $small) {
      display: block;
      transform: none;
    }
    .left,
    .right {
      flex: 1;
      position: relative;
      max-width: 50%;
      @media screen and (max-width: $small) {
        max-width: none;
      }
      img {
        display: block;
        width: 50vw;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        @media screen and (max-width: $medium) {
          position: static;
          transform: none;
          width: calc(100% - 20px);
          
        }
      }

      @media screen and (max-width: $medium) { 
        .videoTeaser {
          max-width: none;
          min-width: calc(100vw - 20px);
          margin-top: 40px;
        }
      }

    }
    .right {
      &:after {
        content: '';
        display: block;
        padding-top: 50%;
        @media screen and (max-width: $medium) { 
          display: none;
        }
      }
    }
    .left {
      box-sizing: border-box;
      padding-right: 50px;
      font-size: 17px;
      line-height: 30px;
      @media screen and (max-width: $small) { 
        font-size: 15px;
        line-height: 24px;
      }
      div {
        margin-bottom: 40px;
        @media screen and (max-width: $small) { 
          margin-bottom: 20px;
        }
      }
    }
  }
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 1em;
    color: $academy;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $small) { 
      font-size: 25px;
      line-height: 30px;
    }
  }
  a.arrowLink {
    color: $academy;
    font-weight: bold;
    svg {
      width: 22px;
      height: 22px;
      * {
        fill: $academy;
      }
    }
  }
}
</style>