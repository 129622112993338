<template>
  <div class="newsEntries wrapper" v-if="relatedPosts">
    <h5>{{ $root.translate('recommended_news') }}</h5>
    <div class="newsEntriesContainer">
      <div class="item" v-for="(item) in relatedPosts" :key="item.id">
        <router-link :to="(language == 'en' ? '/en' : '') + `/news/${item.slug}`">
          <figure>
            <div>
              <lazy-image :image="item.acf[0].data.header_image" replaceSize="medium_large"></lazy-image>
            </div>
            <figcaption>
              <div>
                <span class="date">{{ formatDate(item.date) }} <span class="category" v-for="categoryId in item.categories" :key="categoryId" ><router-link :to="language === 'en' ? '/en/news/#' + getCategory(categoryId).slug : '/news/#' + getCategory(categoryId).slug"># {{ getCategory(categoryId).name }}</router-link></span></span>
                <span class="title" v-html="item.title.rendered"></span>
              </div>
              <router-link :to="(language == 'en' ? '/en' : '') + '/news/' + item.slug" class="arrowLink">
                {{ $root.translate('read_article') }}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
              </router-link>
            </figcaption>
          </figure>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LazyImage from './LazyImage';
export default {
  name: 'RelatedNews',
  props: ['data'],
  components: {
    LazyImage
  },
  computed: { 
    ...mapState({
      categories: state => state.category.categories,
      language: state => state.language.language,
      posts: state => state.post.posts,
      post: state => state.post.post
    }),
    relatedPosts() {
      if((!this.data.categories || !this.data.categories.length )&& this.posts) return this.posts.slice(0,3);
      return this.posts ? this.posts.filter(post => post.id != this.post.id).filter(post => post.categories.includes(this.data.categories[0].id)).slice(0,3) : null
    }
  },
  mounted() {
    this.$store.dispatch('category/getCategories', { language: this.language }).then(() => {
      this.$store.dispatch('post/getPosts', { language: this.language });
    });
  },
  methods: {
    formatDate(date) {
      let d = new Date(Date.parse(date));
      return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth()+1)).slice(-2) + '.' + d.getFullYear();
    },
    getCategory(id) {
      if(!this.categories) return { slug: null };
      const categories = this.categories.length ? this.categories.filter((cat) => cat.id === id) : [];
      return categories.length ? categories[0] : { slug: null };
    },
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.newsEntries {
  h5 {
    margin-bottom: 40px;
  }
  .newsEntriesContainer {
    display: flex; 
    flex-wrap: wrap;
    .item {
      width: 33.33%;
      margin-bottom: 100px;
      @media screen and (max-width: $medium) {
        width: 50%;
      }
      @media screen and (max-width: $small) {
        width: 100%;
        margin-bottom: 50px;
      }
      a {
        text-decoration: none;
      }
      figure {
        margin: 0;
        padding: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        > div {
          position: relative;
          overflow: hidden;
          margin: 0 20px 0 0;
          @media screen and (max-width: $small) {
            margin: 0;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            height: 100%;
            transition: transform 500ms ease;
          }
          &:after {
            content: '';
            display: block;
            padding-top: 80%;
          }
        }
        figcaption {
          text-decoration: none;
          color: $color;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .date {
            letter-spacing: 0.135em;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin: 8px 0 15px;
            .category {
              color: $primary;
              display: inline-block;
              margin-left: 20px;
            }
          }

          .title {
            font-size: 27px;
            line-height: 35px;
            display: block;
            margin-bottom: 15px;
            max-width: 90%;
            @media screen and (max-width: $small) {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>