<template>
  <div class="ContentYoutubeVideo">
    <iframe v-if="$root.acceptedCookies.includes('youtube')" :src="`https://www.youtube.com/embed/${data.youtube_id}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: 'ContentYoutubeVideo',
  props: ['data']
}
</script>

<style lang="scss">
@use "sass:math";
@import '../../../styles/index.scss';
.ContentYoutubeVideo {
  position: relative;
  margin-bottom: 100px;
  &:after {
    content: '';
    display: block;
    padding-top: math.div(9,16) * 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>