import {createApp} from 'vue'
import { NKCreateStore } from './stores/index'
import App from './App.vue'
import { NKCreateRouter } from './router'


export function NKCreateApp () {

  const router = NKCreateRouter()
  const store = NKCreateStore()

  const app = createApp(App);
  app.use(store);
  app.use(router);
  app.mount('#app');
}

NKCreateApp();



(function () {
  if(typeof window != 'undefined') {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
  
    if (msie > 0 || !!ua.match(/Trident.*rv:11\./)) {
        document.body.classList.add('ie')
    }
  }
})();


