<template>
  <div class="greatTeaser" v-if="post" :class="{ mint: post && post.post_type === 'publication'}">
    <div class="wrapper">
      <div class="content">
        <div>
          <h5 :class="{ white: post.post_type === 'publication'}">{{ firstData.title }}</h5>
          <h4>
            <router-link :to="pathTo(post.post_name)">
              {{ post.post_title }} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </router-link>
          </h4>
          <div v-html="firstData.preview_text"></div>
        </div>
        <primary-button :to="pathTo(post.post_name)" :theme="post.post_type === 'publication' ? 'white' : null">{{ $root.translate('all_publications')}}</primary-button>
      </div>
      <div class="image">
        <router-link :to="pathTo(post.post_name)">
          <lazy-image v-if="postImage" :image="postImage" ></lazy-image>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from '../../helpers/APIService'
import PrimaryButton from './PrimaryButton';
import { pathTranslate } from '../../helpers/NavigationService';
import { mapState } from 'vuex';
import LazyImage from './LazyImage';

export default {
  name: 'GreatTeaser',
  props: ['data'],
  components: {
    PrimaryButton,
    LazyImage
  },
  data() {
    return {
      postImage: null
    }
  },
  computed: {
    ...mapState({
      language: state => state.language.language
    }),
    firstData() {
      return this.data[0]
    },
    post() {
      return this.data[0].post[0]
    }
  },
  mounted() {
    if(this.post) {
      (new APIService).getPublicationBySlug(this.post.post_name).then( response => {
        if (response.data[0]) {
          this.postImage = response.data[0].acf.preview_image;
        }
      })
    }
  },
  methods: {
    pathTo(path) {
      return pathTranslate('publications') + path;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.greatTeaser {
  padding: 80px 0;
  position: relative;
  margin-bottom: 130px;
  @media screen and (max-width: $medium) { 
    padding: 30px 0;
  }
  .wrapper {
    display: flex;
    @media screen and (max-width: $small) {
      display: block;
    }
    .content {
      flex: 1;
      max-width: 590px;
      min-width: 50%;
      padding-right: 100px;
      box-sizing: border-box;
      font-size: 17px;
      line-height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: $small) {
        font-size: 15px;
        line-height: 24px;
      }
      @media screen and (max-width: $medium) {
        min-width: none;
        padding-right: 50px;
      }
      > div {
        width: 100%;
        margin-bottom: 40px;
      }
    }
    .image {
      flex: 1;
      @media screen and (max-width: $medium) {
        margin-top: 50px;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        min-height: 100%;
        transition: transform 500ms ease;
      }
      a {
        display: block;
        overflow: hidden;
        position: relative;
        width: 50vw;
        @media screen and (max-width: $small) {  
          width: 100%;
        }
      
        &:before {
          content: '';
          display: block;
          padding-top: 70%;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  &.mint {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 5vw;
      right: 0;
      bottom: 0;
      top: 0;
      background: $primary;
      @media screen and (max-width: $large) {
        left: 0;
      }
    }
  }
  h4 {
    a {
      font-size: 38px;
      line-height: 40px;
      font-weight: 300;
      margin: 0 0 1em;
      color: $color;
      text-decoration: none;
      transition: color 500ms ease;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) {
        font-size: 25px;
        line-height: 30px;
      }
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        display: inline-block;
        margin-left: 10px;  
        transition: transform 500ms ease;
        * {
          fill: $color;
          transition: fill 500ms ease;
        }
      }
      &:hover {
        color: white;
        svg {
          transform: rotate(0);
          * {
            fill: white;
          }
        }
      }
    }
  }
}
</style>