<template>
  <div class="contentTextImageBox">
    <div class="image">
      <lazy-image :image="data.image"></lazy-image>
    </div>
    <div class="text" v-html="data.text"></div>
  </div>
</template>

<script>
import LazyImage from './../LazyImage';
export default {
  name: 'contentTextImageBox',
  props: ['data'],
  components: {
    LazyImage
  }
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';

.contentTextImageBox {
  margin-bottom: 100px;
  display: flex;
  @media screen and (max-width: $small) {
    margin-bottom: 60px;
    display: block;
  }
  
  > * {
    flex: 1;
    line-height: 32px;
    font-size: 17px;
    @media screen and (max-width: $medium) {
      font-size: 15px;
      line-height: 24px;
    }
    img {
      max-width: 90%;
      display: block;
      @media screen and (max-width: $small) {
        max-width: none;
        width: 100%;
        margin-bottom: 20px;
      }
    }
    p {
      margin: 0 0 1em;
    }
    strong {
      font-size: 22px;
      @media screen and (max-width: $small) {
        font-size: 17px;
      }
    }
  }
}
</style>