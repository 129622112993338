<template>
  <div class="des-mouse-area">
    <div class="des-mouse-area__mouse" :style="{ left, top }" :class="color">
      <span class="des-mouse-area__mouse__pointer" ></span>
      <span class="des-mouse-area__mouse__label">{{label}}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MouseArea',
  data() {
    return {
      x: 0,
      y: 0
    }
  },
  watch: {
    '$route'() {
      this.$store.commit('mouseHovering', null)
    }
  },
  computed: {
    left() {
      return this.x + 'px'
    },
    top() {
      return this.y + 'px'
    },
    ...mapState({
      'label': (state) => state.mouse.label,
      'color': (state) => state.mouse.color ? 'des-mouse-area__mouse--' + state.mouse.color : null
    })
  },
  mounted() {
    this.mousemove();
  },
  methods: {
    mousemove() {
      document.addEventListener('mousemove', (event) => {
        this.x = event.clientX
        this.y = event.clientY
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/index';

  .des-mouse-area {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    @media screen and (max-width: $medium) {
      display: none;
    }
    &__mouse {
      transform: translate(-50%, -50%);
      position: absolute;
      &__pointer {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: transparent;
        &:after {
          content: '';
          width: 77px;
          height: 77px;
          border-radius: 50%;
          background: white;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) scale(0.5);
          opacity: 0;
          transition: transform 700ms cubic-bezier(.39,1.42,.33,.98), opacity 500ms ease;
        }
      }

      &__label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
        color: $primary;
        display: block;
        padding: 0 0 0 1em;
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 300;
      }

      &--white {
        .des-mouse-area__mouse__pointer {
          background: white;
        }
      }
      &--whiteFocus {
        .des-mouse-area__mouse__pointer {
          background: white;
          &:after {
            transform: translate(-50%,-50%) scale(1);
            opacity: 0.5;
          }
        }
        .des-mouse-area__mouse__label {
          color: white;
        }
      }
      &--academyFocus {
        .des-mouse-area__mouse__pointer {
          background: $academy;
          &:after {
            transform: translate(-50%,-50%) scale(1);
            opacity: 0.25;
            background: $academy;
          }
        }
        .des-mouse-area__mouse__label {
          color: $academy;
        }
      }
    }
  }
</style>