<template>
  <div class="contentButton">
    <div class="text" v-html="data.text"></div>
    <primary-button :to="data.button.url">{{ data.button.title }}</primary-button>
  </div>
</template>

<script>
import PrimaryButton from '../PrimaryButton'

export default {
  name: 'ContentButton',
  props: ['data'],
  components: {
    PrimaryButton
  }
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';
.contentButton {
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 100px;
  strong {
    font-size: 22px;
  }
  @media screen and (max-width: $medium) {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 60px;
    strong {
      font-size: 17px;
    }
  }
}
</style>