import { emailDecode, emailEncode } from "../helpers/EmailService"

export const emailMixin = {
  methods: {
    decodeEmail(email) {
      return emailDecode(email);
    },
    encodeEmail(email) {
      return emailEncode(email)
    },
    mailRender(email) {
      return this.encodeEmail(email);
    },
    mailTo(email) {
      if(typeof window != 'undefined') {
        location.href = "mailto:" + this.decodeEmail(email);
      }
    }
  }
}