<template>
  <div v-if="project" :class="{academy: project.acf.is_an_academy_project}">
    <project-reference-mountain :data="project" :projects="projects"></project-reference-mountain>
    <news-slider :data="{ title: 'News '}"></news-slider>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectReferenceMountain from '../components/elements/ProjectReferenceMountain'
import NewsSlider from '../components/elements/NewsSlider'
import titleMixin from '../mixins/titleMixin';
import metaMixin from '../mixins/metaMixin';

export default {
  name: 'Project',
  mixins: [titleMixin, metaMixin],
  title() {
    return this.project ? 'NUNATAK - ' + this.project.title.rendered : 'NUNATAK';
  },
  meta() {
    if(this.project) {
      const desc = this.project.acf.description || '';
      const keywords = this.project.acf.keywords || '';
      return {
        description: desc ? desc.data : '',
        keywords: keywords ? keywords.data : '',
        image: null
      }
    }
  },
  asyncData({store, route}) {
    return new Promise((resolve) => {
      Promise.all(
        [
          store.dispatch('project/getProjects', { language: (route.path.indexOf('/en') === 0) ? 'en' : 'de' }),
          store.dispatch('project/getProject', {
            slug: route.params.slug,
            language: (route.path.indexOf('/en') === 0) ? 'en' : 'de'
          })
        ]
      ).finally(() => resolve());
    }) 
  },
  computed: {...mapState({
      projects: state => state.project.projects,
      project: state => state.project.project,
      language: state => state.language.language
    }),
    routerSlug() {
      return this.$route.params.slug
    }
  },
  components: {
    ProjectReferenceMountain,
    NewsSlider
  },
  watch: {
    '$route'() {
      this.init();
    }
  },
  serverPrefetch() {
    return this.init();
  },
  mounted() {
    return this.init();
  },
  methods: {
    init() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('project/getProjects', { language: this.language });
        this.$store.dispatch('project/getProject', {
          slug: this.$route.params.slug,
          language: (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de'
        }).then(() => {
          if(this.project) {
            this.$store.dispatch('language/setCurrentLanguage', this.project.lang);
            this.$store.dispatch('routes/setOppositePage', this.project.translations[(this.project.lang === 'en') ? 'de' : 'en']);
          }
          else {
            this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
            this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
          }
          resolve();
          this.$root.loading = false;
        })
        .catch(() => {
          this.$store.dispatch('language/setCurrentLanguage', (this.$route.path.indexOf('/en') === 0) ? 'en' : 'de');
          this.$router.replace(((this.$route.path.indexOf('/en') === 0) ? '/en' : '') + '/404');
          reject()
        });
      })
      
    }
  }
}
</script>