<template>
  <div class="loading" :class="{show: show}">

  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: ['show']
}
</script>

<style lang="scss">
@import '../styles/config';
  .loading {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    pointer-events: none;
    z-index: 500;
    transition: transform 1000ms ease;
    background: $color;
    transform: translate(-100%,0);
    &.show {
      opacity: 1;
      transform: translate(0,0)
    }
  }
</style>