import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    menu: {
      meta: null,
      primary: null,
      social: null
    }
  },
  mutations: {
    setMenu(state, {name, menu}) {
      state.menu[name] = menu;
    }
  },
  actions: {
    getMenu({ commit }, { slug, name }) {
      (new APIService).getMenuBySlug(slug).then( (menu) => {
        commit('setMenu',{
          name, 
          menu: menu.data
        });
      })
    }
  }
}