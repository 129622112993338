import APIService from '../helpers/APIService';

export default {
  namespaced: true,
  state: {
    projects: null,
    project: null
  },
  mutations: {
    setProjects(state, posts) {
      state.projects = posts
    },
    setProject(state, post) {
      state.project = post
    }
  },
  actions: {
    getProjects({ commit }, { language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getPosts('projects', language).then( (post) => {
          let p = post.data;
          resolve(p);
          commit('setProjects', p)
        }).catch(e => reject(e))
      })
    },
    getProject({ commit }, { slug, language }) {
      return new Promise((resolve, reject) => {
        (new APIService).getProjectBySlug(slug, language).then( (post) => {
          let p = post.data[0];
          resolve(p)
          commit('setProject', p)
        }).catch(e => reject(e))
      })
    }
  }
}