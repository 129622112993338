<template>
  <div class="wrapper">
    <h5>{{ data.title }}</h5>
    <div class="steps">
      <div class="step" v-for="(step,key) in data.step" :key="key" @touchstart="() => false" @click.prevent="activeIndex = key" :class="{active: activeIndex === key}">
        <div class="top">
          <div class="background">
            <lazy-image :image="step.image" replaceSize="medium_large"></lazy-image>
          </div>
          <span class="number">{{ key+1 }}</span>
          <span class="arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg></span>
          <span class="title">{{ step.title }}</span>
        </div>
        <div class="content" :class="{active: false && activeIndex === key}">
          <div v-html="step.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './LazyImage';
export default {
  name: 'Steps',
  props: ['data'],
  components: {
    LazyImage
  },
  data() {
    return {
      activeIndex: null
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';

.steps {
  display: flex;
  margin: 60px 0 210px;
  @media screen and (max-width: $small) {
    flex-wrap: wrap;
    margin: 30px 0 130px;
  }
  .step {
    flex: 1;
    @media screen and (max-width: $small) {
      flex: none;
      width: 50%;
      margin: 0 0 25px;
    }
    .top {
      position: relative;
      .background {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 80%;
        background: $primary;
        overflow: hidden;
        @media screen and (max-width: $small) {
          width: 80%;
          height: 90%;
        }
        img {
          width: 100%;
          opacity: 0;
          transition: opacity 500ms ease;
          @media screen and (max-width: $small) { 
            opacity: 0.4;
          }
        }
      }
      &:after {
        content: '';
        display: block;
        padding-top: 130%;
      }
      .number {
        position: absolute;
        font-size: 200px;
        top: 0;
        right: 15%;
        transition: font-size 500ms ease, top 500ms ease;
        @media screen and (max-width: $small) {
          font-size: 150px;
          right: 10%;
          top: -5%;
        }
      }
      .arrow {
        position: absolute;
        bottom: 35%;
        left: 25px;
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        transition: transform 500ms ease;
        display: none;
        @media screen and (max-width: $medium) {
          display: block;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        position: absolute;
        font-size: 27px;
        line-height: 34px;
        top: 68%;
        left: 25px;
        right: 10%;
        font-weight: 300;
        @media screen and (max-width: $medium) {
          font-size: 17px;
          line-height: 25px;
        }
        @media screen and (max-width: $small) {
          font-weight: 600;
        }
      }
    }
    @media screen and (max-width: $medium) {
      &.active {
        .top {
          .background {
            img {
              opacity: 0.5;
            }
          }
          .number {
            font-size: 75px;
            top: 15%;
            @media screen and (max-width: $small) { 
              font-size: 150px;
              top: -5%;
            }
          }
          .arrow {
            transform: rotate(90deg);
            @media screen and (max-width: $small) {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .content {
      @media screen and (max-width: $small) {
        display: none;
        margin-top: 20px;
        
        
        &.active {
          display: block;
        }
      }
      ul {
        font-size: 17px;
        line-height: 30px;
        margin: 0;
        padding: 0 30px 0 1.2em;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
        }
        li {
          margin: 0 0 1.5em;
          font-weight: 300;
        }
      }
    } 
    @media screen and (max-width: $small) {
      &:nth-child(even) {
        .content {
          //transform: translate(-50%);
        }
      }
    }

    &:hover {
      .top {
        .background {
          img {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>