<template>
  <div class="academyTriangle">
    <div class="wrapper">
      <div class="academyTriangle__intro">
        <div class="academyTriangle__introHeadline">
          <h5>{{ data.title }}</h5>
          <h3>{{ data.headline }}</h3>
        </div>
        <div class="academyTriangle__introContent">
          <div v-html="data.content"></div>
        </div>
      </div>
      <div class="academyTriangleMountainContainer">
        <svg class="mask" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 86.6">
          <path fill="#fff" d="M0 0L100 0L100 86.6L50 0L0 86.6z"/>
        </svg>
       <svg  class="academyLogo" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="uuid-d4f10521-e990-434f-b2d7-9b399058eaa9" x="0" y="0" style="enable-background:new 0 0 702.8 186.4" version="1.1" viewBox="0 0 702.8 186.4"><path d="M62.7 62.4 25.6 7.9H8.8v79.6h15.5V33.6l37.2 53.9h16.7V7.9H62.7zM170.6 54.9c0 11.3-6.7 18.8-16.9 18.8-10.3 0-17.1-7.6-17.1-18.9v-47h-15.5V55c0 20.5 12.9 34.2 32.4 34.2 19.6 0 32.7-13.7 32.7-34.2V7.9h-15.5v47h-.1zM282.9 62.4 245.8 7.9H229v79.6h15.5V33.6l37.2 53.9h16.7V7.9h-15.5zM437.1 22.4h23.6v65.1h15.5V22.4h23.7V7.9h-62.8zM665.6 47l26.8-39.1h-17.5l-22.5 32.8h-7.6V7.9h-15.5v79.6h15.5V55.2h8.2l22.4 32.3h18.9zM366.6 7.9l-32 79.6h17.2l23.4-60.4 10.7 27.8h-10.5v14.2h16l7.1 18.4h18.3l-32-79.6zM552.2 7.9l-32 79.6h17.2l23.4-60.4 10.7 27.8h-11v14.2H577l7.1 18.4h18.3L570.3 7.9z" fill="#fff"/><path d="M262.1 162.6h-23l-5.2 13.3h-5l19.7-48.8h3.9l19.8 48.8h-5.1l-5.1-13.3zm-1.4-3.7-10.1-26-10.1 26h20.2zM295.3 151.4c0-15.7 9.2-24.9 24.5-24.9 10.7 0 18.1 4.4 21.8 13.2l-4.3 1.5c-2.9-7.2-8.7-10.6-17.5-10.6-12.8 0-19.9 7.5-19.9 20.9s7.2 21.1 19.6 21.1c8.4 0 14.7-3.5 17.9-10.9l4.3 1.5c-4 8.8-11.7 13.4-22.3 13.4-15.1-.1-24.1-9.5-24.1-25.2zM398.4 162.6h-23l-5.2 13.3h-5l19.7-48.8h3.9l19.8 48.8h-5.1l-5.1-13.3zm-1.4-3.7-10.1-26-10.1 26H397zM435.8 127.1h14.3c17.4 0 27.3 9 27.3 24.8 0 15.5-9.7 24-27.4 24h-14.2v-48.8zm13.9 44.9c15.2 0 23.2-6.7 23.2-20.3 0-13.8-8-20.8-22.8-20.8h-9.7V172h9.3zM507.2 127.1h35v3.9h-30.5v18.2H539v3.9h-27.3v19h30.5v3.9h-35v-48.9zM622 127.1v48.8h-4.6v-43.3l-18.2 39.3h-4.5l-18.3-39.5v43.5h-4.5v-48.8h6.7l18.4 40 18.4-40h6.6zM669.2 157.3l-20.6-30.2h5.4l17.4 26.1 17.5-26.1h5.4l-20.6 30.2v18.6h-4.5v-18.6z" fill="#a0d0cb"/></svg>
        <div id="academyTriangleMountain"> 
        </div>
        <div class="academyTriangleTop">
          <a href="#" @click="pathTo('#' + data.top.link_id, 0, $event)" :data-link="data.top.link_id"><small>01</small>{{ data.top.title }}</a>
        </div>
        <div class="academyTriangleLeft">
          <a href="#" @click="pathTo('#' + data.left.link_id, 1, $event)" :data-link="data.left.link_id"><small>02</small>{{ data.left.title }}</a>
        </div>
        <div class="academyTriangleRight">
          <a href="#" @click="pathTo('#' + data.right.link_id, 2, $event)" :data-link="data.right.link_id"><small>03</small>{{ data.right.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toLink, getCoords } from '../../helpers/NavigationService';

let academyTriangleMountain = null;
export default {
  name: 'AcademyTriangle',
  props: ['data'],
  mounted() {
    setTimeout(() => this.addMountains(), 1000);
  },
  beforeUnmount() {
    if (academyTriangleMountain) {
      academyTriangleMountain.destroy();
    }
  },
  methods: {
    addMountains() {
      if (!document.querySelector('#academyTriangleMountain')) {
        return;
      }
      academyTriangleMountain = new window._MOUNTAINS({
        element: '#academyTriangleMountain',
        seed: Math.round(Math.random() * 65500),
        disableUI: true,
        groundLevel: 15,
        screenshot: false,
        backgroundColor: 0x000D43,
        groundColor: 0x000D43,
        lineColor: 0xababab,
        camera: {
            auto: true,
            motion: false,
            position: {
                x: 60,
                y: 30,
                z: 20
            },
            lookAt: {
                x: 0,
                y: 20,
                z: 0
            }
        }
      });

      academyTriangleMountain.run();
      
    },
    pathTo(path, index, $event) {
       $event.preventDefault();
      if(path.indexOf('#') === 0) {
        const moduleTop = document.querySelector(path) ? getCoords(document.querySelector(path)).top -100 : 0;
        if(typeof window !== 'undefined') window.scrollTo(0, moduleTop);
        this.$root.$emit('academyBoxes.active', index);
        return true;
      }
     
      const link = toLink(path);
      if(link.type === 'intern') {
        this.$router.push(link.link);
      }
      else {
        if(typeof window !== 'undefined') window.open(link.link);
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.academyTriangle {

  &__intro {
  display: flex;
  margin-bottom: 165px;
  @media screen and (max-width: $small) {
    display: block;
    margin-bottom: 100px;
  }
  &Headline {
    width: 37%;
    flex: 1;
    @media screen and (max-width: $small) {
      width: auto;
      margin-bottom: 1em;
    }
    h5 {
      margin: 0 0 0.5em;
    }
    h3 {
      font-size: 38px;
      line-height: 47px;
      font-weight: 300;
      margin: 0 0 40px;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) { 
        font-size: 25px;
        line-height: 30px;
      }
    }
    div.image {
      width: 85%;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: $small) {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        padding-top: 90%;
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        
      }
    }
  }
  &Content {
    width: 63%;
    box-sizing: border-box;
    padding-left: 50px;
    font-size: 17px;
    line-height: 30px;
    display: block;
    @media screen and (max-width: $small) {
      width: auto;
      padding-left: 0;
    }
    @media screen and (max-width: $small) {
      font-size: 15px;
      line-height: 24px;
    }
    p {
      margin: -4px 0 1.5em;
    }
  }
}

  .image {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }

  .wrapper {
    z-index: 9;
  }
  .academyTriangleMountainContainer {
    width: 33.33%;
    margin: 100px auto 150px;
    position: relative;
    @media screen and (max-width: $small) {
      width: 70%;
      margin: 100px auto 80px;
    }
    svg.mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    svg.academyLogo {
      position: absolute;
      bottom: 10%;
      left: 50%;
      width: 60%;
      height: 18%;
      z-index: 10;
      transform: translate(-50%);
    }

    #academyTriangleMountain {
      width: 100%;
      canvas {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc(100% - 1px) !important;
        z-index: 5;
      }
  
      &:before {
        content: '';
        padding-top: 86.6%;
        display: block;
      }
    }

    .academyTriangleTop {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%);
      
    }

    .academyTriangleRight {
      position: absolute;
      top: 100%;
      left: 100%;
      transform: translate(-50%);
      @media screen and (max-width: $small) {
        transform: translate(-70%)
      }
    }

    .academyTriangleLeft {
      position: absolute;
      top: 100%;
      right: 100%;
      transform: translate(50%);
      @media screen and (max-width: $small) {
        transform: translate(70%)
      }
    }

    a {
      color: $academy;
      text-decoration: none;
      font-size: 28px;
      white-space: nowrap;
      display: inline-block;
      margin: 20px 0;
      position: relative;
      @media screen and (max-width: $small) {
        font-size: 20px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        height: 3px;
        background: $academy;
        width: 0;
        left: 0;
        transition: width 500ms ease;
      }
      small {
        font-size: 16px;  
        display: inline-block;
        margin-right: 6px; 
      }
      &.active,
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
} 
</style>