<template>
  <div class="expertiseBoxesAlt">
    <div class="wrapper">
      <h5>{{ data.title }}</h5>
      <h4 v-html="nl2br(data.subline)"></h4>
      <div class="expertiseBoxesAlt__container">
        <div class="expertiseBoxesAlt__box" v-for="(expertise, key) in expertises" :key="key">
          <a href="" @click.prevent="select(expertise, key)">
            <span class="number">{{ '0' + (key+1) }}</span>
            <span class="type">{{ expertise.acf.type }}</span>
            <span class="title" v-html="expertise.title.rendered"></span>
            <span class="content" v-html="expertise.acf.preview_text"></span>
            <span class="label">
              <span class="link-text">{{ $root.translate('more') }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
            </span>
          </a>
        </div>
      </div>
    </div>

    <expertise-layer v-if="selectedExpertise" @close="deselect" @next="next" @prev="prev" :expertise="selectedExpertise" :data="data"></expertise-layer>
  </div>
</template>

<script>
import ExpertiseLayer from './ExpertiseLayer';
import { mapState } from 'vuex';
export default {
  name: 'ExpertiseBoxesAlt',
  props: ['data'],
  data() {
    return {
      selectedExpertise: null,
    }
  },
  components: {
    ExpertiseLayer
  },
  computed: mapState({
    expertises: state => state.expertise.all,
    language: state => state.language.language
  }),
  mounted() {
    this.$store.dispatch('expertise/getAll', { language: this.language });
  },
  methods: {
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    select(expertise, index) {
      expertise.index = index;
      this.selectedExpertise = expertise;
    },
    deselect() {
      setTimeout(() => this.selectedExpertise = null, 1000);
    },
    next() {
      let nextExpertise = this.expertises[0];
      nextExpertise.index = 0;
      this.expertises.forEach((expertise, index) => {
        if(index === this.selectedExpertise.index+1) {
          nextExpertise = expertise;
          nextExpertise.index = index;
        }
      });
      
      this.selectedExpertise = nextExpertise;
    },
    prev() {
      let prevExpertise = this.expertises[this.expertises.length-1];
      prevExpertise.index = this.expertises.length -1;
      this.expertises.forEach((expertise, index) => {
        if(index === this.selectedExpertise.index-1) {
          prevExpertise = expertise;
          prevExpertise.index = index;
        }
      });
      this.selectedExpertise = prevExpertise;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.expertiseBoxesAlt {
  background: $primary;
  padding: 50px 0;
  width: calc(100% - 80px);
  margin-bottom: 140px;
  .wrapper {
    transform: translate(40px);
  }
  h5 {
    color: white;
  }
  h4 {
    font-size: 38px;
    line-height: 47px;
    font-weight: 300;
    margin: 0 0 2em;
    @media screen and (max-width: $medium) { 
      font-size: 30px;
      line-height: 38px;
    }
    @media screen and (max-width: $medium) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: $medium) {
    width: 100%;
    .wrapper {
      transform: none;
    }
  }
}

.expertiseBoxesAlt__container {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $small) {
    display: block;
  }
  
  .expertiseBoxesAlt__box {
    width: 50%;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: $small) {
      width: 100%;
    }
    a {
      margin-bottom: 80px;
      color: white;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: background-color 500ms ease 200ms;
      span {
        display: block;
        &.number {
          font-size: 17px;
          margin: 0 0 1em;
          font-weight: 300;
          letter-spacing: 0.135em;
          transition: transform 500ms ease;
          @media screen and (max-width: $small) {
            font-size: 10px;
          }
        }
        &.type,
        &.title {
          font-size: 27px;
          line-height: 32px;
          transition: transform 500ms ease 100ms;
          @media screen and (max-width: $small) {
            font-size: 20px;
            line-height: 25px;
          }
        }
        &.title {
          margin: 0 0 0px;
          transition: transform 500ms ease 200ms;
        }
        &.content {
          font-size: 17px;
          line-height: 30px;
          color: $color;
          margin: 1em 0;
          max-width: 460px;
          @media screen and (max-width: $small) {
            font-size: 15px;
            line-height: 24px;
          }
        }
        &.label {
          display: flex;
          .link-text {
            max-width: 0;
            overflow: hidden;
            transition: max-width 500ms ease;
            word-wrap: none;
            white-space: nowrap;
            @media screen and (max-width: $small) {
              max-width: none;
              margin: 0 10px 0 0;
            }
          }
        }
      }
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        transition: transform 300ms ease;
        transform-origin: center center;
        > * {
          fill: white;
        }
      }

      &:hover {
        span {
          &.label {
            .link-text {
              width: auto;
              max-width: 110px;
            }
          }
        }
        svg {
          transform: rotate(0deg) translate(20px, 2px);
        }
      }
    }
    
    span.title {
      color: $color;
    }
  }
}
</style>