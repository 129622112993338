<template>
  <div class="ContentTeaser">
    <div class="image">
      <lazy-image :image="data.image"></lazy-image>
    </div>
    <div class="text">
      <div v-html="nl2br(data.text)"></div>
      <a v-if="data.link && data.link.url" :href="data.link.url" :target="data.link.target" class="arrowLink">
        {{ data.link.title }}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
      </a>
    </div>
  </div>
</template>

<script>
import LazyImage from './../LazyImage';
export default {
  name: 'ContentTeaser',
  props: ['data'],
  components: {
    LazyImage
  },
  methods: {
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  }
}
</script>

<style lang="scss">
@import '../../../styles/index.scss';
.ContentTeaser {
  background: $primary;
  color: white;
  display: flex;
  margin-bottom: 100px;
  @media screen and (max-width: $small) {
    display: block;
  }
  .image {
    display: block;
    max-width: 33.33%;
    img {
      display: block;
      max-width: 100%;
    }
    @media screen and (max-width: $small) {
      max-width: none;
    }
  }
  .text {
    width: 66.66%;
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    flex-direction: column;
    div {
      font-size: 27px;
      line-height: 35px;
      @media screen and (max-width: $small) {
        font-size: 20px;
        line-height: 25px;
      }
      margin: 0 0 1em;
    }
    a {
      color: $color;
      transition: color 500ms ease;
      svg {
        * {
          transition: fill 500ms ease;
          fill: $color;
        }
      }
      &:hover {
        color: white;
        svg {
        * {
          fill: white;
        }
      }
      }
    }
  }
}
</style>