<template>
  <div class="expertiseLayerContainer" :class="{'expertiseLayerContainer--inPage': inPage}">
    <div class="expertiseLayer" :class="{ show: show }">
      <div class="left">
        <div class="mask">
          <div class="inner">
            <div id="expertiseLayerMountain"></div>
            <div class="half-wrapper half-wrapper--left">
              <h5 v-if="!inPage && !data.title.rendered">{{ data.title }}</h5>
              <h5 v-if="!inPage && data.title.rendered" v-html="data.title.rendered"></h5>
              <div class="arrows" v-if="!inPage">  
                <a href="#" class="prev" @click.prevent="$emit('prev')">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                </a>
                <a  href="#" class="next" @click.prevent="$emit('next')">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                </a>
              </div>
              <div class="expertiseTitle">
                <span v-if="!inPage" class="number">{{ '0' + (expertise.index+1) }}</span>
                <!-- <span class="type">{{ expertise.acf.type }}</span> -->
                <span class="title" v-html="expertise.title.rendered"></span>
              </div>
              <primary-button v-if="expertise.acf.projects.length" :to="pathTo(expertise.acf.projects[0].project.post_name)">{{ $root.translate('all_project_references')}}</primary-button>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="mask">
          <div class="inner">
            <div class="half-wrapper half-wrapper--right">
              <div class="content" v-html="expertise.content.rendered"></div>
              <div class="relatedProjects" v-if="expertise.acf.projects">
                <h5>{{ $root.translate('latest_projects') }}</h5>
                <ul>
                  <li v-for="(p, key) in expertise.acf.projects" :key="key">
                    <span class="title">{{ p.project.post_title }}</span>
                    <router-link class="arrowLink" :to="pathTo(p.project.post_name)">
                      {{ $root.translate('to_project') }}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4"><path fill="#4b4b4d" d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"/></svg>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="closeWrapper" v-if="!inPage" :class="{show: show}">
      <a class="close" href="" @click.prevent="close">
        <span class="label">{{ $root.translate('close') }}</span>
        <span class="cross"></span>
      </a>
    </div>
  </div>
</template>

<script>
import PrimaryButton from './PrimaryButton';
import { pathTranslate } from '../../helpers/NavigationService';
import { mapState } from 'vuex';
let mountain = null;
export default {
  name: 'ExpertiseLayer',
  props: ['data', 'expertise', 'inPage'],
  components: {
    PrimaryButton
  },
  computed: mapState({
    language: state => state.language.language
  }),
  data() {
    return {
      show: false,
    }
  },
  watch: {
    show(value) {
      if (!this.inPage) {
        document.querySelector('.des-lines').style.zIndex = value ? 101 : 10;
        document.body.style.overflow = value ? 'hidden' : 'visible';
        document.querySelector('html').style.overflow = value ? 'hidden' : 'visible';
       }
    },
    '$route'() {
      document.body.style.overflow = 'visible';
      document.querySelector('html').style.overflow = 'visible';
    },
    expertise() {
      this.$el.querySelector('.right .mask .inner').scrollTo(0,0);
    }
  },
  mounted() {
    setTimeout(() => this.show = true, 500);
    setTimeout(() => this.addMountains(), 2000);
  },
  beforeUnmount() {
    document.querySelector('.des-lines').style.zIndex = 10;
    document.body.style.overflow = 'visible';
    document.querySelector('html').style.overflow = 'visible';
    if (mountain) {
      mountain?.destroy();
    }
  },
  methods: {
    addMountains() {
      if (!document.querySelector('#expertiseLayerMountain')) {
        return;
      }
      mountain = new window._MOUNTAINS({
        element: '#expertiseLayerMountain',
        seed: Math.round(Math.random() * 65500),
        disableUI: true,
        groundLevel: 15,
        screenshot: false,
        backgroundColor: 0x363e48,
        groundColor: 0x363e48,
        lineColor: 0xababab,
        camera: {
            auto: true,
            motion: false,
            position: {
                x: 60,
                y: 30,
                z: 0
            },
            lookAt: {
                x: 0,
                y: 40,
                z: 0
            }
        }
      });
      mountain.enableCameraAuto(true);
      mountain.run();
    },
    close() {
      this.show = false;
      mountain.destroy();
      this.$emit('close');
    },
    pathTo(projectName) {
      return pathTranslate('project', this.language) + projectName;
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/config';
.expertiseLayerContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media screen and (max-width: $medium) {
    overflow-y: auto;
  }
  &--inPage {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    min-height: calc(100vh - 100px);
    z-index: initial;
    margin-bottom: 80px;
  }
}
.expertiseLayer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  pointer-events: none;
  visibility: hidden;
  transition: visibility 0ms ease 1000ms;
  @media screen and (max-width: $medium) {
    display: block;
    position: static;
  }
  #expertiseLayerMountain {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color;
    @media screen and (max-width: $medium) {
      width: 100vw;
      right: auto;
    }
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .arrows {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    top: 0;
    @media screen and (max-width: $medium) {
      right: auto;
      left: 20px;
      top: 0px;
    }
    .prev,
    .next {
      svg {
        width: 16px;
        > * {
          fill: white;
        }
      }
    }
    .prev {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .right,
  .left {
    flex: 1;
    position: relative;

    > .mask {
      max-width: 0;
      width: 100%;
      transition: max-width 1000ms ease;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      @media screen and (max-width: $medium) {
        position: relative;
        overflow: hidden;
      }
      .inner {
        width: 50vw;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: $color;
        padding: 30px 0;
        box-sizing: border-box;
        @media screen and (max-width: $medium) {
          width: 100vw;
          position: static;
          min-height: 300px;
          .half-wrapper > h5 {
            display: none;
          }
        }
      
        .content {
          padding: 0;
          font-size: 17px;
          line-height: 30px;
          margin-bottom: 100px;
          strong {
            font-weight: 300;
            font-size: 27px;
          }
          @media screen and (max-width: $small) {
            font-size: 15px;
            line-height: 24px;
            strong {
              font-size: 20px;
            }
          }
        }

        .expertiseTitle {
          padding: 50px 0;
          color: white;
          span {
            display: block;
            &.number {
              font-size: 17px;
              margin: 0 0 1em;
              font-weight: 300;
              letter-spacing: 0.135em;
              transition: transform 500ms ease;
              @media screen and (max-width: $small) {
                font-size: 8px;
              }
            }
            &.type,
            &.title {
              font-size: 38px;
              line-height: 47px;
              max-width: 50%;
              transition: transform 500ms ease 100ms;
              font-weight: 300;
              @media screen and (max-width: $medium) { 
                font-size: 30px;
                line-height: 38px;
              }
              @media screen and (max-width: $small) {
                max-width: none;
                font-size: 25px;
                line-height: 30px;
              }
            }
            &.title {
              color: $primary;
            }
          }
          
        }
      }
    }
  }
  .right {
    .mask {
      .inner {
        background: white;
        //display: flex;
        padding-top: 120px;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        @media screen and (max-width: $medium) {
          padding-top: 40px;
        }
      }
    }
  }
  &.show {
    pointer-events: initial;
    visibility: visible;
    transition: visibility 10ms ease 0ms;
    .right,
    .left {
      > .mask {
        max-width: 100%;
      }
    }
  }
}

.closeWrapper {
  position: absolute;
  top: 0;
  max-width: 1180px;
  width: calc(100% - 40px);
  left: 50%;
  transform: translate(-50%);
  z-index: 110;
  opacity: 0;
  transition: opacity 500ms ease;
  &.show {
    opacity: 1;
    transition: opacity 500ms ease 500ms;
  }
  .close {
      position: absolute;
      top: 50px;
      right: 0;
      color: $color;
      text-decoration: none;
      display: flex;
      justify-content:center;
      align-items: center;
      z-index: 20;
      @media screen and (max-width: $medium) {
        color: white;
        top: 25px;
      }
      .cross {
        width: 24px;
        height: 24px;
        display: block;
        position: relative;
        margin-left: 10px;
        &:before,
        &:after {
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          background: $color;
          transform: translate(-50%, -50%) rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
          @media screen and (max-width: $medium) {
            background: white;
          }
        }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

.relatedProjects {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 30px;
      .title {
        font-size: 27px;
        line-height: 37px;
        display: block;
        margin-bottom: 20px;
         @media screen and (max-width: $small) {
          font-size: 20px;
          line-height: 25px;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}
</style>