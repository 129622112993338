<template>
  <div class="wrapper">
    <div class="faqs" v-if="faqs && faqs.length">
      <h5>{{ data.title }}</h5>
      <div
        class="faqEntry"
        v-for="faq in faqs"
        :key="faq.id"
        :class="{ open: openFaqs.includes(faq.id) }"
      >
        <div class="faqTitle" @click.prevent="toggle(faq.id, $event)">
          <h2>
            <span v-html="faq.title.rendered"></span
            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 182.7 176.4">
              <path
                fill="#4b4b4d"
                d="M95 1.1L84 11.9l67.1 68.4H1v15.4h151.6l-67.3 68.6 11 10.8L181 88.8z"
              />
            </svg>
          </h2>
        </div>
        <div class="faqEntryContainer">
         
          <div class="faqContent">
            <div class="left">
              <div v-html="faq.content.rendered"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCoords } from "../../helpers/NavigationService.js";

export default {
  name: "Faqs",
  data() {
    return {
      openFaqs: [],
    };
  },
  props: ["data"],
  computed: {
    ...mapState({
      faqs: (state) => state.faq.faqs,
    }),
  },
  mounted() {
    this.$store.dispatch('faq/getFaqs', { language: this.language });
  },
  methods: {
    toggle(id, $event) {
      if (this.openFaqs.includes(id)) {
        this.openFaqs = this.openFaqs.filter((faq) => faq != id);
      } else {
        this.openFaqs.push(id);
        const coords = getCoords($event.target);
        const delta = 120;
        window.scrollTo(0, coords.top - delta);
      }
    },
    close(id) {
      this.openFaqs = this.openFaqs.filter((faq) => faq != id);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/config";
.faqs {
  margin-bottom: 210px;
  @media screen and (max-width: $medium) {
    margin-bottom: 80px;
  }
  .faqEntry {
    border-bottom: 1px solid $color;
    @media screen and (max-width: $medium) {
      margin-bottom: 20px;
    }
    .faqTitle {
      cursor: pointer;
      @media screen and (max-width: $small) {
        display: block;
      }
      h2 {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 32px;
        line-height: 1em;
        font-weight: normal;
        margin: 0;
        transition: color 500ms ease;
        @media screen and (max-width: $medium) {
          font-size: 25px;
          line-height: 30px;
          //max-width: 80%;
        }
        svg {
          width: 16px;
          height: 16px;
          transform: rotate(0);
          display: block;
          margin-left: 60px;
          transition: transform 500ms ease;
          @media screen and (max-width: $medium) {
            margin-left: 10px;
          }
          * {
            fill: $color;
            transition: color 500ms ease;
          }
        }
      }
      &:hover {
        small {
          color: $primary;
        }
        h2 {
          color: $primary;
        }
        svg {
          * {
            fill: $primary;
          }
        }
      }
    }
    .faqEntryContainer {
      position: relative;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms ease, padding 500ms ease;
      @media screen and (max-width: $small) {
        padding-left: 0;
      }

      .faqContent {
        display: flex;
        font-size: 17px;
        line-height: 30px;
        align-items: flex-end;
        max-width: 66.666%;
        @media screen and (max-width: $medium) {
          display: block;
          font-size: 15px;
          line-height: 24px;
          max-width: 100%;
        }
      }
    }
    &.open {
      .faqTitle {
        small {
          color: $primary;
        }
        h2 {
          color: $primary;
        }
        svg {
          transform: rotate(90deg);
          * {
            fill: $primary;
          }
        }
      }
      .faqEntryContainer {
        max-height: 1000px;
        padding-bottom: 60px;
      }
    }
  }
}
</style>
