function getMeta (vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { meta } = vm.$options
  if (meta) {
    return typeof meta === 'function'
      ? meta.call(vm)
      : meta
  }
}

const serverMetaMixin = {
  created () {
    const meta = getMeta(this)
    if (meta) {
      this.$ssrContext.description = meta.description
      this.$ssrContext.keywords = meta.keywords
      this.$ssrContext.image = meta.image
    }
  }
}

const clientMetaMixin = {
  updated() {

  },
  mounted () {

  }
}

// `VUE_ENV` can be injected with `webpack.DefinePlugin`
export default process.env.VUE_ENV === 'server'
  ? serverMetaMixin
  : clientMetaMixin