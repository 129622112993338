<template>
  <img class="lazy" :class="{loaded: loaded}" :src="src" @load="$emit('load')" :alt="image?.alt || null">
</template>

<script>
let ScrollMagic;
if(typeof window !== 'undefined') {
  ScrollMagic = require('scrollmagic');
}

let timeout;
export default {
  name: 'LazyImage',
  props: ['image', 'replaceSize'],
  data() {
    return {
      src: null,
      loaded: false,
      scene: null
    }
  },
  created() {
    this.src = null // this.image.sizes.thumbnail;
  },
  beforeUnmount() {
    if (this.scene) {
      this.scene.destroy();
    }
  },
  watch: {
    image: {
      deep: true,
      immediate: true,
      handler() {
        timeout = setTimeout(() => this.newScene(), 500);
      }
    }
  },
  mounted() {
    if(!ScrollMagic) return;
    if(timeout) clearTimeout(timeout);
    timeout = setTimeout(() => this.newScene(), 500);
  },
  methods: {
    newScene() {
      if(!ScrollMagic) return;
      if(this.scene) this.scene.destroy();
      this.loaded = false;
      this.scene = new ScrollMagic.Scene({
        triggerElement: this.$el,
        offset: -window.innerHeight/2
      })
      .addTo(window.ScrollMagicController); 
      this.scene.on('enter',() => {
        if(!this.loaded) {
          this.load();
          this.$emit('largeLoad');
        }
      })
    },
    load() {
      if (!this.image) return;
      const image = new Image();
      const imageSrc = this.replaceSize && this.image.sizes[this.replaceSize] ? this.image.sizes[this.replaceSize] : this.image.url;
      image.src = imageSrc;
      image.style.position = 'absolute',
      image.style.height = 0;
      image.style.width = 0;
      image.style.left = '-999em';
      image.onload = () => {
        this.src = imageSrc;
        this.loaded = true;
        this.$emit('imageLoaded', image);
      };
      document.body.appendChild(image);
    }
  }
}
</script>

<style lang="scss">
img.lazy {
  opacity: 0;
  transition: all 300ms ease !important;
  &.loaded {
    opacity: 1
  }
}
</style>